import * as React from "react";
import Box from "@mui/material/Box";
import { CallbackTable } from "./CallbackTable";

export const CallbackParentTable = (props) => {
  const { callbackData, toggleAllComments, allCommentsExpanded,
    page,
    setPage,
    offset,
    setOffset,
    fetchMoreRecord,
    onUpdateCampaignWithAltCampaign,
    updateEmployeeToCallBack,
  } = props;
  const [filterName, setFilterName] = React.useState("");
  const [filterActive, setFilterActive] = React.useState("all");

  const createData = (
    show,
    amountOwed,
    agent,
    scheduleTime,
    campaign,
    accountName,
    phoneNumber,
    prospects,
    alternateCampaign,
    assignedTo,
    createdAt,
    createdBy
  ) => {
    return {
      show,
      amountOwed,
      agent,
      scheduleTime,
      campaign,
      accountName,
      phoneNumber,
      prospects,
      alternateCampaign,
      assignedTo,
      createdAt,
      createdBy,
    };
  };

  const convertApiResponseToRows = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }


    return data.map((item) => {
      const {
        amount_owned,
        agent_name,
        date_time,
        campaign_name,
        account_name,
        phone_number,
        prospect_name,
        assigned_to_name,
        created_at,
        created_by_name
      } = item;

      const user = item;

      return createData(
        user,
        amount_owned,
        agent_name,
        date_time,
        campaign_name,
        account_name,
        phone_number,
        prospect_name,
        user,
        assigned_to_name,
        created_at,
        created_by_name,
      );
    });
  };

  const tableRows = convertApiResponseToRows(callbackData);

  const handleNameFilterChange = (event) => {
    setFilterName(event.target.value);
  };

  const handleActiveFilterChange = (event) => {
    setFilterActive(event.target.value);
  };

  return (
    <Box>
      <CallbackTable
        rows={tableRows}
        filterName={filterName}
        handleNameFilterChange={handleNameFilterChange}
        filterActive={filterActive}
        handleActiveFilterChange={handleActiveFilterChange}
        toggleAllComments={toggleAllComments}
        allCommentsExpanded={allCommentsExpanded}
        page={page}
        setPage={setPage}
        offset={offset}
        setOffset={setOffset}
        fetchMoreRecord={fetchMoreRecord}
        onUpdateCampaignWithAltCampaign={onUpdateCampaignWithAltCampaign}
        updateEmployeeToCallBack={updateEmployeeToCallBack}
      />
    </Box>
  );
};
