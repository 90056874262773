// LoginScreen.js
import React, { useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  FormHelperText,
  Autocomplete,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState } from "react";
import "aos/dist/aos.css";
import dayjs from "dayjs";
import { Loader } from "../Common";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useSelector } from "react-redux";
import { GetUsers, getAllCampaigns } from "../../store/actions";

export const NewAppointment = (props) => {

  const { onCreateAppointment } = props;
  const [triedSubmit, setTriedSubmit] = useState(false);

  const userDetails = useSelector(
    (state) => state?.prospect?.user_details?.data?.results
  );
  const campaignNames = useSelector(
    (state) => state?.campaign?.campaignNamesWithID?.results
  );
  const leadsName = useSelector(
    (state) => state?.lead?.leadData
  );

  const isloading = useSelector((state) => state?.appointment?.isLoading);

  useEffect(() => {
    GetUsers();
    getAllCampaigns();
  }, []);

  //appointment information

  const initialAppointmentData = {
    appointmentDate: "",
    appointmentTime: dayjs(),
    confirmAppointmentDate: "",
    confirmAppointmentTime: dayjs(),
    appointmentType: null,
    status: null,
    comment: "",
  };

  const [appointmentData, setAppointmentData] = useState(initialAppointmentData);

  const initialLeadInfo = {
    agentId: null,
    agentCallingFor: null,
    lead: null,
  };

  //lead info
  const [leadInfo, setLeadInfo] = useState(initialLeadInfo);

  const appointmentTypeError =
    triedSubmit &&
    appointmentData?.appointmentType?.toString()?.trim().length === 0;

  const appointmentTypeErrorMessage =
    triedSubmit &&
      appointmentData?.appointmentType?.toString()?.trim().length === 0
      ? "Please enter valid appointment type "
      : "";

  const agentNameError = triedSubmit && leadInfo?.agentId?.toString().trim().length === 0;

  const agentNameErrorMessage =
    triedSubmit && leadInfo?.agentId?.toString().trim().length === 0
      ? "Please select agent name"
      : "";

  const agentForError =
    triedSubmit && leadInfo?.agentCallingFor?.toString().trim().length === 0;

  const agentForErrorMessage =
    triedSubmit && leadInfo?.agentCallingFor?.toString().trim().length === 0
      ? "This field is required."
      : "";
  const leadError = triedSubmit && leadInfo?.lead?.toString().trim().length === 0;

  const leadErrorMessage =
    triedSubmit && leadInfo?.lead?.toString().trim().length === 0
      ? "This field is required."
      : "";

  const handleChange = (event, newValue, type) => {
    switch (type) {
      case "agentId":
        setLeadInfo({
          ...leadInfo,
          agentId: newValue?.id,
        });
        break;
      case "selectCallingFor":
        setLeadInfo({
          ...leadInfo,
          agentCallingFor: newValue?.id,
        });
        break;
      case "lead":
        setLeadInfo({
          ...leadInfo,
          lead: newValue?.id,
        });
        break;

      default:
        break;
    }
  };

  const handleChangeOptions = (event, type) => {
    switch (type) {
      case "appointmentType":
        setAppointmentData({
          ...appointmentData,
          appointmentType: event.target.value,
        });
        break;
      case "lead":
        setAppointmentData({
          ...appointmentData,
          lead: event.target.value,
        });
        break;
      case "status":
        setAppointmentData({
          ...appointmentData,
          status: event.target.value,
        });
        break;

      default:
        break;
    }
  };

  const isCheckFormValid = () => {
    const isAgentNameValid = leadInfo?.agentId?.toString()?.trim().length > 0;

    const isAgentCallingForValid =
      leadInfo?.agentCallingFor?.toString().trim().length > 0;

    const isLeadValid = leadInfo?.lead?.toString().trim().length > 0;

    const isApppointmentDateValid =
      appointmentData?.appointmentDate?.toString()?.trim().length > 0;

    const isAppointmentTimeValid =
      appointmentData?.appointmentTime?.toString()?.trim().length > 0;

    const isCNFApppointmentDateValid =
      appointmentData?.confirmAppointmentDate?.toString()?.trim().length > 0;

    const isCNFAppointmentTimeValid =
      appointmentData?.confirmAppointmentTime?.toString()?.trim().length > 0;

    const isAppointmentTypeValid =
      appointmentData?.appointmentType?.toString()?.trim().length > 0;

    const isconfirmTimeValid =
      appointmentData?.appointmentTime?.toString()?.trim() ===
      appointmentData?.confirmAppointmentTime?.toString()?.trim();

    const isconfirmDateValid =
      appointmentData?.appointmentDate?.toString()?.trim() ===
      appointmentData?.confirmAppointmentDate?.toString()?.trim();
    return (
      isApppointmentDateValid &&
      isAppointmentTimeValid &&
      isCNFApppointmentDateValid &&
      isCNFAppointmentTimeValid &&
      isAppointmentTypeValid &&
      isconfirmTimeValid &&
      isconfirmDateValid &&
      isAgentNameValid &&
      isAgentCallingForValid &&
      isLeadValid
    );
  };

  const isFormValid = isCheckFormValid();

  const onSubmitHandler = (e) => {
    setTriedSubmit(true);
    if (!isFormValid) return;
    if (false) {
    } else {
      let payload = {
        ...leadInfo,
        ...appointmentData,
      };
      onCreateAppointment(payload);
      onReset()
    }
  };

  const onReset = (e) => {
    setTriedSubmit(false);
    setAppointmentData({
      appointmentDate: initialAppointmentData.appointmentDate,
      appointmentTime: initialAppointmentData.appointmentTime,
      confirmAppointmentDate: initialAppointmentData.confirmAppointmentDate,
      confirmAppointmentTime: initialAppointmentData.confirmAppointmentTime,
      appointmentType: initialAppointmentData.appointmentType,
      status: initialAppointmentData.status,
      comment: initialAppointmentData.comment
    });
    setLeadInfo({
      agentId: initialLeadInfo.agentId,
      agentCallingFor: initialLeadInfo.agentCallingFor,
      lead: initialLeadInfo.lead,
    });
  };

  return (
    <>
      <Box
        sx={{
          margin: { xs: "10px", md: '30px' },
          marginTop: { xs: "10px", md: '30px' },
          borderRadius: "15px!Important",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Accordion
              defaultExpanded
              sx={{ boxShadow: 3, borderRadius: "10px!Important" }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "#212223", pointerEvents: "auto" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  borderBottom: 1,
                  borderColor: "#676a6c4a",
                  backgroundColor: " #d2f5ec",
                  borderRadius: "10px 10x 0 0!Important",


                }}
              >
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 18,
                    color: "#212223",
                    fontWeight: 600,
                  }}
                >
                  Appointment Information
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    padding: "20px 10px",
                  }}
                >
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        {/* <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                          }}
                        >
                          <Typography
                            component="h2"
                            variant="h2"
                            fullWidth
                            sx={{
                              fontSize: 15,
                              color: "#7b7b7b",
                              fontWeight: 600,
                              mb: 1.5,
                            }}
                          >
                            Appointment Date :
                          </Typography>
                          <TextField
                            margin="0"
                            size="small"
                            type="date"
                            fontSize="15"
                            error={appointmentDateError}
                            helperText={
                              appointmentDateError &&
                              appointmentDateErrorMessage
                            }
                            value={appointmentData.appointmentDate}
                            onChange={(e) => {
                              setAppointmentData({
                                ...appointmentData,
                                appointmentDate: e.target.value,
                              });
                            }}
                            InputLabelProps={{
                              sx: {
                                fontSize: "15px",
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                            sx={{
                              marginBottom: "10px",
                              width: {
                                xs: "100%",
                                sm: "100%",
                                md: "200px",
                                lg: "200px",
                              },
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: "#fff",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6DD6C2",
                                },
                              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6DD6C2",
                                },
                            }}
                          />
                        </Box> */}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['DateTimePicker']} sx={{ marginTop: '-8px' }}>
                            <DateTimePicker label="Appointment Date Time"

                              sx={{
                                fontSize: "11px",
                                "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":
                                {
                                  padding: "9px 13px 9px 13px",
                                },
                                width: "100%",

                                "& .css-1pz6unh-MuiFormLabel-root-MuiInputLabel-root": {
                                  top: '-7px',
                                },
                                "& .MuiInputLabel-root": {
                                  "&.Mui-focused": {
                                    color: "#18a689!important",
                                  },
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "rgba(0, 0, 0, 0.23)",
                                },
                                "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6DD6C2",
                                },
                                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#6DD6C2!important",
                                },
                              }} />

                          </DemoContainer>
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        {/* <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                          }}
                        >
                          <Typography
                            component="h2"
                            variant="h2"
                            fullWidth
                            sx={{
                              fontSize: 15,
                              color: "#7b7b7b",
                              fontWeight: 600,
                              mb: 1.5,
                            }}
                          >
                            Appointment Time :
                          </Typography>
                          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              components={[
                                "TimePicker",
                                "MobileTimePicker",
                                "DesktopTimePicker",
                                "StaticTimePicker",
                              ]}
                              sx={{
                                padding: 0,
                              }}
                            >
                              <MobileTimePicker
                                value={appointmentData?.appointmentTime}
                                onChange={(value) => {
                                  setAppointmentData({
                                    ...appointmentData,
                                    appointmentTime: value,
                                  });
                                }}
                                error={appointmentTimeError}
                                helperText={
                                  appointmentTimeError &&
                                  appointmentTimeErrorMessage
                                }
                                slotProps={{
                                  textField: {
                                    helperText: appointmentTimeErrorMessage,
                                  },
                                }}
                                sx={{
                                  fontSize: "12px",
                                  "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input ":
                                    {
                                      padding: "10px 13px 10px",
                                    },
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider> 

                          <TextField
                            ampm={true}
                            type="time"
                            name="appointmentTime"
                            size="small"
                            value={appointmentData?.appointmentTime}
                            onChange={(e) => {
                              setAppointmentData({
                                ...appointmentData,
                                appointmentTime: e.target.value,
                              });
                            }}
                            error={appointmentTimeError}
                            helperText={
                              appointmentTimeError &&
                              appointmentTimeErrorMessage
                            }
                            InputLabelProps={{
                              sx: {
                                fontSize: "15px",
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                            sx={{
                              marginBottom: "10px",
                              width: "200px!important",
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: "#fff",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6DD6C2",
                                },
                              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6DD6C2",
                                },
                            }}
                          />
                        </Box> */}
                        <LocalizationProvider dateAdapter={AdapterDayjs} sx={{
                          "& .css-1nzv0a0-MuiStack-root>.MuiTextField-root":
                          {
                            minWidth: '100%!important'
                          },
                        }}>
                          <DemoContainer components={['DateTimePicker']} sx={{ marginTop: '-8px', minWidth: 'auto', }}>
                            <DateTimePicker label="Confirm Appointment Date Time"

                              sx={{

                                fontSize: "11px",
                                "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":
                                {
                                  padding: "9px 13px 9px 13px",
                                },

                                width: "100%",

                                "& .css-1pz6unh-MuiFormLabel-root-MuiInputLabel-root": {
                                  top: '-7px',
                                },
                                "& .MuiInputLabel-root": {
                                  "&.Mui-focused": {
                                    color: "#18a689!important",
                                  },
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "rgba(0, 0, 0, 0.23)",
                                },
                                "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6DD6C2",
                                },
                                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#6DD6C2!important",
                                },
                              }} />

                          </DemoContainer>
                        </LocalizationProvider>
                      </Grid>
                      {/* <Grid item xs={12} sm={6} md={4}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                          }}
                        >
                          <Typography
                            component="h2"
                            variant="h2"
                            fullWidth
                            sx={{
                              fontSize: 15,
                              color: "#7b7b7b",
                              fontWeight: 600,
                              mb: 1.5,
                            }}
                          >
                            Confirm Appointment Date :
                          </Typography>
                          <TextField
                            margin="0"
                            size="small"
                            type="date"
                            fontSize="15"
                            error={confirmAppointmentDateError}
                            helperText={
                              confirmAppointmentDateError &&
                              confirmAppointmentDateErrorMessage
                            }
                            value={appointmentData.confirmAppointmentDate}
                            onChange={(e) => {
                              setAppointmentData({
                                ...appointmentData,
                                confirmAppointmentDate: e.target.value,
                              });
                            }}
                            InputLabelProps={{
                              sx: {
                                fontSize: "15px",
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                            sx={{
                              marginBottom: "10px",
                              width: {
                                xs: "100%",
                                sm: "100%",
                                md: "200px",
                                lg: "200px",
                              },
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: "#fff",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6DD6C2",
                                },
                              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6DD6C2",
                                },
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                          }}
                        >
                          <Typography
                            component="h2"
                            variant="h2"
                            fullWidth
                            sx={{
                              fontSize: 15,
                              color: "#7b7b7b",
                              fontWeight: 600,
                              mb: 1.5,
                            }}
                          >
                            Confirm Appointment Time :
                          </Typography>
                          <TextField
                            ampm={true}
                            type="time"
                            name="confirmappointmentTime"
                            size="small"
                            value={appointmentData.confirmAppointmentTime}
                            onChange={(e) => {
                              setAppointmentData({
                                ...appointmentData,
                                confirmAppointmentTime: e.target.value,
                              });
                            }}
                            error={confirmAppointmentTimeError}
                            helperText={
                              confirmAppointmentTimeError &&
                              confirmAppointmentTimeErrorMessage
                            }
                            InputLabelProps={{
                              sx: {
                                fontSize: "15px",
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                            sx={{
                              marginBottom: "10px",
                              width: "200px!important",
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: "#fff",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6DD6C2",
                                },
                              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6DD6C2",
                                },
                            }}
                          />
                        </Box>
                      </Grid> */}

                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl
                          size="small"
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgba(0, 0, 0, 0.23)",
                            },

                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },

                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6DD6C2!important",
                            },
                          }}
                        >
                          <InputLabel
                            id="demo-simple-select-label"
                            InputLabelProps={{
                              sx: {
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                            sx={{
                              fontSize: 15,
                              "&.Mui-focused": {
                                color: "#6DD6C2!important",
                                fontWeight: "600",
                              },
                            }}
                          >
                            Appointment Type
                          </InputLabel>
                          <Select
                            labelId="sms-label"
                            id="demo-simple-select"
                            label="Appointment Type"
                            error={appointmentTypeError}
                            onChange={(event) => {
                              handleChangeOptions(event, "appointmentType");
                            }}
                            size="small"
                            InputLabelProps={{
                              sx: {
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                          >
                            <MenuItem value={1} sx={{ fontSize: 15 }}>
                              In Person
                            </MenuItem>
                            <MenuItem value={2} sx={{ fontSize: 15 }}>
                              Phone Call
                            </MenuItem>
                          </Select>
                          <>
                            {appointmentTypeError && (
                              <FormHelperText
                                sx={{
                                  color: "#d32f2f",
                                }}
                              >
                                {appointmentTypeErrorMessage}
                              </FormHelperText>
                            )}
                          </>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl
                          size="small"
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "100%",
                              lg: "100%",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgba(0, 0, 0, 0.23)",
                            },

                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },

                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6DD6C2!important",
                            },
                          }}
                        >
                          <InputLabel
                            id="demo-simple-select-label"
                            InputLabelProps={{
                              sx: {
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                            sx={{
                              fontSize: 15,
                              "&.Mui-focused": {
                                color: "#6DD6C2!important",
                                fontWeight: "600",
                              },
                            }}
                          >
                            Status
                          </InputLabel>
                          <Select
                            labelId="sms-label"
                            id="demo-simple-select"
                            label="Status"
                            onChange={(event) => {
                              handleChangeOptions(event, "status");
                            }}
                            size="small"
                            InputLabelProps={{
                              sx: {
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                          >
                            <MenuItem value={"1"} sx={{ fontSize: 15 }}>
                              Awaiting Confirmation
                            </MenuItem>
                            <MenuItem value={"2"} sx={{ fontSize: 15 }}>
                              Confirmed
                            </MenuItem>
                            <MenuItem value={"3"} sx={{ fontSize: 15 }}>
                              Left Message
                            </MenuItem>
                            <MenuItem value={"4"} sx={{ fontSize: 15 }}>
                              Rescheduled
                            </MenuItem>
                            <MenuItem value={"5"} sx={{ fontSize: 15 }}>
                              Cancelled
                            </MenuItem>
                            <MenuItem value={"6"} sx={{ fontSize: 15 }}>
                              Cancelled - Waiting to Rescheduled
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="comment"
                          label="Comment"
                          type="text"
                          id="comment"
                          fontSize="15"
                          value={appointmentData.comment}
                          onChange={(event) => {
                            setAppointmentData({
                              ...appointmentData,
                              comment: event.target.value,
                            });
                          }}
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#6DD6C2",
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "100%",
                              lg: "100%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} md={12}>
            <Accordion
              defaultExpanded
              sx={{ boxShadow: 3, borderRadius: "10px!Important" }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "#212223", pointerEvents: "auto" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  borderBottom: 1,
                  borderColor: "#676a6c4a",
                  backgroundColor: " #d2f5ec",
                  borderRadius: "10px 10px 0 0!Important",
                }}
              >
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 18,
                    color: "#212223",
                    fontWeight: 600,
                  }}
                >
                  Associative Information
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    padding: "20px 10px",
                  }}
                >
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl
                          size="small"
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgba(0, 0, 0, 0.23)",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6DD6C2!important",
                            },
                          }}
                        >
                          <Autocomplete
                            id="combo-box-demo"
                            options={userDetails}
                            getOptionLabel={(option) => option.full_name}
                            onChange={(event, newValue) => {
                              handleChange(event, newValue, "agentId");
                            }}
                            size="small"
                            // disableClearable={true}
                            error={agentNameError}
                            helperText={agentNameError && agentNameErrorMessage}
                            sx={{
                              "& .MuiInputLabel-root": {
                                "&.Mui-focused": {
                                  color: "#18a689!important",
                                },
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: agentNameError
                                  ? "#d32f2f"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#18a689",
                              },
                              "& .Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#18a689",
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                            renderInput={(params) => (
                              <>
                                <TextField {...params} label="Select Agent" />
                                {agentNameError && (
                                  <FormHelperText
                                    sx={{
                                      color: "#d32f2f",
                                    }}
                                  >
                                    {agentNameErrorMessage}
                                  </FormHelperText>
                                )}
                              </>
                            )}
                            renderOption={(props, option, { inputValue }) => {
                              return (
                                <li
                                  {...props}
                                  key={`${option.id}-${option.full_name}`}
                                >
                                  {option?.full_name}
                                </li>
                              );
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl
                          size="small"
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgba(0, 0, 0, 0.23)",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6DD6C2!important",
                            },
                          }}
                        >
                          <Autocomplete
                            id="combo-box-demo"
                            options={campaignNames}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => {
                              handleChange(event, newValue, "selectCallingFor");
                            }}
                            size="small"
                            // value={leadInfo.agentCallingFor}
                            // disableClearable={true}
                            error={agentForError}
                            helperText={agentForError && agentForErrorMessage}
                            sx={{
                              "& .MuiInputLabel-root": {
                                "&.Mui-focused": {
                                  color: "#18a689!important",
                                },
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: agentForError
                                  ? "#d32f2f"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#18a689",
                              },
                              "& .Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#18a689",
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                            renderInput={(params) => (
                              <>
                                <TextField
                                  {...params}
                                  label="Agent Calling for"
                                />
                                {agentForError && (
                                  <FormHelperText
                                    sx={{
                                      color: "#d32f2f",
                                    }}
                                  >
                                    {agentForErrorMessage}
                                  </FormHelperText>
                                )}
                              </>
                            )}
                            renderOption={(props, option, { inputValue }) => {
                              return (
                                <li
                                  {...props}
                                  key={`${option.id}-${option.name}`}
                                >
                                  {option?.name}
                                </li>
                              );
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl
                          size="small"
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgba(0, 0, 0, 0.23)",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6DD6C2!important",
                            },
                          }}
                        >
                          <Autocomplete
                            id="selectinfo"
                            label="Lead"
                            error={leadError}
                            helperText={leadError && leadErrorMessage}
                            //  onChange={(event) => {
                            //   handleChangeOptions(event, "lead");
                            // }}
                            onChange={(event, newValue) => {
                              handleChange(event, newValue, "lead");
                            }}
                            options={leadsName}
                            getOptionLabel={(option) => option.agent_name}

                            size="small"
                            // disableClearable={true}
                            sx={{
                              "& .MuiInputLabel-root": {
                                "&.Mui-focused": {
                                  color: "#18a689!important",
                                },
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: leadError
                                  ? "#d32f2f"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#18a689",
                              },
                              "& .Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#18a689",
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                            renderInput={(params) => (
                              <>
                                <TextField
                                  {...params}
                                  label="Lead"
                                />
                                {leadError && (
                                  <FormHelperText
                                    sx={{
                                      color: "#d32f2f",
                                    }}
                                  >
                                    {leadErrorMessage}
                                  </FormHelperText>
                                )}
                              </>
                            )}
                            renderOption={(props, option, { inputValue }) => {
                              return (
                                <li
                                  {...props}
                                  key={`${option.id}-${option.agent_name}`}
                                >
                                  {option?.agent_name}
                                </li>
                              );
                            }}
                          />
                        </FormControl>

                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        sx={{ marginTop: "15px" }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          onClick={onSubmitHandler}
                          sx={{
                            fontWeight: 600,
                            textTransform: "capitalize",
                            pl: 4,
                            pr: 4,
                            backgroundColor: "#6DD6C2",
                            borderColor: "#6DD6C2",
                            color: "#212223",
                            "&:hover": {
                              // Customize the hover color
                              backgroundColor: "#6DD6C2",
                              borderColor: "#6DD6C2",
                            },
                          }}
                        >
                          Submit
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          onClick={onReset}
                          sx={{
                            fontWeight: 600,
                            textTransform: "capitalize",
                            pl: 4,
                            pr: 4,
                            backgroundColor: "#212223",
                            borderColor: "#212223",
                            color: "#FFFFFF",
                            marginLeft: "10px",
                            "&:hover": {
                              // Customize the hover color
                              backgroundColor: "#212223",
                              borderColor: "#212223",
                            },
                          }}
                        >
                          Reset
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <Loader loading={isloading} />
      </Box>
    </>
  );
};
