// LoginScreen.js
import React from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Autocomplete,
  FormHelperText,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { CallbackParentTable } from "./CallbackParentTable";
import { DateTimePicker } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { getAllClients } from "../../store/actions/profile";
import { updateCampaignWithAlternateCamapaign } from "../../store/actions";
import { updateAssignedLead } from "../../store/actions/callBack";
import { Loader } from "../Common";


export const Callback = (props) => {
  const { onSearchCallBacks, getAllEmployeesList, getAllUser } = props;

  const callBackData = useSelector((state) => state?.callback?.callBackData);

  const userInfo = useSelector((state) => state?.loginAuth?.user_details);

  const initialCallBackSearchInfo = {
    employeeId: 0,
    agentId: 0,
    days: 0,
    fromDateTime: dayjs(),
    toDateTime: dayjs(),
  };

  //lead info
  const [callBackSearchInfo, setCallBackSearchInfo] = useState(
    initialCallBackSearchInfo
  );
  const [triedSubmit, setTriedSubmit] = useState(false);
  const [allCommentsExpanded, setAllCommentsExpanded] = useState(false);


  const dispatch = useDispatch();

  const [page, setPage] = React.useState(0);
  const [offset, setOffset] = useState(1);

  const userDetails = useSelector((state) => state?.profile?.allClients);

  const isAssignedLoading = useSelector(
    (state) => state?.callback?.isAssignedLoading
  );

  const allEmployees = useSelector(
    (state) => state?.appointment?.allEmployeelist
  );

  const agentNameError =
    triedSubmit && callBackSearchInfo?.agentId?.toString().trim().length === 0;

  const agentNameErrorMessage =
    triedSubmit && callBackSearchInfo?.agentId?.toString().trim().length === 0
      ? "Please select agent name"
      : "";

  const employeeNameError =
    triedSubmit &&
    callBackSearchInfo?.employeeId?.toString().trim().length === 0;

  const employeeNameErrorMessage =
    triedSubmit &&
      callBackSearchInfo?.employeeId?.toString().trim().length === 0
      ? "Please select emoloyee name"
      : "";

  const [inputValue, setInputValue] = React.useState("");
  const [inputValueTwo, setInputValueTwo] = React.useState("");

  const filterOptions = (options) => {
    // Filter options based on the inputValue (matching the prefix)
    return options.filter((option) =>
      option.full_name.toLowerCase().startsWith(inputValue?.toLowerCase())
    );
  };

  const filterOptionsAgent = (options) => {
    // Filter options based on the inputValue (matching the prefix)
    return options.filter((option) =>
      option.full_name.toLowerCase().startsWith(inputValueTwo?.toLowerCase())
    );
  };

  const handleChange = (event, newValue, type) => {
    switch (type) {
      case "agentName":
        setCallBackSearchInfo({
          ...callBackSearchInfo,
          agentId: newValue.id,
        });
        break;
      case "days":
        setCallBackSearchInfo({
          ...callBackSearchInfo,
          days: event.target.value,
        });
        break;
      case "employee":
        setCallBackSearchInfo({
          ...callBackSearchInfo,
          employeeId: newValue.id,
        });
        break;
      default:
        break;
    }
  };

  const fetchCallBacks = (payload, offset = 1) => {
    onSearchCallBacks(payload, offset);
  };

  const onSearch = () => {
    const formatedFromDate = moment(
      callBackSearchInfo.fromDateTime.$d,
      "hh:mm A"
    ).format("MM-DD-YYYY hh:mm A");

    const formatedToDate = moment(
      callBackSearchInfo.toDateTime.$d,
      "hh:mm A"
    ).format("MM-DD-YYYY hh:mm A");

    const payload = {
      agent:
        callBackSearchInfo.agentId !== 0
          ? callBackSearchInfo.agentId ?? null
          : null,
      days: callBackSearchInfo.days ?? null,
      from_datetime:
        callBackSearchInfo.days == 2 || callBackSearchInfo.days == 3
          ? formatedFromDate ?? null
          : null,
      to_datetime:
        callBackSearchInfo.days == 2 || callBackSearchInfo.days == 3
          ? formatedToDate ?? null
          : null,
      employee:
        userInfo?.user_type === "Employee"
          ? userInfo?.id
          : callBackSearchInfo.employeeId !== 0
            ? callBackSearchInfo.employeeId ?? null
            : null,
    };
    fetchCallBacks(payload);
  };

  useEffect(() => {
    const payload = {
      agent: null,
      days: 0,
      from_datetime: null,
      to_datetime: null,
      employee: userInfo?.user_type === "Employee" ? userInfo?.id : null,
    };
    fetchCallBacks(payload);
  }, []);

  const toggleAllComments = () => {
    setAllCommentsExpanded(!allCommentsExpanded);
  };

  useEffect(() => {
    getAllEmployeesList();
    dispatch(getAllClients());
  }, []);

  const fetchMoreRecord = (offset) => {
    const formatedFromDate = moment(
      callBackSearchInfo.fromDateTime.$d,
      "hh:mm A"
    ).format("MM-DD-YYYY hh:mm A");

    const formatedToDate = moment(
      callBackSearchInfo.toDateTime.$d,
      "hh:mm A"
    ).format("MM-DD-YYYY hh:mm A");

    const payload = {
      agent:
        callBackSearchInfo.agentId !== 0
          ? callBackSearchInfo.agentId ?? null
          : null,
      days:
        callBackSearchInfo.days !== 0 ? callBackSearchInfo.days ?? null : null,
      from_datetime:
        callBackSearchInfo.days == 2 || callBackSearchInfo.days == 3
          ? formatedFromDate ?? null
          : null,
      to_datetime:
        callBackSearchInfo.days == 2 || callBackSearchInfo.days == 3
          ? formatedToDate ?? null
          : null,
      employee:
        userInfo?.user_type === "Employee"
          ? userInfo?.id
          : callBackSearchInfo.employeeId !== 0
            ? callBackSearchInfo.employeeId ?? null
            : null,
    };
    fetchCallBacks(payload, offset);
  };

  const onUpdateCampaignWithAltCampaign = (payload, cb = () => { }) => {
    dispatch(
      updateCampaignWithAlternateCamapaign(
        payload,
        () => {
          onSearch();
          cb();
        },
        false
      )
    );
  };

  const updateEmployeeToCallBack = (payload, cb = () => { }) => {
    dispatch(
      updateAssignedLead(payload, () => {
        onSearch();
        cb();
      })
    );
  };

  return (
    <>
      <Box
        sx={{
          margin: { xs: "5px", md: '30px' },
          marginTop: { xs: "10px", md: '30px' },
          borderRadius: "15px!Important",
        }}
      >
        <Accordion
          defaultExpanded
          sx={{ borderRadius: "10px!Important", boxShadow: 3 }}
        >
          <Box
            sx={{
              display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: { xs: "0px", md: "15px" },
              paddingBottom: { xs: "10px", sm: "0px", md: "0px" },
              backgroundColor: " #d2f5ec",
              borderTopLeftRadius: "15px!Important",
              borderTopRightRadius: "15px!Important",
              borderBottom: 1,
              borderColor: "#676a6c4a",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{ color: "#212223", pointerEvents: "auto" }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                width: "100%",
                pointerEvents: "none",
              }}
            >
              <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: 18, color: "#212223", fontWeight: 600 }}
              >
                Listing Callbacks Scheduled | Total Count :{" "}
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 18,
                    color: "#18a689",
                    fontWeight: 600,
                    display: "initial",
                  }}
                >
                  ( {callBackData.length ?? 0} )
                </Typography>{" "}
              </Typography>
            </AccordionSummary>

            {userInfo?.user_type !== "Employee" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",

                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    width: 130,
                    textTransform: "capitalize",
                    marginLeft: { xs: "20px", sm: "0", md: "0", lg: "0" },
                    marginRight: { xs: "15px", sm: "15px", md: "10px", lg: "15px" },
                    backgroundColor: "#6DD6C2",
                    borderColor: "#6DD6C2",
                    fontWeight: 600,
                    color: "#212223",
                    "&:hover": {
                      backgroundColor: "#6DD6C2",
                      borderColor: "#6DD6C2",
                    },
                  }}
                >
                  Mass Update
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    width: 100,
                    backgroundColor: "transparent",
                    borderColor: "#6DD6C2",
                    textTransform: "capitalize",
                    border: 2,
                    fontWeight: 600,
                    color: "#1ab394",
                    size: "small",
                    "&:hover": {
                      backgroundColor: "#6DD6C2",
                      borderColor: "#6DD6C2!important",
                      color: "#212223",
                    },
                  }}
                >
                  Export
                  <ExitToAppIcon sx={{ marginLeft: "5px", fontSize: "20px" }} />
                </Button>
              </Box>
            )}


            <Button
              variant="contained"
              onClick={toggleAllComments}
              sx={{
                width: { xs: 150, sm: 120, md: 190, lg: 200 },
                height: 40,
                marginLeft: { xs: "20px", sm: "0", md: "0", lg: "0" },
                marginTop: { xs: "10px", sm: "0", md: "0", lg: "0" },
                textTransform: "capitalize",
                marginRight: 2,
                backgroundColor: "#6DD6C2",
                borderColor: "#6DD6C2",
                fontWeight: 600,
                color: "#212223",
                "&:hover": {
                  backgroundColor: "#6DD6C2",
                  borderColor: "#6DD6C2",
                },
              }}
            >
              {!allCommentsExpanded ? "Show" : "Hide"} Comments
            </Button>
          </Box>

          <Box sx={{

            border: { xs: "0", sm: "0", md: '1px solid #ddd' },
            marginX: { xs: "15px", sm: "27px", md: '18px' },
            marginTop: { xs: "15px", sm: "20px", md: '20px' },
            marginBottom: { xs: "15px", sm: "20px", md: '20px' },
            borderRadius: "7px"
            // border:"2px solid red" 
          }}>
            <Box sx={{
              display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
              flexWrap: { xs: "wrap", sm: "wrap", md: "wrap", lg: "inherit" },
              alignItems: 'center',
              gap: { xs: 2, sm: 2, md: 2, lg: 1 },
              // width: "100%",
              flexDirection: { xs: "column", sm: "row" }, // stack vertically on small screens

              margin: { xs: "0px", md: '20px' },
            }}>
              {userInfo?.user_type !== "Employee" && (
                <Box sx={{ width: "100%", maxWidth: { xs: "100%", sm: "100%", md: "240px", lg: "240px" }, }}>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl
                      size="small"
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "100%",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: agentNameError
                            ? "#d32f2f"
                            : "rgba(0, 0, 0, 0.23)",
                        },
                        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#6DD6C2",
                        },
                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6DD6C2!important",
                        },
                      }}
                    >
                      <Autocomplete
                        id="combo-box-demo"
                        options={allEmployees}
                        getOptionLabel={(option) => option.full_name}
                        onChange={(event, newValue) => {
                          handleChange(event, newValue, "employee");
                        }}
                        onInputChange={(event, newInputValue) =>
                          setInputValue(newInputValue)
                        }
                        filterOptions={filterOptions}
                        error={employeeNameError}
                        helperText={
                          employeeNameError && employeeNameErrorMessage
                        }
                        size="small"
                        disableClearable={true}
                        sx={{
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#18a689!important",
                            },
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(0, 0, 0, 0.23)",
                          },
                          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#18a689",
                          },
                          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#18a689",
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            "&.Mui-focused": {
                              color: "#6DD6C2",
                            },
                          },
                        }}
                        renderInput={(params) => (
                          <>
                            <TextField {...params} label="Select User" />
                            {employeeNameError && (
                              <FormHelperText
                                sx={{
                                  color: "#d32f2f",
                                }}
                              >
                                {employeeNameErrorMessage}
                              </FormHelperText>
                            )}
                          </>
                        )}
                        renderOption={(props, option, { inputValue }) => {
                          return (
                            <li
                              {...props}
                              key={`${option.id}-${option.full_name}`}
                            >
                              {option?.full_name}
                            </li>
                          );
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Box>
              )}
              <Box sx={{ width: "100%", maxWidth: { xs: "100%", sm: "100%", md: "240px", lg: "240px" }, }}>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl
                    size="small"
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "100%",
                        md: "100%",
                        lg: "100%",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: agentNameError
                          ? "#d32f2f"
                          : "rgba(0, 0, 0, 0.23)",
                      },
                      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#6DD6C2",
                      },
                      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6DD6C2!important",
                      },
                    }}
                  >
                    <Autocomplete
                      id="combo-box-demo"
                      options={userDetails}
                      getOptionLabel={(option) => option.full_name}
                      onChange={(event, newValue) => {
                        handleChange(event, newValue, "agentName");
                      }}
                      onInputChange={(event, newInputValue) =>
                        setInputValueTwo(newInputValue)
                      }
                      filterOptions={filterOptionsAgent}
                      error={agentNameError}
                      helperText={agentNameError && agentNameErrorMessage}
                      size="small"
                      disableClearable={true}
                      sx={{
                        "& .MuiInputLabel-root": {
                          "&.Mui-focused": {
                            color: "#18a689!important",
                          },
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(0, 0, 0, 0.23)",
                        },
                        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#18a689",
                        },
                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#18a689",
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          "&.Mui-focused": {
                            color: "#6DD6C2",
                          },
                        },
                      }}
                      renderInput={(params) => (
                        <>
                          <TextField {...params} label="Select Agent" />
                          {agentNameError && (
                            <FormHelperText
                              sx={{
                                color: "#d32f2f",
                              }}
                            >
                              {agentNameErrorMessage}
                            </FormHelperText>
                          )}
                        </>
                      )}
                      renderOption={(props, option, { inputValue }) => {
                        return (
                          <li
                            {...props}
                            key={`${option.id}-${option.full_name}`}
                          >
                            {option?.full_name}
                          </li>
                        );
                      }}
                    />
                  </FormControl>
                </Grid>
              </Box>

              <Box sx={{ width: "100%", maxWidth: { xs: "100%", sm: "100%", md: "240px", lg: "240px" }, }}>

                <FormControl
                  size="small"
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.23)",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#6DD6C2",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#6DD6C2!important",
                    },
                  }}
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                      fontSize: 15,
                      "&.Mui-focused": {
                        color: "#6DD6C2!important",
                        fontWeight: "600",
                        margin: "0",
                      },
                    }}
                  >
                    Select Day{" "}
                  </InputLabel>
                  <Select
                    sx={{ backgroundColor: "#fff!important" }}
                    id="selectinfo"
                    label="Select Agent"
                    value={callBackSearchInfo.days}
                    onChange={(event) => {
                      handleChange(event, "", "days");
                    }}
                    size="small"
                  >
                    <MenuItem value={0} sx={{ fontSize: 15 }}>
                      Today
                    </MenuItem>
                    <MenuItem value={1} sx={{ fontSize: 15 }}>
                      Tomorrow
                    </MenuItem>
                    <MenuItem value={-1} sx={{ fontSize: 15 }}>
                      Yesterday
                    </MenuItem>
                    <MenuItem value={2} sx={{ fontSize: 15 }}>
                      All
                    </MenuItem>
                    <MenuItem value={3} sx={{ fontSize: 15 }}>
                      With Created Date
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ width: "100%", maxWidth: { xs: "100%", sm: "100%", md: "260px", lg: "260px" }, }}>
 
                <Box sx={{ marginTop: '-7px' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DateTimePicker"]}>
                      <DateTimePicker
                        label='From Date Time'
                        disabled={
                          callBackSearchInfo.days == 2 ||
                            callBackSearchInfo.days == 3
                            ? false
                            : true
                        }
                        value={callBackSearchInfo.fromDateTime}
                        onChange={(value) =>
                          setCallBackSearchInfo({
                            ...callBackSearchInfo,
                            fromDateTime: value,
                          })
                        }
                        sx={{
                          fontSize: "11px",
                          "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            padding: "9px 13px 9px",
                          },
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
              </Box>

              <Box sx={{ width: "100%", maxWidth: { xs: "100%", sm: "100%", md: "260px", lg: "260px" }, }}>
                <Box sx={{ marginTop: '-7px' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DateTimePicker"]}>
                      <DateTimePicker
                        label='To Date Time'
                        disabled={
                          callBackSearchInfo.days == 2 ||
                            callBackSearchInfo.days == 3
                            ? false
                            : true
                        }
                        value={callBackSearchInfo.toDateTime}
                        defaultValue={dayjs()}
                        onChange={(value) =>
                          setCallBackSearchInfo({
                            ...callBackSearchInfo,
                            toDateTime: value,
                          })
                        }
                        sx={{
                          fontSize: "11px",
                          "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            padding: "9px 13px 9px",
                          },
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
              </Box>
              <Button
                type="submit"
                variant="contained"
                onClick={onSearch}
                
                sx={{
                  fontWeight: 600,
                  textTransform: "capitalize",
                  pl: 4,
                  pr: 4,
                  backgroundColor: "#6DD6C2",
                  borderColor: "#6DD6C2",
                  color: "#212223",
                  "&:hover": {
                    backgroundColor: "#6DD6C2",
                    borderColor: "#6DD6C2",
                  },
                }}
              >
                Search
              </Button>
            </Box>
          </Box>
          <AccordionDetails>
            <Box
              sx={{
                padding: {
                  xs: "20px 0px 0px 0px",
                  sm: "20px 0px 0px 0px",
                  md: "20px 0px 0px 0px",
                  lg: "20px 0px 0px 0px",
                },
              }}
            >
              <CallbackParentTable
                callbackData={callBackData}
                toggleAllComments={toggleAllComments}
                allCommentsExpanded={allCommentsExpanded}
                page={page}
                setPage={setPage}
                offset={offset}
                setOffset={setOffset}
                fetchMoreRecord={fetchMoreRecord}
                onUpdateCampaignWithAltCampaign={
                  onUpdateCampaignWithAltCampaign
                }
                updateEmployeeToCallBack={updateEmployeeToCallBack}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};
