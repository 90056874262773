import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { ClickAwayListener, Skeleton, Typography } from "@mui/material";
import { ActiveData, Loader } from "../Common";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { AdminTableHead } from "./AdminTableHead";
import { AdminTableToolbar } from "./AdminTableToolbar";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import { formatPhoneNumber } from "../../helpers";
import { clientResetPassword } from "../../store/actions";
import { ProfileModal } from "./ProfileModal";

export const AdminTable = (props) => {
	const { rows, onDeleteAdmin, handleStatusSubmit } = props;

	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("firstName");
	const [selected, setSelected] = useState([]);
	const [page, setPage] = useState(0);
	const [isShow, setIsShow] = useState(false);
	const [userData, setUserData] = useState();
	const [tooltipID, setTooltipID] = useState(null);

	const [rowsPerPage, setRowsPerPage] = React.useState(5);

	const dispatch = useDispatch();

	const navigate = useNavigate();
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = rows?.map((n) => n?.operations?.id);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleDelete = async () => {
		const payload = {
			profile_ids: selected,
		};
		await onDeleteAdmin(payload);
		setSelected([]);
	};

	const handleClick = (event, row) => {
		if (!event.target.closest(".show-button")) {
			// Exclude clicks on the "Show" button
			const selectedIndex = selected?.indexOf(row?.operations?.id);
			let newSelected = [];

			if (selectedIndex === -1) {
				newSelected = newSelected?.concat(selected, row?.operations?.id);
			} else if (selectedIndex === 0) {
				newSelected = newSelected?.concat(selected?.slice(1));
			} else if (selectedIndex === selected.length - 1) {
				newSelected = newSelected?.concat(selected?.slice(0, -1));
			} else if (selectedIndex > 0) {
				newSelected = newSelected?.concat(
					selected?.slice(0, selectedIndex),
					selected?.slice(selectedIndex + 1)
				);
			}

			setSelected(newSelected);
		}
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const isSelected = (row) => {
		if (!row) {
			return false;
		}
		return selected.includes(row?.operations?.id);
	};

	const emptyRows =
		rowsPerPage - Math.min(rowsPerPage, rows?.length - page * rowsPerPage);

	const stableSort = (array = [], comparator) => {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis?.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	};

	const getComparator = (order, orderBy) => {
		return order === "desc"
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	};

	const descendingComparator = (a, b, orderBy) => {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	};

	const handleShowDetails = (user) => {
		// Handle showing employee details in modal
		setUserData(user);
		setIsShow(true);

		// Pass the employee details to the modal component
	};

	let loading = useSelector((state) => state?.client?.loading);
	let resetloading = useSelector((state) => state?.client?.isResetLoading);

	const handleTooltipClose = () => {
		setTooltipID(null);
	};

	const handleTooltipOpen = (id) => {
		setTooltipID(id);
	};

	const resetClientPswd = (id) => {
		dispatch(clientResetPassword(id));
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Paper sx={{ width: "100%", mb: 2 }}>
				{selected.length > 0 && (
					<AdminTableToolbar
						numSelected={selected?.length}
						handleDelete={handleDelete}
						setSelected={setSelected}
					/>
				)}

				<TableContainer sx={{ maxHeight: 500, minHeight: 100 }}>
					<Table
						sx={{ minWidth: 750, border: 1, borderColor: "#ddd" }}
						aria-labelledby="tableTitle"
						size="medium"
					>
						<AdminTableHead
							numSelected={selected?.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={rows?.length}
							disableSort={["view", "operations", "resetPassword"]} // Disable sorting for "View" and "Operations" columns
						/>
						<TableBody>
							<>
								{loading ? (
									<>
										{[...Array(rowsPerPage)].map((_, index) => (
											<TableRow>
												<TableCell
													colSpan={11}
													sx={{ paddingTop: 1, paddingBottom: 1 }}
												>
													<Skeleton
														variant="rectangular"
														width="100%"
														height={38}
													/>
												</TableCell>
											</TableRow>
										))}
									</>
								) : (
									<>
										{rows?.length === 0 ? (
											<Box
												sx={{
													justifyContent: "center",
													alignItems: "center",
													position: "absolute",
													left: "44%",
													top: "73%",
												}}
											>
												<Typography
													component="h2"
													variant="h2"
													sx={{
														fontSize: 18,
														color: "#454647",
														fontWeight: 600,
													}}
												>
													No Record Found!
												</Typography>
											</Box>
										) : (
											<>
												{stableSort(rows, getComparator(order, orderBy)).map(
													(row, index) => {
														const isItemSelected = isSelected(row);
														const labelId = `enhanced-table-checkbox-${index}`;

														return (
															<TableRow
																hover
																sx={{
																	backgroundColor:
																		index % 2 == 1 ? "#fff" : "#fff",
																	borderBottom: "0!important",
																	"&:hover": {
																		backgroundColor: "#F4F6FA!important",
																	},
																}}
																onClick={(event) => handleClick(event, row)}
																role="checkbox"
																aria-checked={isItemSelected}
																tabIndex={-1}
																key={row.operations.id}
																selected={isItemSelected}
															>
																<TableCell
																	padding="checkbox"
																	sx={{ borderBottom: "0" }}
																>
																	<Checkbox
																		size="small"
																		checked={isItemSelected}
																		inputProps={{
																			"aria-labelledby": labelId,
																		}}
																	/>
																</TableCell>
																<TableCell
																	sx={{
																		color: "#6B7489",
																		fontWeight: 500,
																		fontSize: 14,
																		borderBottom: "0",
																	}}
																	component="th"
																	id={labelId}
																	scope="row"
																	padding="none"
																>
																	{row?.firstName}
																</TableCell>
																<TableCell
																	sx={{
																		color: "#6B7489",
																		fontWeight: 500,
																		fontSize: 14,
																		borderBottom: "0",
																	}}
																	align="left"
																>
																	{row?.lastName}
																</TableCell>
																<TableCell
																	sx={{
																		color: "#6B7489",
																		fontWeight: 500,
																		fontSize: 14,
																		borderBottom: "0",
																		whiteSpace: "nowrap",
																	}}
																	align="left"
																>
																	{formatPhoneNumber(row.phoneNumber) ??
																		row.phoneNumber}
																</TableCell>
																<TableCell
																	sx={{
																		color: "#6B7489",
																		fontWeight: 500,
																		fontSize: 14,
																		borderBottom: "0",
																	}}
																	align="left"
																>
																	<ClickAwayListener
																		onClickAway={handleTooltipClose}
																	>
																		<div>
																			<Tooltip
																				sx={{
																					marginTop: "0!important",
																					" & .MuiTooltip-tooltip": {
																						marginTop: "0!important",
																					},
																				}}
																				arrow
																				PopperProps={{
																					disablePortal: false,
																				}}
																				onClose={handleTooltipClose}
																				open={
																					tooltipID === row?.operations?.id
																						? true
																						: false
																				}
																				disableFocusListener
																				disableHoverListener
																				disableTouchListener
																				title={
																					<ActiveData
																						onClose={handleTooltipClose}
																						user={row}
																						onSubmit={handleStatusSubmit}
																						label={"Agent Status"}
																					/>
																				}
																			>
																				<Button
																					sx={{
																						"&:hover": {
																							color: "#6B7489",
																						},
																						width: "50px",
																						height: "20px",
																						minWidth: "50px",
																						padding: "0 0 0 5px",
																						color: "#45c9b1",
																						fontWeight: 600,
																						fontSize: "14px",
																						textTransform: "capitalize",
																					}}
																					onClick={(event) => {
																						event.stopPropagation(); // Prevent event propagation to TableRow
																						handleTooltipOpen(
																							row?.operations?.id
																						);
																					}}
																				>
																					{row?.isActive
																						? "Active"
																						: "Inactive"}
																				</Button>
																			</Tooltip>
																		</div>
																	</ClickAwayListener>
																</TableCell>

																<TableCell
																	align="left"
																	sx={{
																		borderBottom: "0",
																	}}
																>
																	<Button
																		variant="contained"
																		sx={{
																			width: "20px",
																			height: "20px",
																			minWidth: "30px",
																			pl: 1.5,
																			pr: 1.5,
																			pt: 1.5,
																			pb: 1.5,
																			backgroundColor: "#6B7489",
																			"&:hover": {
																				backgroundColor: "#6B7489",
																			},
																			borderColor: "#6B7489",
																			color: "#fff",
																		}}
																		onClick={(event) => {
																			event.stopPropagation(); // Prevent event propagation to TableRow
																			navigate("/CreateAdmin", {
																				state: {
																					isEdit: true,
																					adminData: row?.operations,
																				},
																			});
																		}}
																		className="show-button" // Add a class to identify the "Show" button
																	>
																		<DriveFileRenameOutlineIcon
																			sx={{ fontSize: "22px" }}
																		/>
																	</Button>
																</TableCell>
																<TableCell
																	align="left"
																	sx={{
																		borderBottom: "0",
																	}}
																>
																	<Button
																		variant="contained"
																		sx={{
																			fontWeight: 500,
																			textTransform: "capitalize",
																			width: "20px",
																			height: "20px",
																			minWidth: "30px",
																			pl: 1.5,
																			pr: 1.5,
																			pt: 1.5,
																			pb: 1.5,
																			ml: 1,
																			backgroundColor: "#6B7489",
																			"&:hover": {
																				backgroundColor: "#6B7489",
																			},
																			borderRadius: "3px",
																			borderColor: "#6B7489",
																			color: "#fff",
																		}}
																		onClick={(event) => {
																			event.stopPropagation(); // Prevent event propagation to TableRow
																			handleShowDetails(row?.operations.user);
																		}}
																		className="show-button" // Add a class to identify the "Show" button
																	>
																		<RemoveRedEyeIcon
																			sx={{ fontSize: "19px" }}
																		/>
																	</Button>
																</TableCell>
															</TableRow>
														);
													}
												)}
											</>
										)}
									</>
								)}

								{emptyRows > 0 && !loading && (
									<TableRow style={{ height: 53 * emptyRows }}>
										<TableCell colSpan={11} />
									</TableRow>
								)}
							</>
						</TableBody>
					</Table>
				</TableContainer>

				<ProfileModal
					open={isShow}
					profileInformation={userData}
					onClose={() => {
						setIsShow(false);
					}}
				/>
				<Loader loading={resetloading} />
			</Paper>
		</Box>
	);
};
