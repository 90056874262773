import {
  AUTH_CALLBACK_FAILURE,
  AUTH_CALLBACK_IN_PROGRESS,
  AUTH_CALLBACK_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_IN_PROGRESS,
  CHANGE_PASSWORD_SUCCESS,
  GET_ALL_CLIENTS_FAILURE,
  GET_ALL_CLIENTS_IN_PROGRESS,
  GET_ALL_CLIENTS_SUCCESS,
  GET_GOOGLE_AUTH_URL_FAILURE,
  GET_GOOGLE_AUTH_URL_IN_PROGRESS,
  GET_GOOGLE_AUTH_URL_SUCCESS,
  GET_USER_PROFILE_FAILURE,
  GET_USER_PROFILE_IN_PROGRESS,
  GET_USER_PROFILE_SUCCESS,
  GOOGLE_CALENDAR_LOGIN_STATUS_FAILURE,
  GOOGLE_CALENDAR_LOGIN_STATUS_IN_PROGRESS,
  GOOGLE_CALENDAR_LOGIN_STATUS_SUCCESS,
  SYNC_GOOGLE_CALENDAR_FAILURE,
  SYNC_GOOGLE_CALENDAR_IN_PROGRESS,
  SYNC_GOOGLE_CALENDAR_SUCCESS,
  UPDATE_PROFILE_KEY_FAILURE,
  UPDATE_PROFILE_KEY_IN_PROGRESS,
  UPDATE_PROFILE_KEY_SUCCESS,
} from "../actions/profile/types";
import initialState from "../initialState";

export const profile = (state = initialState.profile, action) => {
  switch (action.type) {
    case GET_GOOGLE_AUTH_URL_IN_PROGRESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
      };
    case GET_GOOGLE_AUTH_URL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
      };
    case GET_GOOGLE_AUTH_URL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: action?.error?.message,
      };

    case SYNC_GOOGLE_CALENDAR_IN_PROGRESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
        isCalenderSync: false,
      };
    case SYNC_GOOGLE_CALENDAR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
        isCalenderSync: true,
        lastSyncTime: action?.data[0]?.time ?? "",
      };
    case SYNC_GOOGLE_CALENDAR_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: action?.error?.message,
        isCalenderSync: false,
      };
    // case UPDATE_LOGIN_STATUS:
    //   return {
    //     ...state,
    //     isCalendarLogin: action?.data?.islogin,
    //     calendarType: action?.data?.loginType,
    //   };
    case AUTH_CALLBACK_IN_PROGRESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
      };
    case AUTH_CALLBACK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
      };
    case AUTH_CALLBACK_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case GOOGLE_CALENDAR_LOGIN_STATUS_IN_PROGRESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
      };
    case GOOGLE_CALENDAR_LOGIN_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        isCalendarLogin: action.data.status,
        calendarType: action.data.type !== null ? action.data.type : "",
        errorMessage: "",
      };
    case GOOGLE_CALENDAR_LOGIN_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: action?.error?.message,
        isCalendarLogin:false,
        calendarType:null,
      };
    case CHANGE_PASSWORD_IN_PROGRESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
      };
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case GET_USER_PROFILE_IN_PROGRESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
      };
    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userDetails: action?.data,
        error: false,
        errorMessage: "",
      };
    case GET_USER_PROFILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        userDetails: {},
        error: true,
        errorMessage: action?.error?.message,
      };
    case GET_ALL_CLIENTS_IN_PROGRESS:
      return {
        ...state,
        allClients: [],
        error: false,
        errorMessage: "",
      };
    case GET_ALL_CLIENTS_SUCCESS:
      return {
        ...state,
        allClients: action?.data,
        error: false,
        errorMessage: "",
      };
    case GET_ALL_CLIENTS_FAILURE:
      return {
        ...state,
        allClients: [],
        error: true,
        errorMessage: action?.error?.message,
      };
    case UPDATE_PROFILE_KEY_IN_PROGRESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
      };
    case UPDATE_PROFILE_KEY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
      };
    case UPDATE_PROFILE_KEY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    default:
      return state;
  }
};
