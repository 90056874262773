import { connect } from "react-redux";
import {
    CreateAdmin,
    DeleteAdmin,
    GetAdmins,
    UpdateAdmin,
} from "../store/actions";

import { AdminList, Create } from "../components/AdminComponent";
import { useLocation } from "react-router-dom";

const mapStateToProps = (state) => ({
    adminsData: state?.admin?.admin_listing?.results,
});

const mapDispatchToProps = (dispatch) => {
    return {
        GetAllAdmins: (payload, cb) => dispatch(GetAdmins(payload, cb)),
        onCreateAdmin: (payload, cb) => dispatch(CreateAdmin(payload, cb)),
        onUpdateAdmin: (employeeId, payload, cb) =>
            dispatch(UpdateAdmin(employeeId, payload, cb)),
        onDeleteAdmin: (payload, cb) => dispatch(DeleteAdmin(payload, cb)),
    };
};

export const AdminContainer = () => {
    let location = useLocation();
    let ComponentToRender;
    switch (location.pathname) {
        case "/CreateAdmin":
            ComponentToRender = Create;
            break;
        case "/AdminList":
            ComponentToRender = AdminList;
            break;
        default:
            ComponentToRender = null;
    }

    let Container = connect(
        mapStateToProps,
        mapDispatchToProps
    )(ComponentToRender);
    return <Container />;
};
