// LoginScreen.js
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Autocomplete,
  FormHelperText,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled, useTheme } from "@mui/material/styles";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { AppointmentsParentTable } from "./AppointmentsParentTable";
import { DateTimePicker } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getAllClients } from "../../store/actions/profile";
import {
  updateAppointmentKey,
  updateAppointmentStatus,
  updateLeadKey,
} from "../../store/actions/lead";
import { Loader } from "../Common";
import { tableToCSV } from "../../helpers";

export const AppointmentsList = (props) => {
  const {
    onSearchAppointments,
    onDeleteAppointment,
    getAllEmployeesList,
    getAllUser,
  } = props;
  const userInfo = useSelector((state) => state?.loginAuth?.user_details);

  const initialAppSearchInfo = {
    employeeId: 0,
    agentId: 0,
    days: userInfo?.user_type === "Client" ? 6 : 1,
    fromDateTime: dayjs(),
    toDateTime: dayjs(),
  };

  //lead info
  const [appSearchInfo, setAppSearchInfo] = useState(initialAppSearchInfo);
  const [triedSubmit, setTriedSubmit] = useState(false);
  const appointmentList = useSelector(
    (state) => state?.appointment?.appointmentData?.appointmentData
  );

  const isAppointmentStatusUpdate = useSelector(
    (state) => state?.lead?.isAppointmentStatusUpdate
  );

  const isloading = useSelector((state) => state?.lead?.isLoading);

  const dispatch = useDispatch();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [inputValue, setInputValue] = React.useState("");
  const [inputValueTwo, setInputValueTwo] = React.useState("");

  const filterOptions = (options, { inputValue }) => {
    // Filter options based on the inputValue (matching the prefix)
    return options.filter((option) =>
      option.full_name.toLowerCase().startsWith(inputValue.toLowerCase())
    );
  };

  const userDetails = useSelector((state) => state?.profile?.allClients);

  const allEmployees = useSelector(
    (state) => state?.appointment?.allEmployeelist
  );

  const agentNameError =
    triedSubmit && appSearchInfo?.agentId?.toString().trim().length === 0;

  const agentNameErrorMessage =
    triedSubmit && appSearchInfo?.agentId?.toString().trim().length === 0
      ? "Please select agent name"
      : "";

  const employeeNameError =
    triedSubmit && appSearchInfo?.employeeId?.toString().trim().length === 0;

  const employeeNameErrorMessage =
    triedSubmit && appSearchInfo?.employeeId?.toString().trim().length === 0
      ? "Please select emoloyee name"
      : "";

  const handleChange = (event, newValue, type) => {
    switch (type) {
      case "agentName":
        setAppSearchInfo({
          ...appSearchInfo,
          agentId: newValue.id,
        });
        break;
      case "days":
        setAppSearchInfo({
          ...appSearchInfo,
          days: event.target.value,
        });
        break;
      case "employee":
        setAppSearchInfo({
          ...appSearchInfo,
          employeeId: newValue.id,
        });
        break;
      default:
        break;
    }
  };

  const fetchAppointments = (payload) => {
    onSearchAppointments(payload);
  };

  const fetchAppointmentForClient = () => {
    const formatedFromDate = moment(
      appSearchInfo.fromDateTime.$d,
      "hh:mm A"
    ).format("MM-DD-YYYY hh:mm A");

    const formatedToDate = moment(
      appSearchInfo.toDateTime.$d,
      "hh:mm A"
    ).format("MM-DD-YYYY hh:mm A");
    const payload = {
      agent: userInfo?.id ?? 0,
      days: appSearchInfo.days ?? 0,
      from_datetime: appSearchInfo.days === 5 ? formatedFromDate ?? null : null,
      to_datetime: appSearchInfo.days === 5 ? formatedToDate ?? null : null,
      employee: null,
    };
    fetchAppointments(payload);
  };

  const onSearch = () => {
    const formatedFromDate = moment(
      appSearchInfo.fromDateTime.$d,
      "hh:mm A"
    ).format("MM-DD-YYYY hh:mm A");

    const formatedToDate = moment(
      appSearchInfo.toDateTime.$d,
      "hh:mm A"
    ).format("MM-DD-YYYY hh:mm A");

    const payload = {
      agent: appSearchInfo.agentId ?? 0,
      days: appSearchInfo.days ?? 0,
      from_datetime: appSearchInfo.days === 5 ? formatedFromDate ?? null : null,
      to_datetime: appSearchInfo.days === 5 ? formatedToDate ?? null : null,
      employee:
        userInfo?.user_type === "Employee"
          ? userInfo?.id
          : appSearchInfo?.employeeId,
    };

    userInfo?.user_type === "Client"
      ? fetchAppointmentForClient()
      : fetchAppointments(payload);
  };

  const deleteAppointment = (id) => {
    onDeleteAppointment(id, onSearch);
  };

  useEffect(() => {
    getAllEmployeesList();
    dispatch(getAllClients());
    const payload = {
      agent: userInfo?.id ?? 0,
      days: 6 ?? 0,
      from_datetime: null,
      to_datetime: null,
      employee: null,
    };
    userInfo?.user_type === "Client" && fetchAppointments(payload);
    const initialPayload = {
      agent: null,
      days: 1,
      from_datetime: null,
      to_datetime: null,
      employee: userInfo?.user_type === "Employee" ? userInfo?.id : null,
    };
    userInfo?.user_type !== "Client" && fetchAppointments(initialPayload);
  }, []);

  const onChangeAppointmentKey = (id, key, val, cb = () => { }) => {
    dispatch(
      updateAppointmentKey(id, key, val, () => {
        onSearch();
        cb();
      })
    );
  };

  const onChangeLeadKey = (leadId, key, val, cb = () => { }) => {
    dispatch(
      updateLeadKey(leadId, key, val, () => {
        onSearch();
        cb();
      })
    );
  };

  const onAppointmentStatus = (payload, cb = () => { }) => {
    dispatch(
      updateAppointmentStatus(payload, () => {
        onSearch();
        cb();
      })
    );
  };

  return (
    <>
      <Box
        sx={{
          margin: { xs: "10px", md: '30px' },
          marginTop: { xs: "10px", md: '30px' },
          boxShadow: 1,

        }}
      >
        <Accordion defaultExpanded sx={{ borderRadius: "10px!Important" }}>
          <Box
            sx={{
              display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: { xs: "0px", md: "15px" },
              paddingBottom: { xs: "10px", sm: "0px", md: "0px" },
              backgroundColor: " #d2f5ec",
              borderTopLeftRadius: "15px!Important",
              borderTopRightRadius: "15px!Important",
              borderBottom: 1,
              borderColor: "#676a6c4a",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{ color: "#212223", pointerEvents: "auto" }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                width: "100%",
                borderRadius: "10px 10px 0 0!Important",
                pointerEvents: "none",
              }}
            >
              <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: { xs: 16, sm: 16, md: 17, lg: 18 }, color: "#212223", fontWeight: 600 }}
              >
                Appointments List | Total Count :{" "}
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 18,
                    color: "#6DD6C2",
                    fontWeight: 600,
                    display: "initial",
                  }}
                >
                  ( {appointmentList?.length ?? 0} )
                </Typography>{" "}
              </Typography>
            </AccordionSummary>
            <Box sx={{
              display: { xs: "flex", sm: "flex", md: "flex", lg: "flex" },
              ml: { xs: 2, sm: 1, md: 0, lg: 0 },
            }}>
              {userInfo?.user_type !== "Client" && (
                <Button
                  variant="contained"
                  sx={{

                    width: { xs: 120, sm: 120, md: 150, lg: 150 },
                    height: 40,
                    textTransform: "capitalize",
                    marginRight: 1,
                    backgroundColor: "#6DD6C2",
                    borderColor: "#6DD6C2",
                    fontWeight: 600,
                    color: "#212223",
                    "&:hover": {
                      backgroundColor: "#6DD6C2",
                      borderColor: "#6DD6C2",
                    },
                  }}
                >
                  Mass Update
                </Button>
              )}

              <Button
                variant="contained"
                onClick={() => {
                  tableToCSV("appointmentListTable");
                }}
                sx={{

                  mr: { xs: 1, sm: 1, md: 2, lg: 2 },
                  width: 100,
                  backgroundColor: "transparent",
                  borderColor: "#6DD6C2",
                  textTransform: "capitalize",
                  border: 2,
                  fontWeight: 600,
                  color: "#1ab394",
                  size: "small",
                  boxShadow: "0",
                  "&:hover": {
                    backgroundColor: "#6DD6C2",
                    borderColor: "#6DD6C2!important",
                    color: "#212223",
                  },
                }}
              >
                Export
                <ExitToAppIcon sx={{ marginLeft: "5px", fontSize: "20px" }} />
              </Button>
            </Box>
          </Box>

          <Box
            sx={{
              display: { xs: "grid", sm: "grid", md: "grid", lg: "flex" },

              alignItems: "center",

              border: { xs: "0", sm: "0", md: '1px solid #ddd' },
              marginX: { xs: "15px", sm: "27px", md: '18px' },
              marginTop: { xs: "15px", sm: "20px", md: '20px' },
              marginBottom: { xs: "15px", sm: "20px", md: '20px' },
              borderRadius: "7px"
              // border:"2px solid red" 
            }}
          >
            <Box
              sx={{
                display: { xs: "grid", sm: "grid", md: "grid", lg: "flex" },

                alignItems: 'center',
                gap: 2,
                // width: "100%",
                flexDirection: { xs: "column", sm: "row" }, // stack vertically on small screens
                width: "100%",
                margin: { xs: "0px", md: '20px' },
              }}
            >
              <Box sx={{ width: "100%", }}>
                {userInfo?.user_type !== "Client" && (
                  <>
                    <Box sx={{ display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" }, gap: 2 }}>
                      {userInfo?.user_type !== "Employee" && (
                        <Box sx={{ width: "100%", maxWidth: { xs: "100%", sm: "100%", md: "280px", lg: "280px" }, }}>
                          <Grid item xs={12} sm={6} md={3}>
                            <FormControl
                              size="small"
                              sx={{
                                width: {
                                  xs: "100%",
                                  sm: "100%",
                                  md: "100%",
                                  lg: "100%",
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: agentNameError
                                    ? "#d32f2f"
                                    : "rgba(0, 0, 0, 0.23)",
                                },
                                "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6DD6C2",
                                },
                                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#6DD6C2!important",
                                },
                              }}
                            >
                              <Autocomplete
                                id="combo-box-demo"
                                options={allEmployees}
                                getOptionLabel={(option) => option.full_name}
                                inputValue={inputValue}
                                onInputChange={(event, newInputValue) =>
                                  setInputValue(newInputValue)
                                }
                                filterOptions={filterOptions}
                                onChange={(event, newValue) => {
                                  handleChange(event, newValue, "employee");
                                }}
                                error={employeeNameError}
                                helperText={
                                  employeeNameError && employeeNameErrorMessage
                                }
                                size="small"
                                disableClearable={true}
                                sx={{
                                  "& .MuiInputLabel-root": {
                                    "&.Mui-focused": {
                                      color: "#18a689!important",
                                    },
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "rgba(0, 0, 0, 0.23)",
                                  },
                                  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "#18a689",
                                  },
                                  "& .Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "#18a689",
                                  },
                                }}
                                InputLabelProps={{
                                  sx: {
                                    "&.Mui-focused": {
                                      color: "#6DD6C2",
                                    },
                                  },
                                }}
                                renderInput={(params) => (
                                  <>
                                    <TextField

                                      {...params}
                                      label="Select User"
                                    />
                                    {employeeNameError && (
                                      <FormHelperText
                                        sx={{
                                          color: "#d32f2f",
                                        }}
                                      >
                                        {employeeNameErrorMessage}
                                      </FormHelperText>
                                    )}
                                  </>
                                )}
                                renderOption={(props, option, { inputValue }) => {
                                  return (
                                    <li
                                      {...props}
                                      key={`${option.id}-${option.full_name}`}
                                    >
                                      {option?.full_name}
                                    </li>
                                  );
                                }}
                              />
                            </FormControl>
                          </Grid>
                        </Box>
                      )}


                      <Box sx={{ width: "100%", maxWidth: { xs: "100%", sm: "100%", md: "280px", lg: "280px" }, }}>
                        <Grid item xs={12} sm={6} md={3}>
                          <FormControl
                            size="small"
                            sx={{
                              width: {
                                xs: "100%",
                                sm: "100%",
                                md: "100%",
                                lg: "100%",
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: agentNameError
                                  ? "#d32f2f"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#6DD6C2!important",
                              },
                            }}
                          >
                            <Autocomplete
                              id="combo-box-demo"
                              options={userDetails}
                              getOptionLabel={(option) => option.full_name}
                              inputValue={inputValueTwo}
                              onInputChange={(event, newInputValue) =>
                                setInputValueTwo(newInputValue)
                              }
                              filterOptions={filterOptions}
                              onChange={(event, newValue) => {
                                handleChange(event, newValue, "agentName");
                              }}
                              error={agentNameError}
                              helperText={agentNameError && agentNameErrorMessage}
                              size="small"
                              disableClearable={true}
                              sx={{
                                "& .MuiInputLabel-root": {
                                  "&.Mui-focused": {
                                    color: "#18a689!important",
                                  },
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "rgba(0, 0, 0, 0.23)",
                                },
                                "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#18a689",
                                },
                                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#18a689",
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  "&.Mui-focused": {
                                    color: "#6DD6C2",
                                  },
                                },
                              }}
                              renderInput={(params) => (
                                <>
                                  <TextField {...params} label="Select Agent" />
                                  {agentNameError && (
                                    <FormHelperText
                                      sx={{
                                        color: "#d32f2f",
                                      }}
                                    >
                                      {agentNameErrorMessage}
                                    </FormHelperText>
                                  )}
                                </>
                              )}
                              renderOption={(props, option, { inputValue }) => {
                                return (
                                  <li
                                    {...props}
                                    key={`${option.id}-${option.full_name}`}
                                  >
                                    {option?.full_name}
                                  </li>
                                );
                              }}
                            />
                          </FormControl>
                        </Grid>
                      </Box>
                      <Box sx={{ width: "100%", maxWidth: { xs: "100%", sm: "100%", md: "280px", lg: "280px" }, }}>

                        <FormControl
                          size="small"
                          sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgba(0, 0, 0, 0.23)",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6DD6C2!important",
                            },
                          }}
                        >
                          <InputLabel
                            id="demo-simple-select-label"
                            sx={{
                              fontSize: 15,
                              "&.Mui-focused": {
                                color: "#6DD6C2!important",
                                fontWeight: "600",
                                margin: "0",
                              },
                            }}
                          >
                            Select Day{" "}
                          </InputLabel>
                          {userInfo?.user_type === "Client" ? (
                            <Select
                              sx={{ backgroundColor: "#fff!important" }}
                              id="selectinfo"
                              label="Select Agent"
                              value={appSearchInfo.days}
                              onChange={(event) => {
                                handleChange(event, "", "days");
                              }}
                              size="small"
                            >
                              <MenuItem value={6} sx={{ fontSize: 15 }}>
                                Upcoming
                              </MenuItem>
                              <MenuItem value={0} sx={{ fontSize: 15 }}>
                                Today
                              </MenuItem>
                              <MenuItem value={1} sx={{ fontSize: 15 }}>
                                Tomorrow
                              </MenuItem>
                              <MenuItem value={-1} sx={{ fontSize: 15 }}>
                                Yesterday
                              </MenuItem>
                              <MenuItem value={2} sx={{ fontSize: 15 }}>
                                2 days
                              </MenuItem>
                              <MenuItem value={3} sx={{ fontSize: 15 }}>
                                3 days
                              </MenuItem>
                              <MenuItem value={4} sx={{ fontSize: 15 }}>
                                4 days
                              </MenuItem>
                              <MenuItem value={5} sx={{ fontSize: 15 }}>
                                All
                              </MenuItem>
                            </Select>
                          ) : (
                            <Select
                              sx={{ backgroundColor: "#fff!important" }}
                              id="selectinfo"
                              label="Select Agent"
                              value={appSearchInfo.days}
                              onChange={(event) => {
                                handleChange(event, "", "days");
                              }}
                              size="small"
                            >
                              <MenuItem value={0} sx={{ fontSize: 15 }}>
                                Today
                              </MenuItem>
                              <MenuItem value={1} sx={{ fontSize: 15 }}>
                                Tomorrow
                              </MenuItem>
                              <MenuItem value={-1} sx={{ fontSize: 15 }}>
                                Yesterday
                              </MenuItem>
                              <MenuItem value={2} sx={{ fontSize: 15 }}>
                                2 days
                              </MenuItem>
                              <MenuItem value={3} sx={{ fontSize: 15 }}>
                                3 days
                              </MenuItem>
                              <MenuItem value={4} sx={{ fontSize: 15 }}>
                                4 days
                              </MenuItem>
                              <MenuItem value={5} sx={{ fontSize: 15 }}>
                                All
                              </MenuItem>
                            </Select>
                          )}
                        </FormControl>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>


              <Box sx={{
                display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: { xs: "10px", sm: "0px", md: "0px" },
              }}>

                <Box sx={{
                  display: "flex",
                  alignItems: 'center',
                  gap: 2,
                  width: "100%",
                  flexDirection: { xs: "column", sm: "row" }, // stack vertically on small screens
                }}>
                  <Box sx={{}}>
                    <Box sx={{ marginTop: '-8px' }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DateTimePicker"]}>
                          <DateTimePicker
                            label='From Date Time'
                            disabled={appSearchInfo.days == 5 ? false : true}
                            value={appSearchInfo.fromDateTime}
                            onChange={(value) =>
                              setAppSearchInfo({
                                ...appSearchInfo,
                                fromDateTime: value,
                              })
                            }
                            sx={{
                              fontSize: "12px",
                              "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":
                              {
                                padding: "9px 13px 9px",
                              },
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Box>
                  </Box>

                  <Box >
                    <Box sx={{ display: "flex", gap: "10px", marginTop: '-8px' }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DateTimePicker"]}>
                          <DateTimePicker
                            label='To Date Time'
                            disabled={appSearchInfo.days === 5 ? false : true}
                            value={appSearchInfo.toDateTime}
                            onChange={(value) =>
                              setAppSearchInfo({
                                ...appSearchInfo,
                                toDateTime: value,
                              })
                            }
                            sx={{
                              fontSize: "12px",
                              "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":
                              {
                                padding: "9px 13px 9px",
                              },
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Box>
                  </Box>

                  <Button
                    type="submit"
                    variant="contained"

                    onClick={onSearch}

                    sx={{
                      fontWeight: 600,
                      // full width on small screens

                      textTransform: "capitalize",
                      pl: 4,
                      pr: 4,
                      py: 1,
                      // adjusted margin top for small screens
                      backgroundColor: "#6DD6C2",
                      borderColor: "#6DD6C2",
                      color: "#212223",
                      "&:hover": {
                        backgroundColor: "#6DD6C2",
                        borderColor: "#6DD6C2",
                      },
                    }}
                  >
                    Search
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>

          <AccordionDetails>
            <Box sx={{}}>
              <AppointmentsParentTable
                onDelete={deleteAppointment}
                onChangeAppointmentKey={onChangeAppointmentKey}
                onAppointmentStatus={onAppointmentStatus}
                onChangeLeadKey={onChangeLeadKey}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Loader loading={isAppointmentStatusUpdate || isloading} />
      </Box>
    </>
  );
};
