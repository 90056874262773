import * as React from "react";
import Box from "@mui/material/Box";
import { AdminTable } from "./AdminTable";

export const AdminParentTable = (props) => {
    const { adminData, onDeleteAdmin, handleStatusSubmit } = props;

    return (
        <Box>
            <AdminTable
                rows={adminData}
                onDeleteAdmin={onDeleteAdmin}
                handleStatusSubmit={handleStatusSubmit}
            />
        </Box>
    );
};
