import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Container,
  Link,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { ERROR_MESSAGE, validateEmail } from "../../helpers";
import { Loader, OtpInput } from "../Common";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router";
import { QRCodeGenerate } from "../Common/QRCode";
import { OtpInputQrCode } from "../Common/OtpInputQrCode";

export const LoginComponent = (props) => {
  const { isloading, isVerifyOYPloading, isforgetloading } = props;
  const [userEmail, setUserEmail] = useState("");
  const [userForgetEmail, setUserForgetEmail] = useState("");

  const [password, setPassword] = useState("");
  const [triedSubmit, setTriedSubmit] = useState(false);

  const [triedForgetSubmit, setTriedForgetSubmit] = useState(false);
  const [organization, setOrganization] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isResent, setIsResent] = useState(false);
  const [isQrCodeOpen, setIsQrCodeOpen] = useState(false);
  const [isQrCodeOtpOpen, setIsQrCodeOtpOpen] = useState(false);
  const [userData ,setUserData] = useState()
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  
  useEffect(() => {
    const navigateToLogin = () => {
      navigate("/login");
    };
    navigateToLogin();
  }, []);



  /** Form Validation */
  const userEmailError =
    triedSubmit && (userEmail.trim().length === 0 || !validateEmail(userEmail));
  const userEmailErrorMessage =
    triedSubmit && !props?.loading && ERROR_MESSAGE.EMAIL.IN_VALID;
  const passwordError = triedSubmit && password.trim().length === 0;
  const passwordErrorMessage =
    triedSubmit && !props?.loading && ERROR_MESSAGE.PASSWORD.IN_VALID;

  const organizationError = triedSubmit && organization.trim().length === 0;

  const organizationErrorMessage =
    triedSubmit && !props?.loading && ERROR_MESSAGE.ORGANISATION.IN_VALID;

  const userForgetEmailError =
    triedForgetSubmit &&
    (userForgetEmail.trim().length === 0 || !validateEmail(userForgetEmail));
  const userForgetEmailErrorMessage =
    triedForgetSubmit && ERROR_MESSAGE.EMAIL.IN_VALID;

  const isForgetValid =
    userForgetEmail?.trim()?.length > 0 && validateEmail(userForgetEmail);
  const isFormValid =
    userEmail?.trim()?.length > 0 &&
    validateEmail(userEmail) &&
    organization?.trim()?.length > 0 &&
    password?.length > 0
      ? true
      : false;
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleCloseQrCode = () => {
    setIsQrCodeOpen(false);
  };
  const handleCloseQrCodeOtp = () => {
    setIsQrCodeOtpOpen(false);
  };
  const handleCloseSnack = () => {
    setIsResent(false);
  };
  const handleSubmit = (otp) => {
    if (otp?.trim()?.length === 6)

      if (!(!userEmail || !otp)) {
        const payload = {
          email: userEmail,
          otp: otp,
          two_way_authentication:userData?.two_way_authentication,
          two_fa_authentication_enable:userData?.two_fa_authentication_enable,
        };
        props?.verifyOTP(payload, () => {
          // navigate("/HomeComponent");
          handleClose();
        });
      }
  };
  const handleSubmitQrOtp = (otp) => {
    if (otp?.trim()?.length === 6)
      if (!(!userEmail || !otp)) {
        const payload = {
          email: userEmail,
          token_otp: otp,
          two_way_authentication:userData?.two_way_authentication,
          two_fa_authentication_enable:userData?.two_fa_authentication_enable,
        };
        props?.verifyQrOTP(payload, () => {
          // navigate("/HomeComponent");
          handleCloseQrCodeOtp();
        });
      }
  };

  const onSubmitHandler = (type) => {
    const handleOpen = (response) => {
      if(response?.is_otp_required){
        setUserData(response)
        setIsOpen(true);
        type === "resend" && setIsResent(true);
      }
       else{
        setUserData(response.user)
        if(response?.is_device_registered_for_totp){
          setIsQrCodeOtpOpen(true)
        }else{
        props?.qrCodeGenerate(payload, () => {
          setIsQrCodeOpen(true)
        });
        }
      }
    };
    setTriedSubmit(true);
    if (!isFormValid) return;
    const payload = {
      userEmail: userEmail,
      slug: organization,
      password: password,
    };

    props?.onLoginAuth(payload, handleOpen);
  };

  const onForgetSubmitHandler = () => {
    setTriedForgetSubmit(true);
    if (!isForgetValid) return;
    const payload = {
      userEmail: userForgetEmail,
    };
    props?.onForgetPassword(payload, handleCloseModal2);
  };
  // Resetting the auth error while user modified the storeID or password
  useEffect(() => {
    if (props?.error && props?.errorMessage != "") {
      props?.onResetError();
    }
  }, [userEmail, organization, password]);

  const style = {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    border: "2px solid #fff",
    p: 4,
    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    background: "#fff",
    borderRadius: "5px",
    "&:focus": {
      outline: "none",
      boxShadow: "none",
    },
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleCloseModal2 = () => {
    setUserForgetEmail("");
    setTriedForgetSubmit(false);
    setOpen(false);
  };

  useEffect(() => {
    // Extract organization from subdomain
    const subdomain = window.location.hostname.split(".")[0];
    setOrganization("leadorchard");
  }, []);

  return (
    <>
      {loading ? (
        <div style={{backgroundColor:"red"}}><Loader loading={loading} ></Loader> </div> 
      ) :  <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        backgroundColor: "#F3F3F4",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginTop: 2,
        }}
      >
        <img
          loading="lazy"
          src={"/logo3.png"}
          style={{ height: "100%", width: "40%" }}
          alt="logo"
        />
      </Box>
      <Container maxWidth="xs" sx={{ marginTop: 6 }}>
        <Box
          sx={{
            marginTop: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
            background: "#fff",
            padding: "30px",
            maxWidth: 400,
            margin: "0 auto",
            borderRadius: "10px",
          }}
        >
          <Typography
            component="h3"
            variant="h5"
            sx={{
              fontWeight: 600,
              fontSize: 24,
              color: "#212223",
              "& span": {
                color: "#6DD6C2",
                fontWeight: 700,
              },
            }}
          >
            Welcome to{" "}
            <span style={{ textTransform: "uppercase" }}>Lead Orchard</span>
          </Typography>
          <Box noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              size="small"
              autoComplete="email"
              autoFocus
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              error={userEmailError}
              helperText={userEmailError && userEmailErrorMessage}
              InputLabelProps={{
                sx: {
                  "&.Mui-focused": {
                    color: "#6DD6C2",
                  },
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#F4F6FA",
                },
                "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#6DD6C2",
                  },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#6DD6C2",
                  },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              size="small"
              name="organization"
              label="Organization"
              id="organization"
              value={organization}
              onChange={(e) => setOrganization(e.target.value)}
              error={organizationError}
              helperText={organizationError && organizationErrorMessage}
              disabled
              InputLabelProps={{
                sx: {
                  "&.Mui-focused": {
                    color: "#6DD6C2",
                  },
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#F4F6FA",
                },
                "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#6DD6C2",
                  },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#6DD6C2",
                  },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              size="small"
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={passwordError}
              helperText={passwordError && passwordErrorMessage}
              InputLabelProps={{
                sx: {
                  "&.Mui-focused": {
                    color: "#6DD6C2",
                  },
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#F4F6FA",
                },
                "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#6DD6C2",
                  },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#6DD6C2",
                  },
              }}
            />
            {props?.error && props?.errorMessage !== "" && (
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "600",
                  color: "#ff0000",
                  fontSize: 15,
                }}
              >
                {props?.errorMessage}
              </Typography>
            )}
            <Button
              type="button"
              fullWidth
              variant="contained"
              sx={{
                fontWeight: 600,
                mt: 3,
                mb: 2,
                backgroundColor: "#6DD6C2",
                borderColor: "#6DD6C2",
                color: "#212223",
                "&:hover": {
                  // Customize the hover color
                  backgroundColor: "#6DD6C2",
                  borderColor: "#6DD6C2",
                },
              }}
              onClick={() => {
                onSubmitHandler("login");
              }}
            >
              Submit
            </Button>
            <Typography
              sx={{
                fontSize: "13px",
                textAlign: "center",
                display: "block",
                color: "#6B7489",
                fontWeight: 500,
                lineHeight: "17px",
              }}
            >
              Forgot your account password, please use link
              <br />
              <Link
                sx={{
                  color: "#6DD6C2",
                  fontWeight: 700,
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                onClick={handleOpen}
              >
                Forgot password?
              </Link>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Box
                    onClick={handleCloseModal2}
                    sx={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      cursor: "pointer",
                      color: "#7f7f7f",
                    }}
                  >
                    <CloseIcon />
                  </Box>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ fontSize: "18px", fontWeight: 600, mb: 2 }}
                  >
                    Enter Your Email
                  </Typography>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        margin="normal"
                        fullWidth
                        size="small"
                        name="email"
                        label="user@gmail.com"
                        id="email"
                        value={userForgetEmail}
                        onChange={(e) => setUserForgetEmail(e.target.value)}
                        error={userForgetEmailError}
                        helperText={
                          userForgetEmailError && userForgetEmailErrorMessage
                        }
                        InputLabelProps={{
                          sx: {
                            "&.Mui-focused": {
                              color: "#6DD6C2",
                            },
                          },
                        }}
                        sx={{
                          mb: 0,
                          mt: 0,
                          "& .MuiOutlinedInput-root": {
                            backgroundColor: "#f9f9f9",
                          },
                          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                        }}
                      />
                      <Button
                        type="submit"
                        variant="contained"
                        onClick={onForgetSubmitHandler}
                        sx={{
                          maxWidth: "max-content",
                          width: "100%",
                          backgroundColor: "#1ab394",
                          borderColor: "#1ab394",
                          color: "#FFFFFF",
                          height: "40px",
                          "&:hover": {
                            // Customize the hover color
                            backgroundColor: "#32c3a3",
                            borderColor: "#32c3a3",
                          },
                        }}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Modal>
            </Typography>
          </Box>
        </Box>
        <OtpInput
          isOpen={isOpen}
          userEmail={userEmail}
          onSubmit={handleSubmit}
          onClose={handleClose}
          onResent={onSubmitHandler}
        />
        <QRCodeGenerate 
         isQrCodeOpen ={isQrCodeOpen}
         onCloseQrCode={handleCloseQrCode}
         setIsQrCodeOpen={setIsQrCodeOpen}
         setIsQrCodeOtpOpen={setIsQrCodeOtpOpen}
         isQrCodeOtpOpen ={isQrCodeOtpOpen}
        />
         <OtpInputQrCode 
         isQrCodeOtpOpen ={isQrCodeOtpOpen}
         onCloseCodeOtp={handleCloseQrCodeOtp}
         onSubmit={handleSubmitQrOtp}
         userEmail={userEmail}
        />
        <Loader loading={isloading || isVerifyOYPloading || isforgetloading} />
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={isResent}
          autoHideDuration={5000}
          onClose={handleCloseSnack}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            OTP has been resent.
          </Alert>
        </Snackbar>
      </Container>
    </Box>
}
    </>
  );
};
