import React from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Autocomplete,
  FormHelperText,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState } from "react";
import {
  ERROR_MESSAGE,
  formatPhoneNumber,
  validateMobileNumber,
  validateName,
  validatePincode,
} from "../../helpers";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Loader } from "../Common";
import { useSelector } from "react-redux";
import "aos/dist/aos.css";
import {
  Autocomplete as GoogleAutocomplete,
} from "@react-google-maps/api";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";


export const NewLead = (props) => {
  const location = useLocation();

  const { getAllUser, onCreateLead, getAllCampaignNames } = props;
  
  const userDetails = useSelector((state) => state?.profile?.allClients);

  const { isEdit, prospectInfoData } = location.state || {};
  const [triedSubmit, setTriedSubmit] = useState(false);

  //access state data
  const isloading = useSelector((state) => state?.lead?.isLoading);

  const callResult = useSelector(
    (state) => state?.prospect?.callResult?.options
  );

  //initial_info

  const initialLeadInfo = {
    agentName: "",
    agentCallingFor: "",
    category: "",
  };

  //lead info
  const [leadInfo, setLeadInfo] = useState(initialLeadInfo);

  //insuranceInfo

  const infoInsuranceInfo = {
    locationEmployeeSizeActual: "",
    nextRenewalDate: "",
    liabilityRenewalDate: "",
    liabilityCarrier: "",
    workersCompRenewalDate: "",
    AutoRenewalDate: "",
    totalLocations: "",
    workersCompCarrier: "",
    autoCarrier: "",
    totalVehicles: "",
  };

  const campaignNames = useSelector(
    (state) => state?.campaign?.campaignNamesWithID?.results
  );

  //lead info
  const [insuranceInfo, setInsuranceInfo] = useState(infoInsuranceInfo);

  //prospects info states
  const initialProspectInfo = {
    phoneNumber: isEdit ? prospectInfoData?.phone_number : "",
    alternativePhoneNumber: isEdit
      ? prospectInfoData?.alternative_phone_number
      : "",
    executiveTitle: isEdit ? prospectInfoData?.title : "",
    executiveFirstName: isEdit ? prospectInfoData?.first_name : "",
    executiveLastName: isEdit ? prospectInfoData?.last_name : "",
    companyName: isEdit ? prospectInfoData?.company_name : "",
    companyWebsite: isEdit ? prospectInfoData?.website : "",
    primarySicCode: isEdit ? prospectInfoData?.sic_code : "",
    primarySicCodeDescription: isEdit
      ? prospectInfoData?.sic_code_description
      : "",
    primaryNaicsCode: isEdit ? prospectInfoData?.naics_code : "",
    primaryNaicsCodeDescription: isEdit
      ? prospectInfoData?.naics_code_description
      : "",
    comments: isEdit ? prospectInfoData?.comments : "",
  };

  const [prospectInfo, setProspectInfo] = useState(initialProspectInfo);

  //address info states
  const initialAddressInfo = {
    locationStreet: isEdit ? prospectInfoData?.address : "",
    locationCity: isEdit ? prospectInfoData?.city : "",
    locationState: isEdit ? prospectInfoData?.state : "",
    locationZipCode: isEdit ? prospectInfoData?.zip_code : "",
    countyDescription: isEdit ? prospectInfoData?.county : "",
  };

  const [addressInfo, setAddressInfo] = useState(initialAddressInfo);

  //additional states for storing data
  const initialAdditionalData = {
    renewalDate: isEdit ? prospectInfoData?.renewal_date : "",
    squareFootage: isEdit ? prospectInfoData?.square_footage : "",
    callResult: isEdit ? prospectInfoData?.call_result : 0,
    callResultDescription: isEdit
      ? prospectInfoData?.call_result_description
      : "",
    creditRating: isEdit ? prospectInfoData?.credit_rating : "",
    lastAppointmentWith: isEdit ? prospectInfoData?.last_appointment_with : "",
    carrierOfRecord: isEdit ? prospectInfoData?.carrier_of_record : "",
    locationEmployeeSizeActual: isEdit ? prospectInfoData?.employee_size : "",
  };

  const [additionalData, setAdditionalData] = useState(initialAdditionalData);

  //appointment information

  const initialAppointmentData = {
    appointmentDate: "",
    appointmentTime: dayjs("2022-04-17T15:30"),
    confirmAppointmentDate: "",
    confirmAppointmentTime: dayjs("2022-04-17T15:30"),
    appointmentType: "",
    status: "",
    comment: "",
  };

  const [appointmentData, setAppointmentData] = useState(
    initialAppointmentData
  );

  //handle errors

  const agentNameError =
    triedSubmit && leadInfo?.agentName?.toString().trim().length === 0;

  const agentNameErrorMessage =
    triedSubmit && leadInfo?.agentName?.toString().trim().length === 0
      ? "Please select agent name"
      : "";

  const agentForError =
    triedSubmit && leadInfo?.agentCallingFor?.toString().trim().length === 0;

  const agentForErrorMessage =
    triedSubmit && leadInfo?.agentCallingFor?.toString().trim().length === 0
      ? "This field is required."
      : "";

  const executiveFirstNameError =
    triedSubmit &&
    (prospectInfo?.executiveFirstName?.trim().length === 0 ||
      !validateName(prospectInfo?.executiveFirstName));
  const executiveFirstNameErrorMessage =
    triedSubmit && prospectInfo?.executiveFirstName?.trim().length === 0
      ? ERROR_MESSAGE?.NAME?.IN_VALID
      : "";

  const executiveLastNameError =
    triedSubmit &&
    (prospectInfo?.executiveLastName?.trim().length === 0 ||
      !validateName(prospectInfo?.executiveLastName));
  const executiveLastNameErrorMessage =
    triedSubmit && prospectInfo?.executiveLastName?.trim().length === 0
      ? ERROR_MESSAGE?.NAME?.IN_VALID
      : "";

  const companyNameError =
    triedSubmit &&
    (prospectInfo?.companyName?.trim().length === 0 ||
      !validateName(prospectInfo?.companyName));
  const companyNameErrorMessage =
    triedSubmit && prospectInfo?.companyName?.trim().length === 0
      ? ERROR_MESSAGE?.NAME?.IN_VALID
      : "";

  const phoneNumberError =
    triedSubmit &&
    (prospectInfo?.phoneNumber?.trim().length === 0 ||
      !validateMobileNumber(prospectInfo?.phoneNumber));

  const phoneNumberErrorMessage =
    triedSubmit && prospectInfo?.phoneNumber.trim().length === 0
      ? "Please enter valid phone number"
      : "";

  const addressError =
    triedSubmit && addressInfo?.locationStreet.trim().length === 0;
  const addressErrorMessage =
    triedSubmit && addressInfo?.locationState?.trim().length === 0
      ? "Please enter valid address"
      : "";

  const stateError =
    triedSubmit &&
    (addressInfo?.locationState.trim().length === 0 ||
      !validateName(addressInfo?.locationState));
  const stateErrorMessage =
    triedSubmit && addressInfo?.locationState?.trim().length === 0
      ? ERROR_MESSAGE.STATE.IN_VALID
      : "";
  const cityError =
    triedSubmit &&
    (addressInfo?.locationCity.trim().length === 0 ||
      !validateName(addressInfo?.locationCity));
  const cityErrorMessage =
    triedSubmit && addressInfo?.locationCity?.trim().length === 0
      ? ERROR_MESSAGE.CITY.IN_VALID
      : "";
  const zipCodeError =
    triedSubmit && !validatePincode(addressInfo?.locationZipCode);
  const zipCodeErrorMessage =
    triedSubmit &&
      (addressInfo?.locationZipCode?.trim().length < 5 ||
        !validatePincode(addressInfo?.locationZipCode))
      ? "Please enter valid zip code"
      : "";
  const countyDescriptionError =
    triedSubmit &&
    (addressInfo?.countyDescription.trim().length === 0 ||
      !validateName(addressInfo?.countyDescription));
  const countyDescriptionErrorMessage =
    triedSubmit && addressInfo?.countyDescription?.trim().length === 0
      ? "Please enter valid discription"
      : "";

  const appointmentDateError =
    triedSubmit &&
    appointmentData?.appointmentDate?.toString()?.trim().length === 0;

  const appointmentDateErrorMessage =
    triedSubmit && appointmentData?.appointmentDate?.trim().length === 0
      ? "Please enter valid date"
      : "";

  const appointmentTimeError =
    triedSubmit &&
    appointmentData?.appointmentTime?.toString()?.trim().length === 0;

  const appointmentTimeErrorMessage =
    triedSubmit &&
      appointmentData?.appointmentTime?.toString()?.trim().length === 0
      ? "Please enter valid appointment time "
      : "";
  const confirmAppointmentDateError =
    triedSubmit &&
    (appointmentData?.confirmAppointmentDate?.toString()?.trim().length === 0 ||
      appointmentData?.appointmentDate !==
      appointmentData?.confirmAppointmentDate);

  const confirmAppointmentDateErrorMessage =
    triedSubmit &&
      (appointmentData?.confirmAppointmentDate?.toString()?.trim().length === 0 ||
        appointmentData?.appointmentDate !==
        appointmentData?.confirmAppointmentDate)
      ? "Please enter valid confirm appointment date "
      : "";

  const confirmAppointmentTimeError =
    triedSubmit &&
    (appointmentData?.confirmAppointmentTime?.toString()?.trim().length === 0 ||
      appointmentData?.appointmentTime?.toString() !==
      appointmentData?.confirmAppointmentTime?.toString());

  const confirmAppointmentTimeErrorMessage =
    triedSubmit &&
      (appointmentData?.confirmAppointmentTime?.toString()?.trim().length === 0 ||
        appointmentData?.appointmentTime?.toString() !==
        appointmentData?.confirmAppointmentTime?.toString())
      ? "Please enter valid confirm appointment time "
      : "";

  const appointmentTypeError =
    triedSubmit &&
    appointmentData?.appointmentType?.toString()?.trim().length === 0;

  const appointmentTypeErrorMessage =
    triedSubmit &&
      appointmentData?.appointmentType?.toString()?.trim().length === 0
      ? "Please enter valid appointment type "
      : "";

  const handleChangeOptions = (event, type) => {
    switch (type) {
      case "selectCallingFor":
        setLeadInfo({
          ...leadInfo,
          agentCallingFor: event.target.value,
        });
        break;
      case "selectCategory":
        setLeadInfo({
          ...leadInfo,
          category: event.target.value,
        });
        break;
      case "appointmentType":
        setAppointmentData({
          ...appointmentData,
          appointmentType: event.target.value,
        });
        break;
      case "status":
        setAppointmentData({
          ...appointmentData,
          status: event.target.value,
        });
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    getAllUser();
    getAllCampaignNames();
  }, []);

  const autoCompleteRef = React.useRef(null);

  const handlePlaceChanged = () => {
    const place = autoCompleteRef.current.getPlace();
    const addressComponents = place?.address_components;
    //console.log("place==========>", place);

    let street = "";
    let city = "";
    let state = "";
    let zipCode = "";
    let county = "";

    addressComponents?.forEach((component) => {
      const componentType = component.types[0];

      if (componentType === "street_number" || componentType === "route") {
        street = `${street} ${component.long_name}`;
      } else if (componentType === "locality") {
        city = component.long_name;
      } else if (componentType === "administrative_area_level_1") {
        state = component.short_name;
      } else if (componentType === "postal_code") {
        zipCode = component.long_name;
      } else if (componentType === "administrative_area_level_2") {
        county = component.long_name;
      }
    });

    setAddressInfo({
      ...addressInfo,
      locationStreet: street,
      locationCity: city,
      locationState: state,
      locationZipCode: zipCode,
      countyDescription: county,
    });
  };

  const handleChange = (event, newValue, type) => {
    switch (type) {
      case "agentName":
        setLeadInfo({
          ...leadInfo,
          agentName: newValue.id,
        });
        break;
      case "callResult":
        setAdditionalData({
          ...additionalData,
          callResult: newValue.id,
        });
        break;
      case "lastAppoinment":
        setAdditionalData({
          ...additionalData,
          lastAppoinment: newValue.id,
        });
        break;
      case "selectCallingFor":
        setLeadInfo({
          ...leadInfo,
          agentCallingFor: newValue.id,
        });
        break;
      default:
        break;
    }
  };

  const isCheckFormValid = () => {
    const isExecutiveFirstNameValid =
      prospectInfo?.executiveFirstName?.trim().length > 0 &&
      validateName(prospectInfo?.executiveFirstName);

    const isExecutiveLastNameValid =
      prospectInfo?.executiveLastName?.trim().length > 0 &&
      validateName(prospectInfo?.executiveLastName);

    const isCompanyNameValid =
      prospectInfo?.companyName?.trim().length > 0 &&
      validateName(prospectInfo?.companyName);

    const isPhoneNumberValid =
      prospectInfo?.phoneNumber?.trim().length > 0 &&
      validateMobileNumber(prospectInfo?.phoneNumber);

    const isAddressValid = addressInfo?.locationStreet?.trim().length > 0;

    const isStateValid =
      addressInfo?.locationState?.trim().length > 0 &&
      validateName(addressInfo?.locationState);

    const isCityValid =
      addressInfo?.locationCity?.trim().length > 0 &&
      validateName(addressInfo?.locationCity);

    const isZipCodeValid =
      addressInfo?.locationZipCode?.trim().length >= 5 &&
      validatePincode(addressInfo?.locationZipCode);

    const isCountyDescriptionValid =
      addressInfo?.countyDescription?.trim().length > 0 &&
      validateName(addressInfo?.countyDescription);

    const isAgentNameValid = leadInfo?.agentName?.toString()?.trim().length > 0;

    const isAgentCallingForValid =
      leadInfo?.agentCallingFor?.toString().trim().length > 0;

    const isApppointmentDateValid =
      appointmentData?.appointmentDate?.toString()?.trim().length > 0;

    const isAppointmentTimeValid =
      appointmentData?.appointmentTime?.toString()?.trim().length > 0;

    const isCNFApppointmentDateValid =
      appointmentData?.confirmAppointmentDate?.toString()?.trim().length > 0;

    const isCNFAppointmentTimeValid =
      appointmentData?.confirmAppointmentTime?.toString()?.trim().length > 0;

    const isAppointmentTypeValid =
      appointmentData?.appointmentType?.toString()?.trim().length > 0;

    const isconfirmTimeValid =
      appointmentData?.appointmentTime?.toString()?.trim() ===
      appointmentData?.confirmAppointmentTime?.toString()?.trim();

    const isconfirmDateValid =
      appointmentData?.appointmentDate?.toString()?.trim() ===
      appointmentData?.confirmAppointmentDate?.toString()?.trim();

    //console.log("isExecutiveFirstNameValid====>", isExecutiveFirstNameValid);
    //console.log("isExecutiveLastNameValid====>", isExecutiveLastNameValid);
    //console.log("isCompanyNameValid====>", isCompanyNameValid);
    //console.log("isPhoneNumberValid====>", isPhoneNumberValid);
    //console.log("isAddressValid====>", isAddressValid);
    //console.log("isStateValid====>", isStateValid);
    //console.log("isCityValid====>", isCityValid);
    //console.log("isZipCodeValid====>", isZipCodeValid);
    //console.log("isCountyDescriptionValid====>", isCountyDescriptionValid);
    //console.log("isAgentNameValid====>", isAgentNameValid);
    //console.log("isAgentCallingForValid====>", isAgentCallingForValid);
    //console.log("isApppointmentDateValid====>", isApppointmentDateValid);
    //console.log("isAppointmentTimeValid====>", isAppointmentTimeValid);
    //console.log("isCNFApppointmentDateValid====>", isCNFApppointmentDateValid);
    //console.log("isCNFAppointmentTimeValid====>", isCNFAppointmentTimeValid);
    //console.log("isAppointmentTypeValid====>", isAppointmentTypeValid);
    //console.log("isconfirmTimeValid====>", isconfirmTimeValid);
    //console.log("isconfirmDateValid====>", isconfirmDateValid);
    return (
      isExecutiveFirstNameValid &&
      isExecutiveLastNameValid &&
      isCompanyNameValid &&
      isPhoneNumberValid &&
      isAddressValid &&
      isStateValid &&
      isCityValid &&
      isZipCodeValid &&
      isCountyDescriptionValid &&
      isAgentNameValid &&
      isAgentCallingForValid &&
      isApppointmentDateValid &&
      isAppointmentTimeValid &&
      isCNFApppointmentDateValid &&
      isCNFAppointmentTimeValid &&
      isAppointmentTypeValid &&
      isconfirmTimeValid &&
      isconfirmDateValid
    );
  };

  const isFormValid = isCheckFormValid();

  const onReset = () => {
    setTriedSubmit(false);
    setAddressInfo(initialAddressInfo);
    setProspectInfo(initialProspectInfo);
    setAppointmentData(initialAppointmentData);
    setAdditionalData(initialAdditionalData);
    setLeadInfo(initialLeadInfo);
    setInsuranceInfo(infoInsuranceInfo);
  };

  const onSubmitHandler = () => {
    setTriedSubmit(true);
    //console.log("isFormValid======>", isFormValid);
    if (!isFormValid) return;

    if (false) {
    } else {
      let payload = {
        ...leadInfo,
        ...insuranceInfo,
        ...prospectInfo,
        ...addressInfo,
        ...additionalData,
        ...appointmentData,
      };
      onCreateLead(payload, onReset);
    }
  };

  return (
    <>
      <Box sx={{
        margin: { xs: "10px", md: '30px' },
        marginTop: { xs: "10px", md: '30px' },

        borderRadius: "15px!Important",
      }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Accordion
              defaultExpanded
              sx={{ boxShadow: 3, borderRadius: "10px!Important" }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "#212223", pointerEvents: "auto" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: { xs: "0px", md: "15px" },
                  paddingBottom: { xs: "10px", sm: "0px", md: "0px" },
                  backgroundColor: " #d2f5ec",
                  borderTopLeftRadius: "15px!Important",
                  borderTopRightRadius: "15px!Important",
                  borderBottom: 1,
                  borderColor: "#676a6c4a",
                }}
              >
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 18,
                    color: "#212223",
                    fontWeight: 600,
                  }}
                >
                  Lead Information
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    padding: "20px 10px",
                  }}
                >
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl
                          size="small"
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgba(0, 0, 0, 0.23)",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6DD6C2!important",
                            },
                          }}
                        >
                          <Autocomplete
                            id="combo-box-demo"
                            options={userDetails}
                            getOptionLabel={(option) => option.full_name}
                            onChange={(event, newValue) => {
                              handleChange(event, newValue, "agentName");
                            }}
                            size="small"
                            disableClearable={true}
                            error={agentNameError}
                            helperText={agentNameError && agentNameErrorMessage}
                            sx={{
                              "& .MuiInputLabel-root": {
                                "&.Mui-focused": {
                                  color: "#18a689!important",
                                },
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: agentNameError
                                  ? "#d32f2f"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#18a689",
                              },
                              "& .Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#18a689",
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                            renderInput={(params) => (
                              <>
                                <TextField {...params} label="Select Agent" />
                                {agentNameError && (
                                  <FormHelperText
                                    sx={{
                                      color: "#d32f2f",
                                    }}
                                  >
                                    {agentNameErrorMessage}
                                  </FormHelperText>
                                )}
                              </>
                            )}
                            renderOption={(props, option, { inputValue }) => {
                              return (
                                <li
                                  {...props}
                                  key={`${option.id}-${option.full_name}`}
                                >
                                  {option?.full_name}
                                </li>
                              );
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl
                          size="small"
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgba(0, 0, 0, 0.23)",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6DD6C2!important",
                            },
                          }}
                        >
                          <Autocomplete
                            id="combo-box-demo"
                            options={campaignNames}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => {
                              handleChange(event, newValue, "selectCallingFor");
                            }}
                            size="small"
                            disableClearable={true}
                            error={agentForError}
                            helperText={agentForError && agentForErrorMessage}
                            sx={{
                              "& .MuiInputLabel-root": {
                                "&.Mui-focused": {
                                  color: "#18a689!important",
                                },
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: agentForError
                                  ? "#d32f2f"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#18a689",
                              },
                              "& .Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#18a689",
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                            renderInput={(params) => (
                              <>
                                <TextField
                                  {...params}
                                  label="Select Calling for"
                                />
                                {agentForError && (
                                  <FormHelperText
                                    sx={{
                                      color: "#d32f2f",
                                    }}
                                  >
                                    {agentForErrorMessage}
                                  </FormHelperText>
                                )}
                              </>
                            )}
                            renderOption={(props, option, { inputValue }) => {
                              return (
                                <li
                                  {...props}
                                  key={`${option.id}-${option.name}`}
                                >
                                  {option?.name}
                                </li>
                              );
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl
                          size="small"
                          sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgba(0, 0, 0, 0.23)",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6DD6C2!important",
                            },
                          }}
                        >
                          <InputLabel
                            id="demo-simple-select-label"
                            sx={{
                              fontSize: 15,
                              "&.Mui-focused": {
                                color: "#6DD6C2!important",
                                fontWeight: "600",
                                margin: "0",
                              },
                            }}
                          >
                            Select Category
                          </InputLabel>
                          <Select
                            sx={{ backgroundColor: "#fff!important" }}
                            id="selectinfo"
                            label="Select Category"
                            onChange={(event) => {
                              handleChangeOptions(event, "selectCategory");
                            }}
                            size="small"
                          >
                            <MenuItem value={"1"} sx={{ fontSize: 15 }}>
                              None
                            </MenuItem>
                            <MenuItem value={"2"} sx={{ fontSize: 15 }}>
                              Accepted
                            </MenuItem>
                            <MenuItem value={"3"} sx={{ fontSize: 15 }}>
                              Cancelled
                            </MenuItem>
                            <MenuItem value={"4"} sx={{ fontSize: 15 }}>
                              Cancelled - Waiting To Reschedule
                            </MenuItem>
                            <MenuItem value={"5"} sx={{ fontSize: 15 }}>
                              Not An Appointment
                            </MenuItem>
                            <MenuItem value={"6"} sx={{ fontSize: 15 }}>
                              Not Interested (CWTR)
                            </MenuItem>
                            <MenuItem
                              value={"Rescheduled"}
                              sx={{ fontSize: 15 }}
                            >
                              Rescheduled
                            </MenuItem>
                            <MenuItem
                              value={"Rescheduled (CWTR)"}
                              sx={{ fontSize: 15 }}
                            >
                              Rescheduled (CWTR)
                            </MenuItem>
                            <MenuItem value={"Returned"} sx={{ fontSize: 15 }}>
                              Returned
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} md={12}>
            <Accordion
              defaultExpanded
              sx={{ boxShadow: 3, borderRadius: "10px!Important" }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "#212223", pointerEvents: "auto" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  borderBottom: 1,
                  borderColor: "#676a6c4a",
                  borderRadius: "10px 10x 0 0!Important",

                  backgroundColor: " #d2f5ec",
                  borderTopLeftRadius: "15px!Important",
                  borderTopRightRadius: "15px!Important",

                }}
              >
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 18,
                    color: "#212223",
                    fontWeight: 600,
                  }}
                >
                  Insurance Information
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    padding: "20px 10px",
                  }}
                >
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          value={insuranceInfo.locationEmployeeSizeActual}
                          onChange={(e) =>
                            setInsuranceInfo({
                              ...insuranceInfo,
                              locationEmployeeSizeActual: e.target.value,
                            })
                          }
                          label="Location Employee Size Actual"
                          type="text"
                          fontSize="15"
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#6DD6C2",
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                          }}
                        >
                          <Typography
                            component="h2"
                            variant="h2"
                            fullWidth
                            sx={{
                              fontSize: 15,
                              color: "#7b7b7b",
                              fontWeight: 600,
                              mb: 1.5,
                            }}
                          >
                            Next Renewal Date :
                          </Typography>
                          <TextField
                            margin="0"
                            size="small"
                            type="date"
                            fontSize="15"
                            value={insuranceInfo.nextRenewalDate}
                            onChange={(e) =>
                              setInsuranceInfo({
                                ...insuranceInfo,
                                nextRenewalDate: e.target.value,
                              })
                            }
                            InputLabelProps={{
                              sx: {
                                fontSize: "15px",
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                            sx={{
                              marginBottom: "10px",
                              width: {
                                xs: "100%",
                                sm: "100%",
                                md: "200px",
                                lg: "200px",
                              },
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: "#fff",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                          }}
                        >
                          <Typography
                            component="h2"
                            variant="h2"
                            fullWidth
                            sx={{
                              fontSize: 15,
                              color: "#7b7b7b",
                              fontWeight: 600,
                              mb: 1.5,
                            }}
                          >
                            Liability Renewal Date :
                          </Typography>
                          <TextField
                            margin="0"
                            size="small"
                            type="date"
                            fontSize="15"
                            value={insuranceInfo.liabilityRenewalDate}
                            onChange={(e) =>
                              setInsuranceInfo({
                                ...insuranceInfo,
                                liabilityRenewalDate: e.target.value,
                              })
                            }
                            InputLabelProps={{
                              sx: {
                                fontSize: "15px",
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                            sx={{
                              marginBottom: "10px",
                              width: {
                                xs: "100%",
                                sm: "100%",
                                md: "200px",
                                lg: "200px",
                              },
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: "#fff",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="liabilitycarrier"
                          label="Liability Carrier"
                          type="text"
                          id="liabilitycarrier"
                          autoComplete="current-password"
                          fontSize="15"
                          value={insuranceInfo.liabilityCarrier}
                          onChange={(e) =>
                            setInsuranceInfo({
                              ...insuranceInfo,
                              liabilityCarrier: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#6DD6C2",
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                          }}
                        >
                          <Typography
                            component="h2"
                            variant="h2"
                            fullWidth
                            sx={{
                              fontSize: 15,
                              color: "#7b7b7b",
                              fontWeight: 600,
                              mb: 1.5,
                            }}
                          >
                            Workers Comp Renewal Date :
                          </Typography>
                          <TextField
                            margin="0"
                            size="small"
                            type="date"
                            fontSize="15"
                            value={insuranceInfo.workersCompRenewalDate}
                            onChange={(e) =>
                              setInsuranceInfo({
                                ...insuranceInfo,
                                workersCompRenewalDate: e.target.value,
                              })
                            }
                            InputLabelProps={{
                              sx: {
                                fontSize: "15px",
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                            sx={{
                              marginBottom: "10px",
                              width: {
                                xs: "100%",
                                sm: "100%",
                                md: "200px",
                                lg: "200px",
                              },
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: "#fff",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                          }}
                        >
                          <Typography
                            component="h2"
                            variant="h2"
                            fullWidth
                            sx={{
                              fontSize: 15,
                              color: "#7b7b7b",
                              fontWeight: 600,
                              mb: 1.5,
                            }}
                          >
                            Auto Renewal Date :
                          </Typography>
                          <TextField
                            margin="0"
                            size="small"
                            type="date"
                            fontSize="15"
                            value={insuranceInfo.AutoRenewalDate}
                            onChange={(e) =>
                              setInsuranceInfo({
                                ...insuranceInfo,
                                AutoRenewalDate: e.target.value,
                              })
                            }
                            InputLabelProps={{
                              sx: {
                                fontSize: "15px",
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                            sx={{
                              marginBottom: "10px",
                              width: {
                                xs: "100%",
                                sm: "100%",
                                md: "200px",
                                lg: "200px",
                              },
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: "#fff",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="totallocations"
                          label="Total Locations"
                          type="text"
                          id="totallocations"
                          value={insuranceInfo.totalLocations}
                          onChange={(e) =>
                            setInsuranceInfo({
                              ...insuranceInfo,
                              totalLocations: e.target.value,
                            })
                          }
                          autoComplete="current-password"
                          fontSize="15"
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#6DD6C2",
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="carrier"
                          label="Workers Comp Carrier"
                          type="text"
                          id="carrier"
                          fontSize="15"
                          value={insuranceInfo.workersCompCarrier}
                          onChange={(e) =>
                            setInsuranceInfo({
                              ...insuranceInfo,
                              workersCompCarrier: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#6DD6C2",
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="autocarrier"
                          label="Auto Carrier"
                          type="text"
                          id="autocarrier"
                          fontSize="15"
                          value={insuranceInfo.autoCarrier}
                          onChange={(e) =>
                            setInsuranceInfo({
                              ...insuranceInfo,
                              autoCarrier: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#6DD6C2",
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="totalvehicles"
                          label="Total Vehicles"
                          type="number"
                          id="totalvehicles"
                          value={insuranceInfo.totalVehicles}
                          onChange={(e) =>
                            setInsuranceInfo({
                              ...insuranceInfo,
                              totalVehicles: e.target.value,
                            })
                          }
                          fontSize="15"
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#6DD6C2",
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} md={12}>
            <Accordion
              defaultExpanded
              sx={{ borderRadius: "10px!Important", boxShadow: 3 }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "#212223", pointerEvents: "auto" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  borderBottom: 1,
                  borderColor: "#676a6c4a",
                  backgroundColor: "#90949f21",
                  borderRadius: "10px 10x 0 0!Important",
                  borderTopLeftRadius: "15px!Important",
                  borderTopRightRadius: "15px!Important",
                }}
              >
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 18,
                    color: "#212223",
                    fontWeight: 600,
                  }}
                >
                  Prospect Information
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    padding: "20px 10px",
                  }}
                >
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="num"
                          label="Phone Number"
                          id="num"
                          fontSize="15"
                          value={prospectInfo?.phoneNumber}
                          error={phoneNumberError}
                          helperText={
                            phoneNumberError && phoneNumberErrorMessage
                          }
                          inputProps={{ maxLength: 12 }}
                          onChange={(e) =>
                            setProspectInfo({
                              ...prospectInfo,
                              phoneNumber: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="num"
                          label="Alternative Phone Number"
                          id="num"
                          fontSize="15"
                          value={
                            formatPhoneNumber(
                              prospectInfo?.alternativePhoneNumber
                            ) ?? prospectInfo?.alternativePhoneNumber
                          }
                          onChange={(e) =>
                            setProspectInfo({
                              ...prospectInfo,
                              alternativePhoneNumber: e.target.value,
                            })
                          }
                          inputProps={{ maxLength: 12 }}
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="executivetitle"
                          label="Executive Title"
                          type="text"
                          id="executivetitle"
                          autoComplete="current-password"
                          fontSize="15"
                          value={prospectInfo?.executiveTitle}
                          onChange={(e) =>
                            setProspectInfo({
                              ...prospectInfo,
                              executiveTitle: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="firstname"
                          label="Executive First Name"
                          type="text"
                          id="firstname"
                          autoComplete="current-password"
                          fontSize="15"
                          value={prospectInfo?.executiveFirstName}
                          error={executiveFirstNameError}
                          helperText={
                            executiveFirstNameError &&
                            executiveFirstNameErrorMessage
                          }
                          onChange={(e) =>
                            setProspectInfo({
                              ...prospectInfo,
                              executiveFirstName: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="Lastname"
                          label="Executive Last Name"
                          type="text"
                          id="lasttname"
                          autoComplete="current-password"
                          fontSize="15"
                          value={prospectInfo?.executiveLastName}
                          error={executiveLastNameError}
                          helperText={
                            executiveLastNameError &&
                            executiveLastNameErrorMessage
                          }
                          onChange={(e) =>
                            setProspectInfo({
                              ...prospectInfo,
                              executiveLastName: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="companyname"
                          label="Company Name"
                          type="text"
                          id="companyname"
                          autoComplete="current-password"
                          fontSize="15"
                          value={prospectInfo?.companyName}
                          error={companyNameError}
                          helperText={
                            companyNameError && companyNameErrorMessage
                          }
                          onChange={(e) =>
                            setProspectInfo({
                              ...prospectInfo,
                              companyName: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="companyweb"
                          label="Company Website"
                          type="text"
                          id="companyweb"
                          fontSize="15"
                          value={prospectInfo?.companyWebsite}
                          onChange={(e) =>
                            setProspectInfo({
                              ...prospectInfo,
                              companyWebsite: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="num"
                          label="Primary Sic Code"
                          id="num"
                          fontSize="15"
                          value={prospectInfo?.primarySicCode}
                          onChange={(e) =>
                            setProspectInfo({
                              ...prospectInfo,
                              primarySicCode: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="num"
                          label="Primary Sic Code Description"
                          id="num"
                          fontSize="15"
                          value={prospectInfo?.primarySicCodeDescription}
                          onChange={(e) =>
                            setProspectInfo({
                              ...prospectInfo,
                              primarySicCodeDescription: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="num"
                          label="Primary Naics Code"
                          id="num"
                          fontSize="15"
                          value={prospectInfo?.primaryNaicsCode}
                          onChange={(e) =>
                            setProspectInfo({
                              ...prospectInfo,
                              primaryNaicsCode: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="num"
                          label="Primary Naics Code Description"
                          id="num"
                          fontSize="15"
                          value={prospectInfo?.primaryNaicsCodeDescription}
                          onChange={(e) =>
                            setProspectInfo({
                              ...prospectInfo,
                              primaryNaicsCodeDescription: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="comments"
                          label="Comments"
                          type="text"
                          id="comments"
                          value={prospectInfo?.comments}
                          onChange={(e) =>
                            setProspectInfo({
                              ...prospectInfo,
                              comments: e.target.value,
                            })
                          }
                          autoComplete="current-password"
                          fontSize="15"
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} md={12}>
            <Accordion
              defaultExpanded
              sx={{ borderRadius: "10px!Important", boxShadow: 3 }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "#212223", pointerEvents: "auto" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  borderBottom: 1,
                  borderColor: "#676a6c4a",
                  backgroundColor: "#90949f21",
                  borderRadius: "10px 10x 0 0!Important",
                  borderTopLeftRadius: "15px!Important",
                  borderTopRightRadius: "15px!Important",
                }}
              >
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 18,
                    color: "#212223",
                    fontWeight: 600,
                  }}
                >
                  Address Information
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    padding: "20px 10px",
                  }}
                >
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6}>
                        <GoogleAutocomplete
                          onLoad={(autoComplete) =>
                            (autoCompleteRef.current = autoComplete)
                          }
                          onPlaceChanged={handlePlaceChanged}
                        >
                          <TextField
                            margin="0"
                            fullWidth
                            size="small"
                            name="Address"
                            label="Location Address"
                            type="text"
                            id="Address"
                            fontSize="15"
                            value={addressInfo?.locationStreet}
                            onChange={(e) =>
                              setAddressInfo({
                                ...addressInfo,
                                locationStreet: e.target.value,
                              })
                            }
                            error={addressError}
                            helperText={addressError && addressErrorMessage}
                            InputLabelProps={{
                              sx: {
                                fontSize: "15px",
                                "&.Mui-focused": {
                                  color: "#5fdbc4",
                                  fontWeight: 600,
                                },
                              },
                            }}
                            sx={{
                              marginBottom: "10px",
                              width: {
                                xs: "100%",
                                sm: "100%",
                                md: "95%",
                                lg: "95%",
                              },
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: "#fff",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            }}
                          />
                        </GoogleAutocomplete>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="city"
                          label="Location City"
                          type="text"
                          id="city"
                          autoComplete="current-password"
                          fontSize="15"
                          value={addressInfo?.locationCity}
                          onChange={(e) =>
                            setAddressInfo({
                              ...addressInfo,
                              locationCity: e.target.value,
                            })
                          }
                          error={cityError}
                          helperText={cityError && cityErrorMessage}
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="state"
                          label="Location State"
                          type="text"
                          id="state"
                          fontSize="15"
                          value={addressInfo?.locationState}
                          onChange={(e) =>
                            setAddressInfo({
                              ...addressInfo,
                              locationState: e.target.value,
                            })
                          }
                          error={stateError}
                          helperText={stateError && stateErrorMessage}
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="zip code"
                          label="Location Zip Code"
                          id="zipcode"
                          fontSize="15"
                          inputProps={{ maxLength: 5 }}
                          value={addressInfo?.locationZipCode}
                          onChange={(e) =>
                            setAddressInfo({
                              ...addressInfo,
                              locationZipCode: e.target.value,
                            })
                          }
                          error={zipCodeError}
                          helperText={zipCodeError && zipCodeErrorMessage}
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          id="description"
                          label="County Description"
                          name="description"
                          fontSize="15"
                          value={addressInfo?.countyDescription}
                          onChange={(e) =>
                            setAddressInfo({
                              ...addressInfo,
                              countyDescription: e.target.value,
                            })
                          }
                          error={countyDescriptionError}
                          helperText={
                            countyDescriptionError &&
                            countyDescriptionErrorMessage
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "97.5%",
                              lg: "97.5%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item xs={12}>
            <Accordion
              defaultExpanded
              sx={{ borderRadius: "10px!Important", boxShadow: 3 }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "#212223", pointerEvents: "auto" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{

                  borderBottom: 1,
                  borderColor: "#676a6c4a",
                  backgroundColor: "#90949f21",
                  borderRadius: "10px 10x 0 0!Important",
                  borderTopLeftRadius: "15px!Important",
                  borderTopRightRadius: "15px!Important",
                }}
              >
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 18,
                    color: "#212223",
                    fontWeight: 600,
                  }}
                >
                  Additional Information
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    padding: "20px 10px",
                  }}
                >
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                          }}
                        >
                          <Typography
                            component="h2"
                            variant="h2"
                            fullWidth
                            sx={{
                              fontSize: 15,
                              color: "#7b7b7b",
                              fontWeight: 600,
                              mb: 1.5,
                            }}
                          >
                            Renewal Date :
                          </Typography>
                          <TextField
                            margin="0"
                            fullWidth
                            size="small"
                            name="renewaldate"
                            type="date"
                            id="renewaldate"
                            fontSize="15"
                            placeholder="Renewal Date"
                            value={null}
                            onChange={(e) =>
                              setAdditionalData({
                                ...additionalData,
                                renewalDate: e.target.value,
                              })
                            }
                            InputLabelProps={{
                              sx: {
                                fontSize: "15px",
                                "&.Mui-focused": {
                                  color: "#5fdbc4",
                                  fontWeight: 600,
                                },
                              },
                            }}
                            sx={{
                              marginBottom: "10px",
                              width: {
                                xs: "100%",
                                sm: "100%",
                                md: "550px",
                                lg: "550px",
                              },
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: "#fff",
                                borderColor: "#18a689",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="footage"
                          label="Square Footage"
                          type="email"
                          id="footage"
                          autoComplete="current-password"
                          fontSize="15"
                          value={additionalData?.squareFootage}
                          onChange={(e) =>
                            setAdditionalData({
                              ...additionalData,
                              squareFootage: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <FormControl
                          size="small"
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgba(0, 0, 0, 0.23)",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6DD6C2!important",
                            },
                          }}
                        >
                          <Autocomplete
                            id="combo-box-demo"
                            options={callResult}
                            getOptionLabel={(option) => option.description}
                            onChange={(event, newValue) => {
                              handleChange(event, newValue, "callResult");
                            }}
                            size="small"
                            disableClearable={true}
                            sx={{
                              "& .MuiInputLabel-root": {
                                "&.Mui-focused": {
                                  color: "#18a689!important",
                                },
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(0, 0, 0, 0.23)",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#18a689",
                              },
                              "& .Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#18a689",
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                            renderInput={(params) => (
                              <>
                                <TextField {...params} label="Call Result" />
                              </>
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="callresult"
                          label="Call Result Description"
                          type="text"
                          id="callresult"
                          fontSize="15"
                          value={additionalData?.callResultDescription}
                          onChange={(e) =>
                            setAdditionalData({
                              ...additionalData,
                              callResultDescription: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="creditrating"
                          label="Credit Rating"
                          type="text"
                          id="creditrating"
                          fontSize="15"
                          value={additionalData?.creditRating}
                          onChange={(e) =>
                            setAdditionalData({
                              ...additionalData,
                              creditRating: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <FormControl
                          size="small"
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgba(0, 0, 0, 0.23)",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6DD6C2!important",
                            },
                          }}
                        >
                          <Autocomplete
                            id="combo-box-demo"
                            options={userDetails}
                            getOptionLabel={(option) => option.full_name}
                            onChange={(event, newValue) => {
                              handleChange(event, newValue, "lastAppoinment");
                            }}
                            size="small"
                            disableClearable={true}
                            // error={agentNameError}
                            // helperText={
                            //   agentNameError &&
                            //   agentNameErrorMessage
                            // }
                            sx={{
                              "& .MuiInputLabel-root": {
                                "&.Mui-focused": {
                                  color: "#18a689!important",
                                },
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(0, 0, 0, 0.23)",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#18a689",
                              },
                              "& .Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#18a689",
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                            renderInput={(params) => (
                              <>
                                <TextField
                                  {...params}
                                  label="Last Appointment With"
                                />
                                {/* {agentNameError && (
                                  <FormHelperText
                                    sx={{
                                      color: "#d32f2f",
                                    }}
                                  >
                                    {agentNameErrorMessage}
                                  </FormHelperText>
                                )} */}
                              </>
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="carrierrecord"
                          label="Carrier Of Record"
                          type="text"
                          id="carrierrecord"
                          fontSize="15"
                          value={additionalData?.carrierOfRecord}
                          onChange={(e) =>
                            setAdditionalData({
                              ...additionalData,
                              carrierOfRecord: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="sizeactual"
                          label="Location Employee Size Actual"
                          type="text"
                          id="sizeactual"
                          fontSize="15"
                          value={additionalData?.locationEmployeeSizeActual}
                          onChange={(e) =>
                            setAdditionalData({
                              ...additionalData,
                              locationEmployeeSizeActual: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Accordion
              defaultExpanded
              sx={{ boxShadow: 3, borderRadius: "10px!Important" }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "#212223", pointerEvents: "auto" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  borderBottom: 1,
                  borderColor: "#676a6c4a",
                  backgroundColor: "#90949f21",
                  borderRadius: "10px 10x 0 0!Important",
                  borderTopLeftRadius: "15px!Important",
                  borderTopRightRadius: "15px!Important",
                }}
              >
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 18,
                    color: "#212223",
                    fontWeight: 600,
                  }}
                >
                  Appointment Information
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    padding: "20px 10px",
                  }}
                >
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                          }}
                        >
                          <Typography
                            component="h2"
                            variant="h2"
                            fullWidth
                            sx={{
                              fontSize: 15,
                              color: "#7b7b7b",
                              fontWeight: 600,
                              mb: 1.5,
                            }}
                          >
                            Appointment Date :
                          </Typography>
                          <TextField
                            margin="0"
                            size="small"
                            type="date"
                            fontSize="15"
                            error={appointmentDateError}
                            helperText={
                              appointmentDateError &&
                              appointmentDateErrorMessage
                            }
                            value={appointmentData.appointmentDate}
                            onChange={(e) => {
                              setAppointmentData({
                                ...appointmentData,
                                appointmentDate: e.target.value,
                              });
                            }}
                            InputLabelProps={{
                              sx: {
                                fontSize: "15px",
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                            sx={{
                              marginBottom: "10px",
                              width: {
                                xs: "100%",
                                sm: "100%",
                                md: "200px",
                                lg: "200px",
                              },
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: "#fff",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                          }}
                        >
                          <Typography
                            component="h2"
                            variant="h2"
                            fullWidth
                            sx={{
                              fontSize: 15,
                              color: "#7b7b7b",
                              fontWeight: 600,
                              mb: 1.5,
                            }}
                          >
                            Appointment Time :
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              components={[
                                "TimePicker",
                                "MobileTimePicker",
                                "DesktopTimePicker",
                                "StaticTimePicker",
                              ]}
                              sx={{
                                padding: 0,
                              }}
                            >
                              <MobileTimePicker
                                value={appointmentData?.appointmentTime}
                                onChange={(value) => {
                                  setAppointmentData({
                                    ...appointmentData,
                                    appointmentTime: value,
                                  });
                                }}
                                error={appointmentTimeError}
                                helperText={
                                  appointmentTimeError &&
                                  appointmentTimeErrorMessage
                                }
                                slotProps={{
                                  textField: {
                                    helperText: appointmentTimeErrorMessage,
                                  },
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                          }}
                        >
                          <Typography
                            component="h2"
                            variant="h2"
                            fullWidth
                            sx={{
                              fontSize: 15,
                              color: "#7b7b7b",
                              fontWeight: 600,
                              mb: 1.5,
                            }}
                          >
                            Confirm Appointment Date :
                          </Typography>
                          <TextField
                            margin="0"
                            size="small"
                            type="date"
                            fontSize="15"
                            error={confirmAppointmentDateError}
                            helperText={
                              confirmAppointmentDateError &&
                              confirmAppointmentDateErrorMessage
                            }
                            value={appointmentData.confirmAppointmentDate}
                            onChange={(e) => {
                              setAppointmentData({
                                ...appointmentData,
                                confirmAppointmentDate: e.target.value,
                              });
                            }}
                            InputLabelProps={{
                              sx: {
                                fontSize: "15px",
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                            sx={{
                              marginBottom: "10px",
                              width: {
                                xs: "100%",
                                sm: "100%",
                                md: "200px",
                                lg: "200px",
                              },
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: "#fff",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                          }}
                        >
                          <Typography
                            component="h2"
                            variant="h2"
                            fullWidth
                            sx={{
                              fontSize: 15,
                              color: "#7b7b7b",
                              fontWeight: 600,
                              mb: 1.5,
                            }}
                          >
                            Confirm Appointment Time :
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              components={[
                                "TimePicker",
                                "MobileTimePicker",
                                "DesktopTimePicker",
                                "StaticTimePicker",
                              ]}
                              sx={{
                                padding: 0,
                              }}
                            >
                              <MobileTimePicker
                                value={appointmentData.confirmAppointmentTime}
                                onChange={(value) => {
                                  setAppointmentData({
                                    ...appointmentData,
                                    confirmAppointmentTime: value,
                                  });
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                          <>
                            {confirmAppointmentTimeError && (
                              <Typography
                                sx={{
                                  color: "#d32f2f",
                                  fontSize: 12,
                                }}
                              >
                                {confirmAppointmentTimeErrorMessage}
                              </Typography>
                            )}
                          </>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl
                          size="small"
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgba(0, 0, 0, 0.23)",
                            },

                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },

                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6DD6C2!important",
                            },
                          }}
                        >
                          <InputLabel
                            id="demo-simple-select-label"
                            InputLabelProps={{
                              sx: {
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                            sx={{
                              fontSize: 15,
                              "&.Mui-focused": {
                                color: "#6DD6C2!important",
                                fontWeight: "600",
                              },
                            }}
                          >
                            Appointment Type
                          </InputLabel>
                          <Select
                            labelId="sms-label"
                            id="demo-simple-select"
                            label="Appointment Type"
                            error={appointmentTypeError}
                            onChange={(event) => {
                              handleChangeOptions(event, "appointmentType");
                            }}
                            size="small"
                            InputLabelProps={{
                              sx: {
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                          >
                            <MenuItem value={1} sx={{ fontSize: 15 }}>
                              In Person
                            </MenuItem>
                            <MenuItem value={2} sx={{ fontSize: 15 }}>
                              Phone Call
                            </MenuItem>
                          </Select>
                          <>
                            {appointmentTypeError && (
                              <FormHelperText
                                sx={{
                                  color: "#d32f2f",
                                }}
                              >
                                {appointmentTypeErrorMessage}
                              </FormHelperText>
                            )}
                          </>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl
                          size="small"
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgba(0, 0, 0, 0.23)",
                            },

                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },

                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6DD6C2!important",
                            },
                          }}
                        >
                          <InputLabel
                            id="demo-simple-select-label"
                            InputLabelProps={{
                              sx: {
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                            sx={{
                              fontSize: 15,
                              "&.Mui-focused": {
                                color: "#6DD6C2!important",
                                fontWeight: "600",
                              },
                            }}
                          >
                            Status
                          </InputLabel>
                          <Select
                            labelId="sms-label"
                            id="demo-simple-select"
                            label="Status"
                            onChange={(event) => {
                              handleChangeOptions(event, "status");
                            }}
                            size="small"
                            InputLabelProps={{
                              sx: {
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                          >
                            <MenuItem
                              value={"Awaiting Confirmation"}
                              sx={{ fontSize: 15 }}
                            >
                              Awaiting Confirmation
                            </MenuItem>
                            <MenuItem value={"Confirmed"} sx={{ fontSize: 15 }}>
                              Confirmed
                            </MenuItem>
                            <MenuItem
                              value={"Left Message"}
                              sx={{ fontSize: 15 }}
                            >
                              Left Message
                            </MenuItem>
                            <MenuItem
                              value={"Rescheduled"}
                              sx={{ fontSize: 15 }}
                            >
                              Rescheduled
                            </MenuItem>
                            <MenuItem value={"Cancelled"} sx={{ fontSize: 15 }}>
                              Cancelled
                            </MenuItem>
                            <MenuItem
                              value={"Cancelled - Waiting to Rescheduled"}
                              sx={{ fontSize: 15 }}
                            >
                              Cancelled - Waiting to Rescheduled
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="comment"
                          label="Comment"
                          type="text"
                          id="comment"
                          fontSize="15"
                          value={appointmentData.comment}
                          onChange={(event) => {
                            setAppointmentData({
                              ...appointmentData,
                              comment: event.target.value,
                            });
                          }}
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#6DD6C2",
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "98.5%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6DD6C2",
                            },
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        sx={{ marginTop: "15px" }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          onClick={onSubmitHandler}
                          sx={{
                            fontWeight: 600,
                            textTransform: "capitalize",
                            pl: 4,
                            pr: 4,
                            backgroundColor: "#6DD6C2",
                            borderColor: "#6DD6C2",
                            color: "#212223",
                            "&:hover": {
                              // Customize the hover color
                              backgroundColor: "#6DD6C2",
                              borderColor: "#6DD6C2",
                            },
                          }}
                        >
                          Submit
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          onClick={onReset}
                          sx={{
                            fontWeight: 600,
                            textTransform: "capitalize",
                            pl: 4,
                            pr: 4,
                            backgroundColor: "#212223",
                            borderColor: "#212223",
                            color: "#FFFFFF",
                            marginLeft: "10px",
                            "&:hover": {
                              // Customize the hover color
                              backgroundColor: "#212223",
                              borderColor: "#212223",
                            },
                          }}
                        >
                          Reset
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <Loader loading={isloading} />
      </Box>
    </>
  );
};
