import {
  CREATE_ADMIN_FAILURE,
  CREATE_ADMIN_IN_PROGRESS,
  CREATE_ADMIN_SUCCESS,
  DELETE_ADMIN_FAILURE,
  DELETE_ADMIN_IN_PROGRESS,
  DELETE_ADMIN_SUCCESS,
  GET_ADMINS_FAILURE,
  GET_ADMINS_IN_PROGRESS,
  GET_ADMINS_SUCCESS,
  UPDATE_ADMIN_FAILURE,
  UPDATE_ADMIN_IN_PROGRESS,
  UPDATE_ADMIN_SUCCESS
} from "../actions/Admin/types";
import initialState from "../initialState";

export const admin = (state = initialState.admin, action) => {
  switch (action.type) {
    case GET_ADMINS_IN_PROGRESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
        admin_listing: {},
      };
    case GET_ADMINS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
        admin_listing: action?.data,
      };
    case GET_ADMINS_FAILURE:
      return {
        ...state,
        admin_listing: {},
        loading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case CREATE_ADMIN_IN_PROGRESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
      };
    case CREATE_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
      };
    case CREATE_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case UPDATE_ADMIN_IN_PROGRESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
      };
    case UPDATE_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
      };
    case UPDATE_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case DELETE_ADMIN_IN_PROGRESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
      };
    case DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
      };
    case DELETE_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    default:
      return state;
  }
};
