import toast from "react-hot-toast";
import {
  BASE_URL,
  CALENDAR_SEARCH_URL,
  CAMPAIGN_SEARCH_URL,
  CREATE_CAMPAIGN_URL,
  CREATE_LEAD_APPOINTMENTS_URL,
  DELETE_CAMPAIGN_URL,
  EDIT_CAMPAIGN_URL,
  FIND_ALTERNATE_CAMPAIGN_URL,
  FIND_ALTERNATE_CAMPAIGN_URL_FOR_LEAD,
  GET_ALL_CAMPAIGNS_URL,
  GET_CAMPAIGN_LIST_BY_FILTER_URL,
  GET_CAMPAIGN_SHOW_URL,
  GET_VIEW_MAP_DETAILS_URL,
  GET_VIEW_MAP_DETAILS_URL_FOR_LEAD,
  UPDATE_ALTERNATE_CAMPAIGN_URL,
  UPDATE_ALTERNATE_CAMPAIGN_URL_FOR_LEAD,
  UPDATE_CAMPAIGN_URL,
  CAMPAIGN_NAME_SEARCH_URL,
} from "../../../config/api_urls";
import {
  ERROR_MESSAGE,
  ERROR_TYPE,
  KEYS,
  getItemFromLocalStorage,
} from "../../../helpers";
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  putRequest,
} from "../../../services";
import {
  CAMPAIGN_SEARCH_FAILURE,
  CAMPAIGN_SEARCH_IN_PROGRESS,
  CAMPAIGN_SEARCH_SUCCESS,
  CREATE_CAMPAIGN_FAILURE,
  CREATE_CAMPAIGN_IN_PROGRESS,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_LEAD_APPOINTMENT_FAILURE,
  CREATE_LEAD_APPOINTMENT_IN_PROGRESS,
  CREATE_LEAD_APPOINTMENT_SUCCESS,
  DELETE_CAMPAIGN_FAILURE,
  DELETE_CAMPAIGN_IN_PROGRESS,
  DELETE_CAMPAIGN_SUCCESS,
  FIND_ALTERNATE_CAMPAIGN_FAILURE,
  FIND_ALTERNATE_CAMPAIGN_IN_PROGRESS,
  FIND_ALTERNATE_CAMPAIGN_SUCCESS,
  GET_CALENDAR_EVENTS_FAILURE,
  GET_CALENDAR_EVENTS_IN_PROGRESS,
  GET_CALENDAR_EVENTS_SUCCESS,
  GET_CAMPAIGNS_FAILURE,
  GET_CAMPAIGNS_IN_PROGRESS,
  GET_CAMPAIGNS_SUCCESS,
  GET_CAMPAIGN_LIST_BY_FILTER_FAILURE,
  GET_CAMPAIGN_LIST_BY_FILTER_IN_PROGRESS,
  GET_CAMPAIGN_LIST_BY_FILTER_SUCCESS,
  GET_CAMPAIGN_SHOW_FAILURE,
  GET_CAMPAIGN_SHOW_IN_PROGRESS,
  GET_CAMPAIGN_SHOW_SUCCESS,
  GET_VIEW_MAP_DETAILS_FAILURE,
  GET_VIEW_MAP_DETAILS_IN_PROGRESS,
  GET_VIEW_MAP_DETAILS_SUCCESS,
  UPDATE_CAMPAIGN_KEY_FAILURE,
  UPDATE_CAMPAIGN_KEY_IN_PROGRESS,
  UPDATE_CAMPAIGN_KEY_SUCCESS,
  UPDATE_CAMPAIGN_WITH_ALTERNATE_CAMPAIGN_FAILURE,
  UPDATE_CAMPAIGN_WITH_ALTERNATE_CAMPAIGN_IN_PROGRESS,
  UPDATE_CAMPAIGN_WITH_ALTERNATE_CAMPAIGN_SUCCESS,
  UPDATE_CIRCLE_DRAGE,
  UPDATE_RADIUS,
  CAMPAIGN_NAME_SEARCH_FAILURE,
  CAMPAIGN_NAME_SEARCH_IN_PROGRESS,
  CAMPAIGN_NAME_SEARCH_SUCCESS,
} from "./types";
import moment from "moment";
import { logout } from "../authentication";

// Action Creators
export const createCampaignInProgress = () => ({
  type: CREATE_CAMPAIGN_IN_PROGRESS,
});

export const createCampaignSuccess = (data) => ({
  type: CREATE_CAMPAIGN_SUCCESS,
  data,
});

export const createCampaignFailure = (error) => ({
  type: CREATE_CAMPAIGN_FAILURE,
  error,
});

export const createCampaign = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Extract payload data for the campaign
      const {
        agentId,
        callerId,
        campaignName,
        county_array,
        fields_array,
        operator_array,
        radius_array,
        values_array,
        types_array,
        isEdit,
        id,
        // Other campaign-specific properties
      } = payload;
      // Create params object with the required keys for the API request
      const params = {
        radius_array,
        operator_array,
        fields_array,
        types_array,
        values_array,
        twilio_cid: callerId,
        name: campaignName,
        agent: agentId,
        county_array,
        state_array: [],
      };

      // Dispatch in progress action
      dispatch(createCampaignInProgress());

      // Make API request to create the campaign
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      
      let URL = isEdit
        ? `${BASE_URL}${EDIT_CAMPAIGN_URL}${id}/`
        : `${BASE_URL}${CREATE_CAMPAIGN_URL}`;

      let response;
      if (isEdit) {
        response = await putRequest(URL, params,"",token);
      } else {
        response = await postRequest(URL, params, "", token);
      }

      // Check response status
      if (response?.status === 201 || response?.status === 200) {
        dispatch(createCampaignSuccess(response?.data));
        cb();
        toast.success(
          `Campaign has been ${isEdit ? "updated" : "created"} successfully`,
          {
            position: "bottom-center",
          }
        );
      } else {
        dispatch(
          createCampaignFailure({
            type: ERROR_TYPE.API.CAMPAIGNS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized resplet tokenErr = error?.response?.data?.error.message
        let tokenErr = error?.response?.data?.error.message;
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const err =
        error?.response?.data?.name?.length > 0
          ? error?.response?.data?.name[0]
          : null;
      const errorMessage =
        err ??
        error?.response?.data?.error?.message ??
        ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        createCampaignFailure({
          type: ERROR_TYPE.API.CAMPAIGNS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Action Creators
export const getCampaignListByFilterInProgress = () => ({
  type: GET_CAMPAIGN_LIST_BY_FILTER_IN_PROGRESS,
});

export const getCampaignListByFilterSuccess = (data) => ({
  type: GET_CAMPAIGN_LIST_BY_FILTER_SUCCESS,
  data,
});

export const updateNextUrl = (url) => ({
  type: "UPDATE_NEXT",
  url,
});

export const getCampaignListByFilterFailure = (error) => ({
  type: GET_CAMPAIGN_LIST_BY_FILTER_FAILURE,
  error,
});
export const getUpdatedData = (existingData, newData, offset) => {
  return offset !== 1 ? existingData.concat(newData) : newData;
};

export const getCampaignListByFilter = (payload, page = 1, cb = () => {}) => {
  return async (dispatch, getState) => {
    try {
      // Dispatch in progress action
      dispatch(getCampaignListByFilterInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN); // Replace KEYS.TOKEN with your token key
      // Make API request to get campaign list by filter
      const encodedCampaignName = encodeURIComponent(
        payload.campaign_name.trim()
      );

      const URL =
        payload.filter === "active" ||
        payload.filter === "inactive" ||
        payload.filter === "all"
          ? `${BASE_URL}${GET_CAMPAIGN_LIST_BY_FILTER_URL}/?status=${payload.filter}&campaign_name=${encodedCampaignName}`
          : `${BASE_URL}${GET_CAMPAIGN_LIST_BY_FILTER_URL}/`;

      const response = await getRequest(URL, token);
      // Check response status
      if (response?.status === 200) {
        // const newData = getUpdatedData(
        //   campaign?.campaignList,
        //   response?.data ?? [],
        //   page
        // );
        dispatch(getCampaignListByFilterSuccess(response.data.results));
        dispatch(updateNextUrl(response.data.next));
      } else {
        dispatch(
          getCampaignListByFilterFailure({
            type: ERROR_TYPE.API.CAMPAIGNS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message;
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        getCampaignListByFilterFailure({
          type: ERROR_TYPE.API.CAMPAIGNS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};
export const getCampaignListMoreData = () => {
  return async (dispatch, getState) => {
    try {
      // Dispatch in progress action
      const { campaign } = getState();
      dispatch(getCampaignListByFilterInProgress());
      const token = getItemFromLocalStorage(KEYS.TOKEN); // Replace KEYS.TOKEN with your token key
      // Make API request to get campaign list by filter

      const URL = campaign.next;

      const response = await getRequest(URL, token);
      // Check response status
      if (response?.status === 200) {
        const newData = getUpdatedData(
          campaign?.campaignList,
          response?.data.results ?? [],
          2
        );
        dispatch(getCampaignListByFilterSuccess(newData));
        dispatch(updateNextUrl(response.data.next));
      } else {
        dispatch(
          getCampaignListByFilterFailure({
            type: ERROR_TYPE.API.CAMPAIGNS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message;
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        getCampaignListByFilterFailure({
          type: ERROR_TYPE.API.CAMPAIGNS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

export const getCampaignShowInProgress = () => ({
  type: GET_CAMPAIGN_SHOW_IN_PROGRESS,
});

export const getCampaignShowSuccess = (data) => ({
  type: GET_CAMPAIGN_SHOW_SUCCESS,
  data,
});

export const getCampaignShowFailure = (error) => ({
  type: GET_CAMPAIGN_SHOW_FAILURE,
  error,
});

export const getCampaignShow = (campaignId, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(getCampaignShowInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN); // Replace KEYS.TOKEN with your token key
      // Make API request to get campaign show data

      //   let URL = `https://run.mocky.io/v3/4b73cd1e-3315-4c14-8206-50c3ffeb46cb`;
      let URL = `${BASE_URL}${GET_CAMPAIGN_SHOW_URL}${campaignId ?? 0}/`;
      const response = await getRequest(URL, token);

      // Check response status
      if (response?.status === 200) {
        dispatch(getCampaignShowSuccess(response?.data));
        cb();
      } else {
        dispatch(
          getCampaignShowFailure({
            type: ERROR_TYPE.API.CAMPAIGNS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message;
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        getCampaignShowFailure({
          type: ERROR_TYPE.API.CAMPAIGNS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Action creators for campaign delete in progress
export const deleteCampaignInProgress = () => ({
  type: DELETE_CAMPAIGN_IN_PROGRESS,
});

// Action creators for campaign delete success
export const deleteCampaignSuccess = (data) => ({
  type: DELETE_CAMPAIGN_SUCCESS,
  data,
});

// Action creators for campaign delete failure
export const deleteCampaignFailure = (error) => ({
  type: DELETE_CAMPAIGN_FAILURE,
  error,
});

// Action method for campaign delete
export const deleteCampaign = (campaignId, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(deleteCampaignInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to delete campaign

      let URL = `${BASE_URL}${DELETE_CAMPAIGN_URL}${campaignId ?? 0}/delete/`;

      const response = await deleteRequest(URL, token);

      // Check response status
      if (response?.status === 204) {
        dispatch(deleteCampaignSuccess(response?.data));
        cb();
      } else {
        dispatch(
          deleteCampaignFailure({
            type: ERROR_TYPE.API.CAMPAIGNS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      // Handle error scenarios
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message;
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        deleteCampaignFailure({
          type: ERROR_TYPE.API.CAMPAIGNS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Action Creators for getting campaigns
export const getCampaignsInProgress = () => ({
  type: GET_CAMPAIGNS_IN_PROGRESS,
});

export const getCampaignsSuccess = (data) => ({
  type: GET_CAMPAIGNS_SUCCESS,
  data,
});

export const updateCurrentCircleRadius = (radi) => ({
  type: UPDATE_RADIUS,
  radi,
});
export const updateCurrentDrag = (drag) => ({
  type: UPDATE_CIRCLE_DRAGE,
  drag,
});
export const getCampaignsFailure = (error) => ({
  type: GET_CAMPAIGNS_FAILURE,
  error,
});

export const getAllCampaigns = () => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(getCampaignsInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to get all campaigns
      const response = await getRequest(
        `${BASE_URL}${GET_ALL_CAMPAIGNS_URL}`, // Update to the correct URL for getting campaigns
        token
      );

      // Check response status  
      if (response?.status === 200) {
        dispatch(getCampaignsSuccess(response?.data));
      } else {
        dispatch(
          getCampaignsFailure({
            type: ERROR_TYPE.API.CAMPAIGNS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      // Handle error scenarios
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message;
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        getCampaignsFailure({
          type: ERROR_TYPE.API.CAMPAIGNS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Action Creators
export const campaignSearchInProgress = () => ({
  type: CAMPAIGN_SEARCH_IN_PROGRESS,
});

export const campaignSearchSuccess = (data) => ({
  type: CAMPAIGN_SEARCH_SUCCESS,
  data,
});

export const campaignSearchFailure = (error) => ({
  type: CAMPAIGN_SEARCH_FAILURE,
  error,
});

export const campaignSearch = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(campaignSearchInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to search for campaigns

      const URL = `${BASE_URL}${CAMPAIGN_SEARCH_URL}`;

      const response = await postRequest(URL,payload, "", token);

      // Check response status
      if (response?.status === 200) {
        dispatch(campaignSearchSuccess(response?.data));
      } else {
        dispatch(
          campaignSearchFailure({
            type: ERROR_TYPE.API.CAMPAIGNS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message;
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      // const errorMessage =error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      let errArr = [];
      for (let key in error?.response?.data) {
        let value = error?.response?.data[key];
        if (typeof value === "object") {
          let nestedValue = "";
          for (let nestedKey in value) {
            nestedValue += `${value[nestedKey]}`;
          }
          value = nestedValue.trim();
        }
        let errToast = `${value}`;
        errArr.push(errToast);
        toast.error(errToast, {
          position: "right-top",
        });
      }

      dispatch(
        campaignSearchFailure({
          type: ERROR_TYPE.API.CAMPAIGNS.UNKNOWN,
          message: errArr.join(", "),
        })
      );
    }
  };
};

// Action Creators
export const getCalendarEventsInProgress = () => ({
  type: GET_CALENDAR_EVENTS_IN_PROGRESS,
});

export const getCalendarEventsSuccess = (data) => ({
  type: GET_CALENDAR_EVENTS_SUCCESS,
  data,
});

export const getCalendarEventsFailure = (error) => ({
  type: GET_CALENDAR_EVENTS_FAILURE,
  error,
});

// Thunk Method for Calendar Search
export const getCalendarEvents = (id, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      const payload = {
        agent_id: id,
      };
      dispatch(getCalendarEventsInProgress());
      const token = getItemFromLocalStorage(KEYS.TOKEN);

      const URL = `${BASE_URL}${CALENDAR_SEARCH_URL}`;

      const response = await getRequest(URL, token, payload);

      // Check response status
      if (response?.status === 200) {
        dispatch(getCalendarEventsSuccess(response?.data ?? []));
        cb();
      } else {
        dispatch(
          getCalendarEventsFailure({
            type: ERROR_TYPE.API.CAMPAIGNS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message;
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        getCalendarEventsFailure({
          type: ERROR_TYPE.API.CAMPAIGNS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Action Creators for creating lead appointments
export const createLeadAppointmentInProgress = () => ({
  type: CREATE_LEAD_APPOINTMENT_IN_PROGRESS,
});

export const createLeadAppointmentSuccess = (data) => ({
  type: CREATE_LEAD_APPOINTMENT_SUCCESS,
  data,
});

export const createLeadAppointmentFailure = (error) => ({
  type: CREATE_LEAD_APPOINTMENT_FAILURE,
  error,
});

const convertDate = (date) => {
  const formatedDate = moment(date, "YYYY-MM-DD").format("MM-DD-YYYY");
  return formatedDate ?? date;
};

const formatTime = (dateTimeObject) => {
  // Extract the hour and minute from the date object
  const hour = dateTimeObject.$H;
  const minute = dateTimeObject.$m;

  // Create a moment object and format it in "hh:mm A" format
  const formattedTime = moment({ hour, minute }).format("hh:mm A");

  return formattedTime;
};

export const createLeadAppointment = (payload, cb = () => {}) => {
  return async (dispatch) => {
    // 
    try {
      const {
        confirmAppointmentDate,
        confirmAppointmentTime,
        appointmentType,
        lead_id,
        status,
        totalEmployees,
        nextRenewalDate,
        liabilityRenewalDate,
        liabilityCarrier,
        workersCompRenewalDate,
        autoRenewalDate,
        totalLocations,
        workersCompCarrier,
        autoCarrier,
        totalVehicles,
        comment,
        campaignInfo,
      } = payload;

      // Create params object for lead appointment creation
      const params = {
        appointment_date:
          confirmAppointmentDate !== ""
            ? convertDate(confirmAppointmentDate)
            : null,
        appointment_time: formatTime(confirmAppointmentTime),
        agency_calling_for_id: campaignInfo?.campaign_id,
        lead_id: lead_id,
        prospect_id: campaignInfo?.prospect?.id,
        liability_renewable_date:
          liabilityRenewalDate !== ""
            ? convertDate(liabilityRenewalDate)
            : null,
        liability_carrier: liabilityCarrier,
        total_locations: totalLocations,
        workers_comp_renewal_date:
          workersCompRenewalDate !== ""
            ? convertDate(workersCompRenewalDate)
            : null,
        auto_renewal_date:
          autoRenewalDate !== "" ? convertDate(autoRenewalDate) : null,
        next_renewable_date: nextRenewalDate,
        auto_carrier: autoCarrier,
        workers_comp_carrier: workersCompCarrier,
        total_vehicles: totalVehicles,
        appointment_type: appointmentType,
        employee_size: totalEmployees,
        status: status,
        comment: comment,
      };

      // Dispatch in progress action
      dispatch(createLeadAppointmentInProgress());

      // Make API request to create the lead appointment
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      const response = await postRequest(
        `${BASE_URL}${CREATE_LEAD_APPOINTMENTS_URL}`, // Update to the correct URL for creating lead appointments
        params,
        "",
        token
      );

      // Check response status and dispatch appropriate actions
      if (response?.status === 201) {
        dispatch(createLeadAppointmentSuccess(response?.data));
        cb();
        toast.success("Appointment has been scheduled", {
          position: "bottom-center",
        });
      } else {
        dispatch(
          createLeadAppointmentFailure({
            type: ERROR_TYPE.API.CAMPAIGNS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      dispatch(
        createLeadAppointmentFailure({
          type: ERROR_TYPE.API.CAMPAIGNS.UNKNOWN,
          message: error?.response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
        })
      );
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message;
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      
      const errorMessage = error?.response?.data?.error.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        createLeadAppointmentFailure({
          type: ERROR_TYPE.API.CAMPAIGNS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

export const updateCampaignKeyInProgress = () => ({
  type: UPDATE_CAMPAIGN_KEY_IN_PROGRESS,
});

export const updateCampaignKeySuccess = (data) => ({
  type: UPDATE_CAMPAIGN_KEY_SUCCESS,
  data,
});

export const updateCampaignKeyFailure = (error) => ({
  type: UPDATE_CAMPAIGN_KEY_FAILURE,
  error,
});

export const updateCampaignKey = (campaignId, value, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Create params object with the key and value to update
      const params = {
        // campaign_id: campaignId,
        is_active: value,
      };

      // Dispatch in progress action
      dispatch(updateCampaignKeyInProgress());

      // Make API request to update the specific key in the campaign
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      const response = await patchRequest(
        `${BASE_URL}${UPDATE_CAMPAIGN_URL}${campaignId}/`,
        params,
        token
      );

      // const response = await putRequest(
      //   `${BASE_URL}${UPDATE_LEAD_KEY_URL
      //   }${campaignId}/`,
      //   params,
      //   token
      // );
      //       // Check response status
      if (response?.status === 200) {
        dispatch(updateCampaignKeySuccess(response?.data));
        cb();
        toast.success(`Campaign Status has been updated successfully`, {
          position: "bottom-center",
        });
      } else {
        dispatch(
          updateCampaignKeyFailure({
            type: ERROR_TYPE.API.CAMPAIGNS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message;
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      // const errorMessage =error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      let errArr = [];
      for (let key in error.response.data) {
        let value = error.response.data[key];
        if (typeof value === "object") {
          let nestedValue = "";
          for (let nestedKey in value) {
            nestedValue += ` ${value[nestedKey]} `;
          }
          value = nestedValue.trim();
        }
        let errToast = `${key}: ${value}`;
        errArr.push(errToast);
        toast.error(errToast, {
          position: "right-top",
        });
      }

      dispatch(
        updateCampaignKeyFailure({
          type: ERROR_TYPE.API.CAMPAIGNS.UNKNOWN,
          message: errArr.join(", "),
        })
      );
    }
  };
};

export const concatData = (existingData, newData, responseData) => {
  // Check if existingData is truthy, if not, use newData. If both are falsy, default to an empty array.
  const concatedData = existingData
    ? existingData.concat(newData)
    : newData || [];

  // Extract additional keys from response data
  const additionalKeys = {
    callback_id: responseData?.callback_id || null,
    prospect_id: responseData?.prospect_id || null,
  };

  // Create the final data object with the concatenated data and additional keys
  const data = {
    concatedData,
    ...additionalKeys,
  };

  return data;
};

export const concatDataLead = (existingData, newData, responseData) => {
  // Check if any of the arrays is undefined, null, [], or {}
  if (!existingData || !newData || !existingData.length || !newData.length) {
    // If one of them is empty or undefined, return the one that has data
    return {
      concatedData: existingData || newData || [],
      callback_id: responseData?.callback_id || null,
      lead_id: responseData?.lead_id || null,
    };
  }

  // If both arrays have data, concatenate them
  const concatedData = existingData.concat(newData);

  // Extract additional keys from response data
  const additionalKeys = {
    callback_id: responseData?.callback_id || null,
    lead_id: responseData?.lead_id || null,
  };

  // Create the final data object with the concatenated data and additional keys
  const data = {
    concatedData,
    ...additionalKeys,
  };

  return data;
};

// Action creators for finding alternate campaign in progress
export const findAlternateCampaignInProgress = () => ({
  type: FIND_ALTERNATE_CAMPAIGN_IN_PROGRESS,
});

// Action creators for finding alternate campaign success
export const findAlternateCampaignSuccess = (data) => ({
  type: FIND_ALTERNATE_CAMPAIGN_SUCCESS,
  data,
});

// Action creators for finding alternate campaign failure
export const findAlternateCampaignFailure = (error) => ({
  type: FIND_ALTERNATE_CAMPAIGN_FAILURE,
  error,
});

// Method for finding alternate campaign
export const findAlternateCampaign = (
  payload,
  cb = () => {},
  isFromLead = false
) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(findAlternateCampaignInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to find alternate campaign
      const URL = isFromLead
        ? `${BASE_URL}${FIND_ALTERNATE_CAMPAIGN_URL_FOR_LEAD}`
        : `${BASE_URL}${FIND_ALTERNATE_CAMPAIGN_URL}`;
      const response = await postRequest(URL, payload, "", token);

      // Check response status
      if (response?.status === 200) {
        // dispatch(findAlternateCampaignSuccess(response?.data));
        const concatedData = isFromLead
          ? concatDataLead(
              response?.data.campaigns1,
              response?.data.campaigns2,
              response?.data
            )
          : concatData(
              response?.data.campaigns1,
              response?.data.campaigns2,
              response?.data
            );
        // const updatedData = concatedData?.length > 0 ? concatedData : [];
        dispatch(findAlternateCampaignSuccess(concatedData));
        cb();
      } else {
        dispatch(
          findAlternateCampaignFailure({
            type: ERROR_TYPE.API.CAMPAIGNS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      // Handle error scenarios
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message;
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        findAlternateCampaignFailure({
          type: ERROR_TYPE.API.CAMPAIGNS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Action creators for updating campaign with alternate campaign in progress
export const updateCampaignWithAlternateCamapaignInProgress = () => ({
  type: UPDATE_CAMPAIGN_WITH_ALTERNATE_CAMPAIGN_IN_PROGRESS,
});

// Action creators for updating campaign with alternate campaign success
export const updateCampaignWithAlternateCamapaignSuccess = (data) => ({
  type: UPDATE_CAMPAIGN_WITH_ALTERNATE_CAMPAIGN_SUCCESS,
  data,
});

// Action creators for updating campaign with alternate campaign failure
export const updateCampaignWithAlternateCamapaignFailure = (error) => ({
  type: UPDATE_CAMPAIGN_WITH_ALTERNATE_CAMPAIGN_FAILURE,
  error,
});

// Method for updating campaign with alternate campaign
export const updateCampaignWithAlternateCamapaign = (
  payload,
  cb = () => {},
  isFromLead = false
) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(updateCampaignWithAlternateCamapaignInProgress());

      // Make API request to update the specific key in the campaign
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      let URL = isFromLead
        ? `${BASE_URL}${UPDATE_ALTERNATE_CAMPAIGN_URL_FOR_LEAD}`
        : `${BASE_URL}${UPDATE_ALTERNATE_CAMPAIGN_URL}`;
      const response = await postRequest(URL, payload, "", token);

      // Check response status
      if (response?.status === 200) {
        dispatch(updateCampaignWithAlternateCamapaignSuccess(response?.data));
        cb();
        toast.success(`Updated successfully`, {
          position: "bottom-center",
        });
      } else {
        dispatch(
          updateCampaignWithAlternateCamapaignFailure({
            type: ERROR_TYPE.API.CAMPAIGNS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message;
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        updateCampaignWithAlternateCamapaignFailure({
          type: ERROR_TYPE.API.CAMPAIGNS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Action creators for get view map details request
export const getViewMapDetailsInProgress = () => ({
  type: GET_VIEW_MAP_DETAILS_IN_PROGRESS,
});

// Action creators for get view map details success
export const getViewMapDetailsSuccess = (data) => ({
  type: GET_VIEW_MAP_DETAILS_SUCCESS,
  data,
});

// Action creators for get view map details failure
export const getViewMapDetailsFailure = (error) => ({
  type: GET_VIEW_MAP_DETAILS_FAILURE,
  error,
});

// Action method for getting view map details
export const getViewMapDetails = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(getViewMapDetailsInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN); // Replace KEYS.TOKEN with your token key
      // Make API request to get view map details

      let URL = payload?.isFromLead
        ? `${BASE_URL}${GET_VIEW_MAP_DETAILS_URL_FOR_LEAD}${
            payload?.campaignId ?? 0
          }/${payload?.leadId ?? 0}/`
        : `${BASE_URL}${GET_VIEW_MAP_DETAILS_URL}${payload?.campaignId ?? 0}/${
            payload?.prospectId ?? 0
          }/`;
      const response = await getRequest(URL, token);

      // Check response status
      if (response?.status === 200) {
        dispatch(getViewMapDetailsSuccess(response?.data));
        cb(response?.data);
      } else {
        dispatch(
          getViewMapDetailsFailure({
            type: ERROR_TYPE.API.CAMPAIGNS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message;
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        getViewMapDetailsFailure({
          type: ERROR_TYPE.API.CAMPAIGNS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

export const campaignNameSearchInProgress = () => ({
  type: CAMPAIGN_NAME_SEARCH_IN_PROGRESS,
});

export const campaignNameSearchSuccess = (data) => ({
  type: CAMPAIGN_NAME_SEARCH_SUCCESS,
  data,
});

export const campaignNameSearchFailure = (error) => ({
  type: CAMPAIGN_NAME_SEARCH_FAILURE,
  error,
});

export const campaignNameSearch = (payload) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(campaignNameSearchInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to search for campaigns

      const URL = `${BASE_URL}${CAMPAIGN_NAME_SEARCH_URL}?campaign_name=${payload}`;

      const response = await getRequest(URL, token);
      // Check response status
      // debugger
      if (response?.status === 200) {
        // debugger
        dispatch(campaignNameSearchSuccess(response?.data));
      } else {
        dispatch(
          campaignNameSearchFailure({
            type: ERROR_TYPE.API.CAMPAIGNS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error?.message;
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      // const errorMessage =error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      let errArr = [];
      for (let key in error?.response?.data) {
        let value = error?.response?.data[key];
        if (typeof value === "object") {
          let nestedValue = "";
          for (let nestedKey in value) {
            nestedValue += `${value[nestedKey]}`;
          }
          value = nestedValue.trim();
        }
        let errToast = `${value}`;
        errArr.push(errToast);
        toast.error(errToast, {
          position: "right-top",
        });
      }

      dispatch(
        campaignNameSearchFailure({
          type: ERROR_TYPE.API.CAMPAIGNS.UNKNOWN,
          message: errArr.join(", "),
        })
      );
    }
  };
};
