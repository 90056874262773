import React, { useState } from "react";
import { Box, Modal, Button } from "@mui/material";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useEffect } from "react";
import { formatPhoneNumber } from "../../../helpers";
import CloseIcon from "@mui/icons-material/Close";
export const ProfileModal = (props) => {
  const { open, onClose, profileInformation } = props;

  const handleClose = () => {
    onClose(false);
  };
  const ProfileInformation = () => {
    return (
      <Box sx={{ p: 0.3, backgroundColor: "#6DD6C2", borderRadius: "4px" }}>
        <Box sx={{ p: 2, backgroundColor: "#fff", borderRadius: "4px" }}>
          <Typography
            component="h2"
            variant="h2"
            sx={{
              fontSize: 18,
              color: "#212223",
              fontWeight: 600,
              backgroundColor: "#90949f21",
              pt: 1.5,
              pl: 1.5,
              pb: 1.5,
            }}
          >
            Basic Profile Information :
          </Typography>
          <Box sx={{ backgroundColor: "#fff", padding: "15px", pr: 0 }}>
            <Grid container spacing={2.4}>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{ fontSize: 15, color: "#6B7489", fontWeight: 600 }}
                  >
                    User Type :
                  </Typography>
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      fontSize: 15,
                      color: "#000",
                      fontWeight: 500,
                      marginLeft: "20px",
                    }}
                  >
                    {profileInformation?.user_type}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{ fontSize: 15, color: "#6B7489", fontWeight: 600 }}
                  >
                    Account Status :
                  </Typography>
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      fontSize: 15,
                      color: "#000",
                      fontWeight: 500,
                      marginLeft: "20px",
                    }}
                  >
                    {profileInformation?.account_status
                      ? "True"
                      : "False"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{ fontSize: 15, color: "#6B7489", fontWeight: 600 }}
                  >
                    First Name :
                  </Typography>
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      fontSize: 15,
                      color: "#000",
                      fontWeight: 500,
                      marginLeft: "20px",
                    }}
                  >
                    {profileInformation?.first_name}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{ fontSize: 15, color: "#6B7489", fontWeight: 600 }}
                  >
                    Last Name :
                  </Typography>
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      fontSize: 15,
                      color: "#000",
                      fontWeight: 500,
                      marginLeft: "20px",
                    }}
                  >
                    {profileInformation?.last_name}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{ fontSize: 15, color: "#6B7489", fontWeight: 600 }}
                  >
                    E-mail ID :
                  </Typography>
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      fontSize: 15,
                      color: "#000",
                      fontWeight: 500,
                      marginLeft: "20px",
                    }}
                  >
                    {profileInformation?.email}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{ fontSize: 15, color: "#6B7489", fontWeight: 600 }}
                  >
                    Phone Number :
                  </Typography>
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      fontSize: 15,
                      color: "#000",
                      fontWeight: 500,
                      marginLeft: "20px",
                    }}
                  >
                    {profileInformation?.phone_number}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{ fontSize: 15, color: "#6B7489", fontWeight: 600 }}
                  >
                    Street :
                  </Typography>
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      fontSize: 15,
                      color: "#000",
                      fontWeight: 500,
                      marginLeft: "20px",
                    }}
                  >
                    {profileInformation?.street}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{ fontSize: 15, color: "#6B7489", fontWeight: 600 }}
                  >
                    Address :
                  </Typography>
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      fontSize: 15,

                      color: "#000",
                      fontWeight: 500,
                      marginLeft: "20px",
                    }}
                  >
                    {profileInformation?.address}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{ fontSize: 15, color: "#6B7489", fontWeight: 600 }}
                  >
                    City :
                  </Typography>
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      fontSize: 15,
                      color: "#000",
                      fontWeight: 500,
                      marginLeft: "20px",
                    }}
                  >
                    {profileInformation?.city}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{ fontSize: 15, color: "#6B7489", fontWeight: 600 }}
                  >
                    State :
                  </Typography>
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      fontSize: 15,
                      color: "#000",
                      fontWeight: 500,
                      marginLeft: "20px",
                    }}
                  >
                    {profileInformation?.state}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                    alignItems: "center",
                    pb: 2,
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{ fontSize: 15, color: "#6B7489", fontWeight: 600 }}
                  >
                    Zip Code :
                  </Typography>
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      fontSize: 15,
                      color: "#000",
                      fontWeight: 500,
                      marginLeft: "20px",
                    }}
                  >
                    {profileInformation?.zip_code}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "800px",
          width: "100%",
          maxHeight: { xs: "500px", sm: "500px", md: "100%" },
          overflowY: { xs: "scroll", sm: "scroll", md: "inherit" },
          boxShadow: 0,
          borderRadius: "7px",
          p: 0,
        }}
      >
        <Button
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: "0px",
            right: "0px",
            color: "#000",
            backgroundColor: "#6DD6C2",
            minWidth: "20px",
            "&:hover": {
              backgroundColor: "#6DD6C2",
            },
          }}
        >
          <CloseIcon />
        </Button>
        <ProfileInformation />
      </Box>
    </Modal>
  );
};
