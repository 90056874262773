export const CREATE_ADMIN_IN_PROGRESS = "CREATE_ADMIN_IN_PROGRESS";
export const CREATE_ADMIN_SUCCESS = "CREATE_ADMIN_SUCCESS";
export const CREATE_ADMIN_FAILURE = "CREATE_ADMIN_FAILURE";

export const GET_ADMINS_IN_PROGRESS = "GET_ADMINS_IN_PROGRESS";
export const GET_ADMINS_SUCCESS = "GET_ADMINS_SUCCESS";
export const GET_ADMINS_FAILURE = "GET_ADMINS_FAILURE";

export const UPDATE_ADMIN_IN_PROGRESS = "UPDATE_ADMIN_IN_PROGRESS";
export const UPDATE_ADMIN_SUCCESS = "UPDATE_ADMIN_SUCCESS";
export const UPDATE_ADMIN_FAILURE = "UPDATE_ADMIN_FAILURE";

export const DELETE_ADMIN_IN_PROGRESS = "DELETE_ADMIN_IN_PROGRESS";
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS";
export const DELETE_ADMIN_FAILURE = "DELETE_ADMIN_FAILURE";

export const UPDATE_CALL_RECORDING_STATUS_IN_PROGRESS =
  "UPDATE_CALL_RECORDING_STATUS_IN_PROGRESS";
export const UPDATE_CALL_RECORDING_STATUS_SUCCESS =
  "UPDATE_CALL_RECORDING_STATUS_SUCCESS";
export const UPDATE_CALL_RECORDING_STATUS_FAILURE =
  "UPDATE_CALL_RECORDING_STATUS_FAILURE";

export const UPLOAD_EMPLOYEE_CSV_IN_PROGRESS =
  "UPLOAD_EMPLOYEE_CSV_IN_PROGRESS";
export const UPLOAD_EMPLOYEE_CSV_SUCCESS = "UPLOAD_EMPLOYEE_CSV_SUCCESS";
export const UPLOAD_EMPLOYEE_CSV_FAILURE = "UPLOAD_EMPLOYEE_CSV_FAILURE";
