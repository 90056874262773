// LoginScreen.js
import React from "react";
import { Box, Button, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { SearchCampaignsAddData } from "./SearchCampaignsAddData";
import { MapboxMap } from "./MapboxMap";
import { CampaignsParentTable } from "./CampaignsParentTable";
import { useState } from "react";
import {
  convertOptToPayloadForCampaignSearch,
} from "../../helpers";
import { useSelector } from "react-redux";

export const SearchCampaigns = (props) => {
  const { onSearchCampaign, onDeleteCampaign } = props;

  const [counties, setCounties] = useState([]);
  const [circles, setCircles] = useState([]);

  const [page, setPage] = React.useState(0);
  const [offset, setOffset] = useState(1);


  const campaignData = useSelector(
    (state) => state?.campaign?.searchedCampaignList
  );

  const [optionValues, setOptionValues] = useState([
    { field: "", type: "", value: "" },
  ]);

  const areAnyFieldsNotEmpty = (data) => {
    for (const option of data) {
      if (option.field !== "" || option.type !== "" || option.value !== "") {
        return true; // Return true if any field is not empty
      }
    }
    return false; // Return false if all fields are empty
  };


  const onSearch = async () => {
    const filter_payload = await convertOptToPayloadForCampaignSearch(
      optionValues
    );

    // onSearchProspects(payload, 1);

    const payload = {
      ...filter_payload,
      county_array: counties ?? [],
      radius_array: {
        $or: circles,
      },
    };
    onSearchCampaign(payload);
  };

  const handleClearAll = async () => {
    if (areAnyFieldsNotEmpty(optionValues)) {
      // setPage(0);
      setOptionValues([{ field: "", type: "", value: "" }]);
      const payload = {
        fields_array: [""],
        types_array: [""],
        values_array: [""],
      };
      onSearchCampaign(payload);
    }
  };

  const deleteCampaign = (campaignId) => {
    onDeleteCampaign(campaignId, onSearch);
  };


  const convertApiResponseToRows = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }

    const createData = (
      user,
      status,
      amountOwed,
      accountName,
      twilioCid,
      createdBy,
      createdAt,
      modifiedBy,
      modifiedAt,
      operations
    ) => {
      return {
        user,
        status,
        amountOwed,
        accountName,
        twilioCid,
        createdBy,
        createdAt,
        modifiedBy,
        modifiedAt,
        operations,
      };
    };

    return data.map((item) => {
      const {
        is_active,
        amount_purchased_month,
        total_amount_owned,
        account_name,
        twilio_cid,
        created_by,
        created_at,
        modified_by,
        modified_at,
      } = item;
      const user = item;
      return createData(
        user,
        is_active,
        total_amount_owned,
        account_name,
        twilio_cid,
        created_by,
        created_at,
        modified_by,
        modified_at,
        user
      );
    });
  };

  const tableRows = convertApiResponseToRows(campaignData);

  return (
    <>
      <Box
        sx={{
          margin: { xs: "10px", md:'30px' },
          marginTop: { xs: "10px", md:'30px' },
          
        }}
      >
        <Accordion defaultExpanded sx={{ borderRadius: "15px!Important" }}>
          <Box
            sx={{
              display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: { xs: "0px", md: "15px" },
                  paddingBottom: { xs: "10px", sm: "0px", md: "0px" },
                  backgroundColor: " #d2f5ec",
                  borderTopLeftRadius: "15px!Important",
                  borderTopRightRadius: "15px!Important",
                  borderBottom: 1,
                  borderColor: "#676a6c4a",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{ color: "#212223", pointerEvents: "auto" }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ width: "100%", margin: "-4px 0!important" }}
            >
              <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: { xs: 16, sm: 16, md:17, lg:18 }, color: "#212223", fontWeight: 600 }}
              >
                County Selection Map
              </Typography>
            </AccordionSummary>
          </Box>
          <AccordionDetails
            sx={{
              padding: 0,
              overflow: "hidden",
            }}
          >
            <MapboxMap
              counties={counties}
              setCounties={setCounties}
              setCircles={setCircles}
              circles={circles}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded sx={{ borderRadius: "15px!Important" }}>
          <Box
            sx={{
              display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: { xs: "0px", md: "15px" },
              paddingBottom: { xs: "10px", sm: "0px", md: "0px" },
              backgroundColor: " #d2f5ec",
              borderTopLeftRadius: "15px!Important",
              borderTopRightRadius: "15px!Important",
              borderBottom: 1,
              borderColor: "#676a6c4a",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{ color: "#212223", pointerEvents: "auto" }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ width: "100%", margin: "-4px 0!important" }}
            >
              <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: { xs: 16, sm: 16, md:17, lg:18 }, color: "#212223", fontWeight: 600 }}
              >
                Campaign Query Builder
              </Typography>
            </AccordionSummary>
          </Box>
          <AccordionDetails >
            <SearchCampaignsAddData 
              val={optionValues}
              setVal={setOptionValues}
            />
            <Box
              sx={{
                display: { xs: "flex", sm: "flex", md: "flex", lg: "flex" },
                alignItems: "center",
                gap: "10px",                
                mt: 1.5,
                mb:1
              }}
            >
              <Button
                variant="contained"
                onClick={onSearch}
                sx={{
                  fontWeight: 600,
                  pl: 4,
                    pr: 4,
                  textTransform: "capitalize",
                  width: "100%",
                  maxWidth: "max-content",
                  backgroundColor: "#6DD6C2",
                  borderColor: "#6DD6C2",
                  color: "#212223",
                  "&:hover": {
                    backgroundColor: "#6DD6C2",
                    borderColor: "#6DD6C2",
                  },
                }}
              >
                Search Campaign
              </Button>
              <Button
                variant="contained"
                onClick={handleClearAll}
                sx={{
                  textTransform: "capitalize",
                  width: "100%",
                  maxWidth: "max-content",
                  backgroundColor: "#212223",
                  borderColor: "#212223",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#212223",
                    borderColor: "#212223",
                  },
                }}
              >
                Clear Query
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded sx={{ borderRadius: "15px!Important" }}>
          <Box
            sx={{
              display: { xs: "grid", sm: "flex", md: "flex", lg: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: { xs: "0px", md: "15px" },
              paddingBottom: { xs: "10px", sm: "0px", md: "0px" },
              backgroundColor: " #d2f5ec",
              borderTopLeftRadius: "15px!Important",
              borderTopRightRadius: "15px!Important",
              borderBottom: 1,
              borderColor: "#676a6c4a",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ width: "100%" }}
            >
              <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: { xs: 16, sm: 16, md:17, lg:18 }, color: "#454647", fontWeight: 600 }}
              >
                Filtered Campaign List | Total Campaign Count :{" "}
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: 18,
                    color: "#18a689",
                    fontWeight: 600,
                    display: "initial",
                  }}
                >
                  {tableRows ? tableRows.length:0}
                </Typography>{" "}
              </Typography>
            </AccordionSummary>
            <Box
              sx={{
                display: "flex",
                marginLeft: { xs: "15px", sm: "0px" , md:'0px' },
                marginRight: { xs: "0px", sm: "10px" , md:'15px' },
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  width: 130,
                  textTransform: "capitalize",
                  marginRight: 1,
                  backgroundColor: "#6DD6C2",
                  borderColor: "#6DD6C2",
                  fontWeight: 600,
                  color: "#212223",
                  "&:hover": {
                    backgroundColor: "#6DD6C2",
                    borderColor: "#6DD6C2",
                  },
                }}
              >
                Mass Update
              </Button>
              <Button
                variant="contained"
                sx={{
                  mr: 1,
                  height: "37px",
                  width: 100,
                  backgroundColor: "#fff",
                  borderColor: "#6DD6C2",
                  textTransform: "capitalize",
                  border: 2,
                  fontWeight: 600,
                  color: "#1ab394",
                  size: "small",
                  "&:hover": {
                    backgroundColor: "#32c3a3",
                    borderColor: "#1ab394!important",
                    color: "#212223",
                  },
                }}
              >
                Export
                <ExitToAppIcon sx={{ marginLeft: "5px", fontSize: "20px" }} />
              </Button>
            </Box>
          </Box>

          <AccordionDetails>
            <Box
              sx={{
                padding: {
                  xs: "10px 0px 0px 0px",
                  sm: "20px 10px 0px 10px",
                  md: "20px 10px 0px 10px",
                  lg: "20px 10px 0px 10px",
                },
                marginBottom:20
              }}
            >
              <CampaignsParentTable
                onDelete={deleteCampaign}
                filteredRows={tableRows}
                filterCampaignName={""}
                getCampaignsFilter={()=>{}}
                page={page}
                setPage={setPage}
                offset={offset}
                setOffset={setOffset}
                fetchMoreRecord={()=>{}}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};
