import {
  BASE_URL,
  CREATE_ADMIN_URL,
  DELETE_ADMINS_URL,
  GET_ALL_ADMINS,
  UPDATE_ADMIN_URL,
} from "../../../config/api_urls";

import {
  ERROR_MESSAGE,
  ERROR_TYPE,
  KEYS,
  getItemFromLocalStorage,
} from "../../../helpers";

import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  putRequest,
} from "../../../services";

import { logout } from "../authentication";
import { LOGOUT } from "../authentication/types";
import {
  CREATE_ADMIN_FAILURE,
  CREATE_ADMIN_IN_PROGRESS,
  CREATE_ADMIN_SUCCESS,
  DELETE_ADMIN_FAILURE,
  DELETE_ADMIN_IN_PROGRESS,
  DELETE_ADMIN_SUCCESS,
  GET_ADMINS_FAILURE,
  GET_ADMINS_IN_PROGRESS,
  GET_ADMINS_SUCCESS,
  UPDATE_ADMIN_FAILURE,
  UPDATE_ADMIN_IN_PROGRESS,
  UPDATE_ADMIN_SUCCESS,
} from "./types";
import toast from "react-hot-toast";

export const CreateAdminInProgress = () => ({
  type: CREATE_ADMIN_IN_PROGRESS,
});

export const CreateAdminSuccess = (data) => ({
  type: CREATE_ADMIN_SUCCESS,
  data,
});

export const CreateAdminFailure = (error) => ({
  type: CREATE_ADMIN_FAILURE,
  error,
});

export const CreateAdmin = (payload, cb = () => { }) => {
  return async (dispatch) => {
    try {
      // Extract payload data
      const {
        userName,
        lastName,
        userEmail,
        phoneNo,
        cellPhone,
        localTimeZone,
        state,
        city,
        address,
        street,
        zipCode,
        isActive,
        isAdmin,
        ratePerHour,
        commissionStranded,
        team,
        commissionPlusPay,
        bonusPay
      } = payload;

      // // Create params object
      const params = {
        user: {
          email: userEmail ?? "",
          first_name: userName ?? "",
          last_name: lastName ?? "",
          phone_number: phoneNo ?? "",
          cell_phone_number: cellPhone ?? "",
          time_zone: localTimeZone ?? "",
          street: street ?? "",
          address: address ?? "",
          city: city ?? "",
          state: state ?? "",
          zip_code: zipCode ?? "",
          // user_type:"",
          is_admin: isAdmin ?? false,
          is_active: isActive === false ? false : true,
          // two_way_authentication: false,
          // two_fa_authentication_enable: false  
        },
        rate_per_hour: ratePerHour ?? "",
        commission_standard_pay: commissionStranded ?? "",
        team: team.toLowerCase() ?? "",
        call_record: true,
        commission_plus_pay: commissionPlusPay ?? "",
        bonus_pay: bonusPay ?? "",
      };

      // Dispatch in progress action
      dispatch(CreateAdminInProgress());

      // Make API request to create employee
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      const response = await postRequest(
        `${BASE_URL}${CREATE_ADMIN_URL}`,
        params,
        "",
        token
      );

      // Check response status
      if (response?.status === 201) {
        dispatch(CreateAdminSuccess(response?.data));
        cb();
      } else {
        dispatch(
          CreateAdminFailure({
            type: ERROR_TYPE.API.ADMIN.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage = error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      let errArr = []
      for (let key in error.response.data) {
        let value = error.response.data[key];
        if (typeof value === 'object') {
          let nestedValue = '';
          for (let nestedKey in value) {
            nestedValue += `${value[nestedKey]}`;
          }
          value = nestedValue.trim();

        }
        let errToast = `${value}`
        errArr.push(errToast)
        toast.error(errToast, {
          position: "right-top",
        });
      }
      dispatch(
        CreateAdminFailure({
          type: ERROR_TYPE.API.ADMIN.UNKNOWN,
          message: errArr.join(", "),
        })
      );
    }
  };
};

export const GetAdminInProgress = () => ({
  type: GET_ADMINS_IN_PROGRESS,
});

export const GetAdminSuccess = (data) => ({
  type: GET_ADMINS_SUCCESS,
  data,
});

export const GetAdminFailure = (error) => ({
  type: GET_ADMINS_FAILURE,
  error,
});

export const GetAdmins = (payload, cb = () => { }) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(GetAdminInProgress());

      // {{lead}}api/employee-profiles/?name=mic&state=fl&account_status=false

      const params = {
        name: payload.name !== "" ? payload?.name : null,
        state: payload.stateName !== "" ? payload?.stateName : null,
        account_status:
          payload.isActive !== "all"
            ? payload.isActive === "active"
              ? true
              : false
            : null,
      };

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to read employee
      const response = await getRequest(
        `${BASE_URL}${GET_ALL_ADMINS}`,
        token,
        params
      );

      // Check response status
      if (response?.status === 200) {
        dispatch(GetAdminSuccess(response?.data));
        cb();
      } else {
        dispatch(
          GetAdminFailure({
            type: ERROR_TYPE.API.ADMIN.UNKNOWN,
            message:
              response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      // Handle error scenarios
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        GetAdminFailure({
          type: ERROR_TYPE.API.ADMIN.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

export const DeleteAdminInProgress = () => ({
  type: DELETE_ADMIN_IN_PROGRESS,
});

export const DeleteAdminSuccess = (data) => ({
  type: DELETE_ADMIN_SUCCESS,
  data,
});

export const DeleteAdminFailure = (error) => ({
  type: DELETE_ADMIN_FAILURE,
  error,
});

export const DeleteAdmin = (payload, cb = () => { }) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(DeleteAdminInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to delete employee

      const response = await deleteRequest(
        `${BASE_URL}${DELETE_ADMINS_URL}`,
        payload,
        token
      );

      // Check response status
      if (response?.status === 204) {
        dispatch(DeleteAdminSuccess(response?.data));
        cb();
      } else {
        dispatch(
          DeleteAdminFailure({
            type: ERROR_TYPE.API.ADMIN.UNKNOWN,
            message:
              response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      // Handle error scenarios
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        DeleteAdminFailure({
          type: ERROR_TYPE.API.ADMIN.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

export const UpdateAdminInProgress = () => ({
  type: UPDATE_ADMIN_IN_PROGRESS,
});

export const UpdateAdminSuccess = (data) => ({
  type: UPDATE_ADMIN_SUCCESS,
  data,
});

export const UpdateAdminFailure = (error) => ({
  type: UPDATE_ADMIN_FAILURE,
  error,
});

export const UpdateAdmin = (adminId, payload, cb = () => { }) => {
  return async (dispatch) => {
    try {
      // Extract payload data
      const {
        userName,
        lastName,
        userEmail,
        phoneNo,
        cellPhone,
        localTimeZone,
        state,
        city,
        address,
        street,
        zipCode,
        isActive,
        isAdmin,
        ratePerHour,
        commissionStranded,
        team,
        commissionPlusPay,
        bonusPay
      } = payload;

      // Create params object
      const params = {
        user: {
          email: userEmail ?? "",
          first_name: userName ?? "",
          last_name: lastName ?? "",
          phone_number: phoneNo ?? "",
          cell_phone_number: cellPhone ?? "",
          time_zone: localTimeZone ?? "",
          street: street ?? "",
          address: address ?? "",
          city: city ?? "",
          state: state ?? "",
          zip_code: zipCode ?? "",
          is_active: isActive === false ? false : true,
          is_admin: isAdmin ?? false,
        },
        rate_per_hour: ratePerHour ?? "",
        commission_standard_pay: commissionStranded ?? "",
        team: team.toLowerCase() ?? "",
        call_record: true,
        commission_plus_pay: commissionPlusPay ?? "",
        bonus_pay: bonusPay ?? "",
      };

      // Dispatch in progress action
      dispatch(UpdateAdminInProgress());
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to update employee
      const response = await putRequest(
        `${BASE_URL}${UPDATE_ADMIN_URL}${adminId}/`,
        params,
        token
      );

      // Check response status
      if (response?.status === 200) {
        dispatch(UpdateAdminSuccess(response?.data));
        cb();
      } else {
        dispatch(
          UpdateAdminFailure({
            type: ERROR_TYPE.API.ADMIN.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      // Handle error scenarios
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        UpdateAdminFailure({
          type: ERROR_TYPE.API.ADMIN.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};