// LoginScreen.js
import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import { useState } from "react";
import {
  ERROR_MESSAGE,
  formatPhoneNumber,
  validateMobileNumber,
  validateName,
  validatePincode,
} from "../../helpers";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Loader } from "../Common";
import { useSelector } from "react-redux";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  Autocomplete as GoogleAutocomplete,
} from "@react-google-maps/api";

export const CreateProspects = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    onCreateProspects,
    getAllUser,
    onUpdateProspects,
    getAllProspects,
  } = props;

  const { isEdit, prospectInfoData } = location.state || {};
  const [triedSubmit, setTriedSubmit] = useState(false);

  //access state data
  const isloading = useSelector((state) => state?.prospect?.loading);

  const callResult = useSelector(
    (state) => state?.prospect?.callResult?.options
  );
  const userDetails = useSelector(
    (state) => state?.prospect?.user_details?.data?.results
  );

  //prospects info states
  const initialProspectInfo = {
    phoneNumber: isEdit ? prospectInfoData?.phone_number : "",
    alternativePhoneNumber: isEdit
      ? prospectInfoData?.alternative_phone_number
      : "",
    executiveTitle: isEdit ? prospectInfoData?.title : "",
    executiveFirstName: isEdit ? prospectInfoData?.first_name : "",
    executiveLastName: isEdit ? prospectInfoData?.last_name : "",
    companyName: isEdit ? prospectInfoData?.company_name : "",
    companyWebsite: isEdit ? prospectInfoData?.website : "",
    primarySicCode: isEdit ? prospectInfoData?.sic_code : "",
    primarySicCodeDescription: isEdit
      ? prospectInfoData?.sic_code_description
      : "",
    primaryNaicsCode: isEdit ? prospectInfoData?.naics_code : "",
    primaryNaicsCodeDescription: isEdit
      ? prospectInfoData?.naics_code_description
      : "",
    comments: isEdit ? prospectInfoData?.comments : "",
  };

  const [prospectInfo, setProspectInfo] = useState(initialProspectInfo);

  //address info states
  const initialAddressInfo = {
    locationStreet: isEdit ? prospectInfoData?.address : "",
    locationCity: isEdit ? prospectInfoData?.city : "",
    locationState: isEdit ? prospectInfoData?.state : "",
    locationZipCode: isEdit ? prospectInfoData?.zip_code : "",
    countyDescription: isEdit ? prospectInfoData?.county : "",
  };

  const [addressInfo, setAddressInfo] = useState(initialAddressInfo);

  //location states for storing data
  const initialLocationInfo = {
    latitude: isEdit ? prospectInfoData?.latitude?.toString() : "",
    longitude: isEdit ? prospectInfoData?.longitude?.toString() : "",
  };

  const [locationInfo, setLocationInfo] = useState(initialLocationInfo);

  //additional states for storing data
  const initialAdditionalData = {
    renewalDate: isEdit ? prospectInfoData?.renewal_date : "",
    squareFootage: isEdit ? prospectInfoData?.square_footage : "",
    callResult: isEdit ? prospectInfoData?.call_result : "",
    callResultDescription: isEdit
      ? prospectInfoData?.call_result_description
      : "",
    creditRating: isEdit ? prospectInfoData?.credit_rating : "",
    lastAppointmentWith: isEdit ? prospectInfoData?.last_appointment_with : "",
    carrierOfRecord: isEdit ? prospectInfoData?.carrier_of_record : "",
    locationEmployeeSizeActual: isEdit ? prospectInfoData?.employee_size : "",
  };

  const [additionalData, setAdditionalData] = useState(initialAdditionalData);

  //handle errors
  const executiveFirstNameError =
    triedSubmit &&
    (prospectInfo?.executiveFirstName?.trim().length === 0 ||
      !validateName(prospectInfo?.executiveFirstName));
  const executiveFirstNameErrorMessage =
    triedSubmit && prospectInfo?.executiveFirstName?.trim().length === 0
      ? ERROR_MESSAGE?.NAME?.IN_VALID
      : "";

  const executiveLastNameError =
    triedSubmit &&
    (prospectInfo?.executiveLastName?.trim().length === 0 ||
      !validateName(prospectInfo?.executiveLastName));
  const executiveLastNameErrorMessage =
    triedSubmit && prospectInfo?.executiveLastName?.trim().length === 0
      ? ERROR_MESSAGE?.NAME?.IN_VALID
      : "";

  const companyNameError =
    triedSubmit &&
    (prospectInfo?.companyName?.trim().length === 0 ||
      !validateName(prospectInfo?.companyName));
  const companyNameErrorMessage =
    triedSubmit && prospectInfo?.companyName?.trim().length === 0
      ? ERROR_MESSAGE?.NAME?.IN_VALID
      : "";

  // const phoneNumberError =
  //   triedSubmit &&
  //   (prospectInfo?.phoneNumber?.trim().length === 0 ||
  //     !validateMobileNumber(prospectInfo?.phoneNumber));

  const phoneNumberError =
    triedSubmit && prospectInfo?.phoneNumber?.trim().length === 0;

  const phoneNumberErrorMessage =
    triedSubmit && prospectInfo?.phoneNumber.trim().length === 0
      ? "Please enter valid phone number"
      : "";
  const sicCodeDiscriptionError =
    triedSubmit &&
    (prospectInfo?.primarySicCodeDescription?.trim().length === 0 ||
      !validateName(prospectInfo?.primarySicCodeDescription));
  const sicCodeDiscriptionErrorMessage =
    triedSubmit && prospectInfo?.primarySicCodeDescription?.trim().length === 0
      ? "Please enter valid primary naics code discription"
      : "";
  const naicsCodeDiscriptionError =
    triedSubmit &&
    (prospectInfo?.primaryNaicsCodeDescription?.trim().length === 0 ||
      !validateName(prospectInfo?.primaryNaicsCodeDescription));
  const naicsCodeDiscriptionErrorMessage =
    triedSubmit &&
    prospectInfo?.primaryNaicsCodeDescription?.trim().length === 0
      ? "Please enter valid primary naics code discription"
      : "";

  const naicsCodeError =
    triedSubmit && prospectInfo?.primaryNaicsCode?.trim().length === 0;
  const naicsCodeErrorMessage =
    triedSubmit && prospectInfo?.primaryNaicsCode?.trim().length === 0
      ? "Please enter valid primary naics code"
      : "";

  const sicCodeError =
    triedSubmit && prospectInfo?.primarySicCode?.trim().length === 0;
  const sicCodeErrorMessage =
    triedSubmit && prospectInfo?.primarySicCode?.trim().length === 0
      ? "Please enter valid primary sic code"
      : "";

  const addressError =
    triedSubmit && addressInfo?.locationStreet.trim().length === 0;
  const addressErrorMessage =
    triedSubmit && addressInfo?.locationState?.trim().length === 0
      ? "Please enter valid address"
      : "";

  const stateError =
    triedSubmit &&
    (addressInfo?.locationState.trim().length === 0 ||
      !validateName(addressInfo?.locationState));
  const stateErrorMessage =
    triedSubmit && addressInfo?.locationState?.trim().length === 0
      ? ERROR_MESSAGE.STATE.IN_VALID
      : "";
  const cityError =
    triedSubmit &&
    (addressInfo?.locationCity.trim().length === 0 ||
      !validateName(addressInfo?.locationCity));
  const cityErrorMessage =
    triedSubmit && addressInfo?.locationCity?.trim().length === 0
      ? ERROR_MESSAGE.CITY.IN_VALID
      : "";
  const zipCodeError =
    triedSubmit && !validatePincode(addressInfo?.locationZipCode);
  const zipCodeErrorMessage =
    triedSubmit &&
    (addressInfo?.locationZipCode?.trim().length < 5 ||
      !validatePincode(addressInfo?.locationZipCode))
      ? "Please enter valid zip code"
      : "";
  const countyDescriptionError =
    triedSubmit &&
    (addressInfo?.countyDescription.trim().length === 0 ||
      !validateName(addressInfo?.countyDescription));
  const countyDescriptionErrorMessage =
    triedSubmit && addressInfo?.countyDescription?.trim().length === 0
      ? "Please enter valid discription"
      : "";

  const latitudeError =
    triedSubmit && locationInfo?.latitude?.toString().trim().length === 0;
  const latitudeErrorMessage =
    triedSubmit && locationInfo?.latitude?.toString().trim().length === 0
      ? "Please enter valid latitude"
      : "";
  const longitudeError =
    triedSubmit && locationInfo?.longitude?.toString().trim().length === 0;
  const longitudeErrorMessage =
    triedSubmit && locationInfo?.longitude?.toString().trim().length === 0
      ? "Please enter valid latitude"
      : "";

  const renewalDateError =
    triedSubmit && additionalData?.renewalDate?.toString()?.trim().length === 0;

  const renewalDateErrorMessage =
    triedSubmit && additionalData?.renewalDate?.trim().length === 0
      ? "Please enter valid date"
      : "";

  const callResultError =
    triedSubmit && additionalData?.callResult?.toString().trim().length === 0;

  const callResultErrorMessage =
    triedSubmit && additionalData?.callResult?.toString().trim().length === 0
      ? "Please select call result"
      : "";

  const lastAppointmentWithError =
    triedSubmit &&
    additionalData?.lastAppointmentWith?.toString().trim().length === 0;

  const lastAppointmentWithErrorMessage =
    triedSubmit &&
    additionalData?.lastAppointmentWith?.toString().trim().length === 0
      ? "Please select last appointment with"
      : "";

  const handleChange = (event, newValue, type) => {
    if (type === "callResult") {
      setAdditionalData({
        ...additionalData,
        callResult: newValue.id,
      });
    } else {
      setAdditionalData({
        ...additionalData,
        lastAppointmentWith: newValue?.id,
      });
    }
  };

  useEffect(() => {
    getAllUser();
    AOS.init();
  }, []);

  const autoCompleteRef = React.useRef(null);

  const handlePlaceChanged = () => {
    const place = autoCompleteRef.current.getPlace();
    const addressComponents = place?.address_components;
    //console.log("place==========>", place);

    let street = "";
    let city = "";
    let state = "";
    let zipCode = "";
    let county = "";

    const lat = place?.geometry.location.lat().toString();
    const long = place?.geometry.location.lng().toString();

    addressComponents?.forEach((component) => {
      const componentType = component.types[0];

      if (componentType === "street_number" || componentType === "route") {
        street = `${street} ${component.long_name}`;
      } else if (componentType === "locality") {
        city = component.long_name;
      } else if (componentType === "administrative_area_level_1") {
        state = component.short_name;
      } else if (componentType === "postal_code") {
        zipCode = component.long_name;
      } else if (componentType === "administrative_area_level_2") {
        county = component.long_name;
      }
    });

    setAddressInfo({
      ...addressInfo,
      locationStreet: street,
      locationCity: city,
      locationState: state,
      locationZipCode: zipCode,
      countyDescription: county,
    });
    setLocationInfo({
      ...locationInfo,
      latitude: lat,
      longitude: long,
    });
  };

  const isCheckFormValid = () => {
    const isExecutiveFirstNameValid =
      prospectInfo?.executiveFirstName?.trim().length > 0 &&
      validateName(prospectInfo?.executiveFirstName);

    const isExecutiveLastNameValid =
      prospectInfo?.executiveLastName?.trim().length > 0 &&
      validateName(prospectInfo?.executiveLastName);

    const isCompanyNameValid =
      prospectInfo?.companyName?.trim().length > 0 &&
      validateName(prospectInfo?.companyName);

    const isPhoneNumberValid =
      prospectInfo?.phoneNumber?.trim().length > 0 ||
      validateMobileNumber(prospectInfo?.phoneNumber);

    const isPrimarySicCodeDescriptionValid =
      prospectInfo?.primarySicCodeDescription?.trim().length > 0 &&
      validateName(prospectInfo?.primarySicCodeDescription);

    const isPrimaryNaicsCodeDescriptionValid =
      prospectInfo?.primaryNaicsCodeDescription?.trim().length > 0 &&
      validateName(prospectInfo?.primaryNaicsCodeDescription);

    const isPrimaryNaicsCodeValid =
      prospectInfo?.primaryNaicsCode?.trim().length > 0;

    const isPrimarySicCodeValid =
      prospectInfo?.primarySicCode?.trim().length > 0;

    const isAddressValid = addressInfo?.locationStreet?.trim().length > 0;

    const isStateValid =
      addressInfo?.locationState?.trim().length > 0 &&
      validateName(addressInfo?.locationState);

    const isCityValid =
      addressInfo?.locationCity?.trim().length > 0 &&
      validateName(addressInfo?.locationCity);

    const isZipCodeValid =
      addressInfo?.locationZipCode?.trim().length >= 5 &&
      validatePincode(addressInfo?.locationZipCode);

    const isCountyDescriptionValid =
      addressInfo?.countyDescription?.trim().length > 0 &&
      validateName(addressInfo?.countyDescription);

    const isLatitudeValid =
      locationInfo?.latitude?.toString().trim().length > 0;

    const isLongitudeValid =
      locationInfo?.longitude?.toString().trim().length > 0;

    const isRenewalDateValid =
      additionalData?.renewalDate?.toString()?.trim().length > 0;

    const isCallResultValid =
      additionalData?.callResult?.toString()?.trim().length > 0;

    const isLastAppointmentWithValid =
      additionalData?.lastAppointmentWith?.toString()?.trim().length > 0;

    return (
      isExecutiveFirstNameValid &&
      isExecutiveLastNameValid &&
      isCompanyNameValid &&
      isPhoneNumberValid &&
      isPrimarySicCodeDescriptionValid &&
      isPrimaryNaicsCodeDescriptionValid &&
      isPrimaryNaicsCodeValid &&
      isPrimarySicCodeValid &&
      isAddressValid &&
      isStateValid &&
      isCityValid &&
      isZipCodeValid &&
      isCountyDescriptionValid &&
      isLatitudeValid &&
      isLongitudeValid &&
      isRenewalDateValid &&
      isCallResultValid &&
      isLastAppointmentWithValid
    );
  };

  const isFormValid = isCheckFormValid();

  const callBackFun = () => {
    isEdit &&
      setTimeout(() => {
        navigate(-2);
      }, 500);

    getAllProspects();
  };

  const onReset = () => {
    setProspectInfo(initialProspectInfo);
    setAddressInfo(initialAddressInfo);
    setAdditionalData(initialAdditionalData);
    setLocationInfo(initialLocationInfo);
  };

  const onSubmitHandler = () => {
    setTriedSubmit(true);
    if (!isFormValid) return;

    if (isEdit) {
      let payload = {
        ...prospectInfo,
        ...addressInfo,
        ...locationInfo,
        ...additionalData,
      };
      let id = prospectInfoData?.id;
      onUpdateProspects(id, payload, callBackFun);
    } else {
      let payload = {
        ...prospectInfo,
        ...addressInfo,
        ...locationInfo,
        ...additionalData,
      };
      onCreateProspects(payload, onReset);
    }
  };

  return (
    <>
      <Box
        sx={{
          margin: { xs: "5px", md:'30px' },
          marginTop: { xs: "10px", md:'30px' },
          borderRadius: "15px!Important",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Accordion
              defaultExpanded
              sx={{ borderRadius: "10px!Important", boxShadow: 3 }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "#212223", pointerEvents: "auto" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  borderBottom: 1,
                  borderColor: "#676a6c4a",
                  backgroundColor: "#d2f5ec",
                  borderRadius: "10px 10px 0 0!Important",
                  pointerEvents: "none",
                }}
              >
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: { xs: 16, sm: 16, md:17, lg:18 },
                    color: "#212223",
                    fontWeight: 600,
                  }}
                >
                  Prospect Information
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    padding: "20px 10px",
                  }}
                >
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="num"
                          label="Phone Number"
                          id="num"
                          fontSize="15"
                          value={prospectInfo?.phoneNumber}
                          error={phoneNumberError}
                          helperText={
                            phoneNumberError && phoneNumberErrorMessage
                          }
                          inputProps={{ maxLength: 12 }}
                          onChange={(e) =>
                            setProspectInfo({
                              ...prospectInfo,
                              phoneNumber: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="num"
                          label="Alternative Phone Number"
                          id="num"
                          fontSize="15"
                          value={
                            formatPhoneNumber(
                              prospectInfo?.alternativePhoneNumber
                            ) ?? prospectInfo?.alternativePhoneNumber
                          }
                          onChange={(e) =>
                            setProspectInfo({
                              ...prospectInfo,
                              alternativePhoneNumber: e.target.value,
                            })
                          }
                          inputProps={{ maxLength: 12 }}
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="executivetitle"
                          label="Executive Title"
                          type="text"
                          id="executivetitle"
                          autoComplete="current-password"
                          fontSize="15"
                          value={prospectInfo?.executiveTitle}
                          onChange={(e) =>
                            setProspectInfo({
                              ...prospectInfo,
                              executiveTitle: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="firstname"
                          label="Executive First Name"
                          type="text"
                          id="firstname"
                          autoComplete="current-password"
                          fontSize="15"
                          value={prospectInfo?.executiveFirstName}
                          error={executiveFirstNameError}
                          helperText={
                            executiveFirstNameError &&
                            executiveFirstNameErrorMessage
                          }
                          onChange={(e) =>
                            setProspectInfo({
                              ...prospectInfo,
                              executiveFirstName: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="Lastname"
                          label="Executive Last Name"
                          type="text"
                          id="lasttname"
                          autoComplete="current-password"
                          fontSize="15"
                          value={prospectInfo?.executiveLastName}
                          error={executiveLastNameError}
                          helperText={
                            executiveLastNameError &&
                            executiveLastNameErrorMessage
                          }
                          onChange={(e) =>
                            setProspectInfo({
                              ...prospectInfo,
                              executiveLastName: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="companyname"
                          label="Company Name"
                          type="text"
                          id="companyname"
                          autoComplete="current-password"
                          fontSize="15"
                          value={prospectInfo?.companyName}
                          error={companyNameError}
                          helperText={
                            companyNameError && companyNameErrorMessage
                          }
                          onChange={(e) =>
                            setProspectInfo({
                              ...prospectInfo,
                              companyName: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="companyweb"
                          label="Company Website"
                          type="text"
                          id="companyweb"
                          fontSize="15"
                          value={prospectInfo?.companyWebsite}
                          onChange={(e) =>
                            setProspectInfo({
                              ...prospectInfo,
                              companyWebsite: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="num"
                          label="Primary Sic Code"
                          id="num"
                          fontSize="15"
                          value={prospectInfo?.primarySicCode}
                          error={sicCodeError}
                          helperText={sicCodeError && sicCodeErrorMessage}
                          onChange={(e) =>
                            setProspectInfo({
                              ...prospectInfo,
                              primarySicCode: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="num"
                          label="Primary Sic Code Description"
                          id="num"
                          fontSize="15"
                          error={sicCodeDiscriptionError}
                          helperText={
                            sicCodeDiscriptionError &&
                            sicCodeDiscriptionErrorMessage
                          }
                          value={prospectInfo?.primarySicCodeDescription}
                          onChange={(e) =>
                            setProspectInfo({
                              ...prospectInfo,
                              primarySicCodeDescription: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="num"
                          label="Primary Naics Code"
                          id="num"
                          fontSize="15"
                          error={naicsCodeError}
                          helperText={naicsCodeError && naicsCodeErrorMessage}
                          value={prospectInfo?.primaryNaicsCode}
                          onChange={(e) =>
                            setProspectInfo({
                              ...prospectInfo,
                              primaryNaicsCode: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="num"
                          label="Primary Naics Code Description"
                          id="num"
                          fontSize="15"
                          error={naicsCodeDiscriptionError}
                          helperText={
                            naicsCodeDiscriptionError &&
                            naicsCodeDiscriptionErrorMessage
                          }
                          value={prospectInfo?.primaryNaicsCodeDescription}
                          onChange={(e) =>
                            setProspectInfo({
                              ...prospectInfo,
                              primaryNaicsCodeDescription: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="comments"
                          label="Comments"
                          type="text"
                          id="comments"
                          value={prospectInfo?.comments}
                          onChange={(e) =>
                            setProspectInfo({
                              ...prospectInfo,
                              comments: e.target.value,
                            })
                          }
                          autoComplete="current-password"
                          fontSize="15"
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} md={12}>
            <Accordion
              defaultExpanded
              sx={{ borderRadius: "10px!Important", boxShadow: 3 }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "#212223", pointerEvents: "auto" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  borderBottom: 1,
                  borderColor: "#676a6c4a",
                  backgroundColor: "#d2f5ec",
                  borderRadius: "10px 10px 0 0!Important",
                  pointerEvents: "none",
                }}
              >
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: { xs: 16, sm: 16, md:17, lg:18 },
                    color: "#212223",
                    fontWeight: 600,
                  }}
                >
                  Address Information
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    padding: "20px 10px",
                  }}
                >
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6}>
                        <GoogleAutocomplete
                          onLoad={(autoComplete) =>
                            (autoCompleteRef.current = autoComplete)
                          }
                          onPlaceChanged={handlePlaceChanged}
                        >
                          <TextField
                            margin="0"
                            fullWidth
                            size="small"
                            name="Address"
                            label="Location Address"
                            type="text"
                            id="Address"
                            fontSize="15"
                            value={addressInfo?.locationStreet}
                            onChange={(e) =>
                              setAddressInfo({
                                ...addressInfo,
                                locationStreet: e.target.value,
                              })
                            }
                            error={addressError}
                            helperText={addressError && addressErrorMessage}
                            InputLabelProps={{
                              sx: {
                                fontSize: "15px",
                                "&.Mui-focused": {
                                  color: "#5fdbc4",
                                  fontWeight: 600,
                                },
                              },
                            }}
                            sx={{
                              marginBottom: "10px",
                              width: {
                                xs: "100%",
                                sm: "100%",
                                md: "95%",
                                lg: "95%",
                              },
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: "#fff",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6DD6C2",
                                },
                              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6DD6C2",
                                },
                            }}
                          />
                        </GoogleAutocomplete>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="city"
                          label="Location City"
                          type="text"
                          id="city"
                          autoComplete="current-password"
                          fontSize="15"
                          value={addressInfo?.locationCity}
                          onChange={(e) =>
                            setAddressInfo({
                              ...addressInfo,
                              locationCity: e.target.value,
                            })
                          }
                          error={cityError}
                          helperText={cityError && cityErrorMessage}
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="state"
                          label="Location State"
                          type="text"
                          id="state"
                          fontSize="15"
                          value={addressInfo?.locationState}
                          onChange={(e) =>
                            setAddressInfo({
                              ...addressInfo,
                              locationState: e.target.value,
                            })
                          }
                          error={stateError}
                          helperText={stateError && stateErrorMessage}
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="zip code"
                          label="Location Zip Code"
                          id="zipcode"
                          fontSize="15"
                          inputProps={{ maxLength: 5 }}
                          value={addressInfo?.locationZipCode}
                          onChange={(e) =>
                            setAddressInfo({
                              ...addressInfo,
                              locationZipCode: e.target.value,
                            })
                          }
                          error={zipCodeError}
                          helperText={zipCodeError && zipCodeErrorMessage}
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          id="description"
                          label="County Description"
                          name="description"
                          fontSize="15"
                          value={addressInfo?.countyDescription}
                          onChange={(e) =>
                            setAddressInfo({
                              ...addressInfo,
                              countyDescription: e.target.value,
                            })
                          }
                          error={countyDescriptionError}
                          helperText={
                            countyDescriptionError &&
                            countyDescriptionErrorMessage
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "97.5%",
                              lg: "97.5%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} md={12}>
            <Accordion
              defaultExpanded
              sx={{ borderRadius: "10px!Important", boxShadow: 3 }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "#212223", pointerEvents: "auto" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  borderBottom: 1,
                  borderColor: "#676a6c4a",
                  backgroundColor: "#d2f5ec",
                  borderRadius: "10px 10px 0 0!Important",
                  pointerEvents: "none",
                }}
              >
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: { xs: 16, sm: 16, md:17, lg:18 },
                    color: "#212223",
                    fontWeight: 600,
                  }}
                >
                  Location Information
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    padding: "20px 10px",
                  }}
                >
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="latitude"
                          label="Latitude"
                          type="text"
                          id="latitude"
                          fontSize="15"
                          value={locationInfo?.latitude}
                          onChange={(e) =>
                            setLocationInfo({
                              ...locationInfo,
                              latitude: e.target.value,
                            })
                          }
                          error={latitudeError}
                          helperText={latitudeError && latitudeErrorMessage}
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="longitude"
                          label="Longitude"
                          type="text"
                          id="longitude"
                          autoComplete="current-password"
                          fontSize="15"
                          value={locationInfo?.longitude}
                          onChange={(e) =>
                            setLocationInfo({
                              ...locationInfo,
                              longitude: e.target.value,
                            })
                          }
                          error={longitudeError}
                          helperText={longitudeError && longitudeErrorMessage}
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Accordion
              defaultExpanded
              sx={{ borderRadius: "10px!Important", boxShadow: 3 }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "#212223", pointerEvents: "auto" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  borderBottom: 1,
                  borderColor: "#676a6c4a",
                  backgroundColor: "#d2f5ec",
                  borderRadius: "10px 10px 0 0!Important",
                  pointerEvents: "none",
                }}
              >
                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    fontSize: { xs: 16, sm: 16, md:17, lg:18 },
                    color: "#212223",
                    fontWeight: 600,
                  }}
                >
                  Additional Information
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    padding: "20px 10px",
                  }}
                >
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                          }}
                        >
                          <Typography
                            component="h2"
                            variant="h2"
                            fullWidth
                            sx={{
                              fontSize: 15,
                              color: "#7b7b7b",
                              fontWeight: 600,
                              mb: 1.5,
                            }}
                          >
                            Renewal Date :
                          </Typography>
                          <TextField
                            margin="0"
                            fullWidth
                            size="small"
                            name="renewaldate"
                            type="date"
                            id="renewaldate"
                            fontSize="15"
                            value={additionalData?.renewalDate}
                            onChange={(e) =>
                              setAdditionalData({
                                ...additionalData,
                                renewalDate: e.target.value,
                              })
                            }
                            error={renewalDateError}
                            helperText={
                              renewalDateError && renewalDateErrorMessage
                            }
                            InputLabelProps={{
                              sx: {
                                fontSize: "15px",
                                "&.Mui-focused": {
                                  color: "#5fdbc4",
                                  fontWeight: 600,
                                },
                              },
                            }}
                            sx={{
                              marginBottom: "10px",
                              width: {
                                xs: "100%",
                                sm: "100%",
                                md: "450px",
                                lg: "450px",
                              },
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: "#fff",
                                borderColor: "#18a689",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6DD6C2",
                                },
                              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6DD6C2",
                                },
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="footage"
                          label="Square Footage"
                          type="email"
                          id="footage"
                          autoComplete="current-password"
                          fontSize="15"
                          value={additionalData?.squareFootage}
                          onChange={(e) =>
                            setAdditionalData({
                              ...additionalData,
                              squareFootage: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <FormControl
                          size="small"
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgba(0, 0, 0, 0.23)",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6DD6C2!important",
                            },
                          }}
                        >
                          <Autocomplete
                            id="combo-box-demo"
                            options={callResult}
                            getOptionLabel={(option) => option.description}
                            onChange={(event, newValue) => {
                              handleChange(event, newValue, "callResult");
                            }}
                            size="small"
                            disableClearable={true}
                            error={callResultError}
                            helperText={
                              callResultError && callResultErrorMessage
                            }
                            sx={{
                              "& .MuiInputLabel-root": {
                                "&.Mui-focused": {
                                  color: "#18a689!important",
                                },
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(0, 0, 0, 0.23)",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#18a689",
                                },
                              "& .Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#18a689",
                                },
                            }}
                            InputLabelProps={{
                              sx: {
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                            renderInput={(params) => (
                              <>
                                <TextField {...params} label="Call Result" />
                                {callResultError && (
                                  <FormHelperText
                                    sx={{
                                      color: "#d32f2f",
                                    }}
                                  >
                                    {callResultErrorMessage}
                                  </FormHelperText>
                                )}
                              </>
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="callresult"
                          label="Call Result Description"
                          type="text"
                          id="callresult"
                          fontSize="15"
                          value={additionalData?.callResultDescription}
                          onChange={(e) =>
                            setAdditionalData({
                              ...additionalData,
                              callResultDescription: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="creditrating"
                          label="Credit Rating"
                          type="text"
                          id="creditrating"
                          fontSize="15"
                          value={additionalData?.creditRating}
                          onChange={(e) =>
                            setAdditionalData({
                              ...additionalData,
                              creditRating: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <FormControl
                          size="small"
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgba(0, 0, 0, 0.23)",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6DD6C2!important",
                            },
                          }}
                        >
                          <Autocomplete
                            id="combo-box-demo"
                            options={userDetails}
                            getOptionLabel={(option) => option.full_name}
                            onChange={(event, newValue) => {
                              handleChange(event, newValue, "");
                            }}
                            size="small"
                            disableClearable={true}
                            error={lastAppointmentWithError}
                            helperText={
                              lastAppointmentWithError &&
                              lastAppointmentWithErrorMessage
                            }
                            sx={{
                              "& .MuiInputLabel-root": {
                                "&.Mui-focused": {
                                  color: "#18a689!important",
                                },
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(0, 0, 0, 0.23)",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#18a689",
                                },
                              "& .Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#18a689",
                                },
                            }}
                            InputLabelProps={{
                              sx: {
                                "&.Mui-focused": {
                                  color: "#6DD6C2",
                                },
                              },
                            }}
                            renderInput={(params) => (
                              <>
                                <TextField
                                  {...params}
                                  label="Last Appointment With"
                                />
                                {lastAppointmentWithError && (
                                  <FormHelperText
                                    sx={{
                                      color: "#d32f2f",
                                    }}
                                  >
                                    {lastAppointmentWithErrorMessage}
                                  </FormHelperText>
                                )}
                              </>
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="carrierrecord"
                          label="Carrier Of Record"
                          type="text"
                          id="carrierrecord"
                          fontSize="15"
                          value={additionalData?.carrierOfRecord}
                          onChange={(e) =>
                            setAdditionalData({
                              ...additionalData,
                              carrierOfRecord: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          margin="0"
                          fullWidth
                          size="small"
                          name="sizeactual"
                          label="Location Employee Size Actual"
                          type="text"
                          id="sizeactual"
                          fontSize="15"
                          value={additionalData?.locationEmployeeSizeActual}
                          onChange={(e) =>
                            setAdditionalData({
                              ...additionalData,
                              locationEmployeeSizeActual: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            sx: {
                              fontSize: "15px",
                              "&.Mui-focused": {
                                color: "#5fdbc4",
                                fontWeight: 600,
                              },
                            },
                          }}
                          sx={{
                            marginBottom: "10px",
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "95%",
                              lg: "95%",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#fff",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6DD6C2",
                              },
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        sx={{ marginTop: "15px" }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          onClick={() => {
                            onSubmitHandler();
                          }}
                          sx={{
                            fontWeight: 600,
                            textTransform: "capitalize",
                            pl: 4,
                            pr: 4,
                            backgroundColor: "#6DD6C2",
                            borderColor: "#6DD6C2",
                            color: "#212223",
                            "&:hover": {
                              // Customize the hover color
                              backgroundColor: "#6DD6C2",
                              borderColor: "#6DD6C2",
                            },
                          }}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <Loader loading={isloading} />
      </Box>
    </>
  );
};
