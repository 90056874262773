import {
  BASE_URL,
  CREATE_CALLBACKS_URL,
  GET_CALLBACK_DETAILS,
  GET_CALLBACK_SEARCH_URL,
  UPDATE_ASSIGNED_TO_URL,
} from "../../../config/api_urls";
import {
  ERROR_MESSAGE,
  ERROR_TYPE,
  KEYS,
  getItemFromLocalStorage,
} from "../../../helpers";
import { getRequest, postRequest } from "../../../services";
import { LOGOUT } from "../authentication/types";
import toast from "react-hot-toast";
import moment from "moment";
import {
  CREATE_CALLBACK_FAILURE,
  CREATE_CALLBACK_IN_PROGRESS,
  CREATE_CALLBACK_SUCCESS,
  GET_CALLBACK_SEARCH_FAILURE,
  GET_CALLBACK_SEARCH_IN_PROGRESS,
  GET_CALLBACK_SEARCH_SUCCESS,
  GET_CALLBACK_SHOW_FAILURE,
  GET_CALLBACK_SHOW_IN_PROGRESS,
  GET_CALLBACK_SHOW_SUCCESS,
  UPDATE_ASSIGNED_LEAD_FAILURE,
  UPDATE_ASSIGNED_LEAD_IN_PROGRESS,
  UPDATE_ASSIGNED_LEAD_SUCCESS,
} from "./types";
import { logout } from "../authentication";

// Replace the old action creators with new ones for creating callbacks
export const createCallbackInProgress = () => ({
  type: CREATE_CALLBACK_IN_PROGRESS,
});

export const createCallbackSuccess = (data) => ({
  type: CREATE_CALLBACK_SUCCESS,
  data,
});

export const createCallbackFailure = (error) => ({
  type: CREATE_CALLBACK_FAILURE,
  error,
});

// Replace the old action creator with a new one for creating callbacks
export const createCallback = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Extract payload data
      const { prospectId, agentId, campaignId, dateTime, callBackReason } =
        payload;

      // Create params object for callback creation

      const formattedDateTime = moment(dateTime).format("MM-DD-YYYY h:mm A");

      const params = {
        date_time: formattedDateTime ?? null,
        prospect_id: prospectId ?? null,
        comment: callBackReason ?? null,
        agent_id: agentId ?? null,
        campaign_id: campaignId ?? null,
      };

      // Dispatch in progress action
      dispatch(createCallbackInProgress());

      // Make API request to create the callback
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      const response = await postRequest(
        `${BASE_URL}${CREATE_CALLBACKS_URL}`, // Update to the correct URL for creating callbacks
        params,
        "",
        token
      );

      // Check response status and dispatch appropriate actions
      if (response?.status === 201) {
        dispatch(createCallbackSuccess(response?.data));
        let is_prmpt = true;
        let message = "Call back has been scheduled"
        cb(is_prmpt, message);
      } else {
        dispatch(
          createCallbackFailure({
            type: ERROR_TYPE.API.CALLBACKS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        createCallbackFailure({
          type: ERROR_TYPE.API.CALLBACKS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Action Creators for callback search
export const getCallBackSearchInProgress = () => ({
  type: GET_CALLBACK_SEARCH_IN_PROGRESS,
});

export const getCallBackSearchSuccess = (data) => ({
  type: GET_CALLBACK_SEARCH_SUCCESS,
  data,
});

export const getCallBackSearchFailure = (error) => ({
  type: GET_CALLBACK_SEARCH_FAILURE,
  error,
});

const getUpdatedData = (existingData, newData, offset) => {
  return offset !== 1 ? existingData.concat(newData) : newData;
};

export const getCallBackSearch = (payload, offset = 1, cb = () => {}) => {
  return async (dispatch, getState) => {
    try {
      // Dispatch in progress action
      const { callback } = getState();
      dispatch(getCallBackSearchInProgress());
      const token = getItemFromLocalStorage(KEYS.TOKEN); // Replace KEYS.TOKEN with your token key
      // Make API request to get callback search data
      const URL = `${BASE_URL}${GET_CALLBACK_SEARCH_URL}?page=${offset}`;

      const response = await getRequest(URL, token, payload);

      // Check response status
      if (response?.status === 200) {
        const newData = getUpdatedData(
          callback?.callBackData,
          response?.data ?? [],
          offset
        );
        dispatch(getCallBackSearchSuccess(newData));
        cb();
      } else {
        dispatch(
          getCallBackSearchFailure({
            type: ERROR_TYPE.API.CALLBACKS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        getCallBackSearchFailure({
          type: ERROR_TYPE.API.CALLBACKS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

export const getCallBackShowInProgress = () => ({
  type: GET_CALLBACK_SHOW_IN_PROGRESS,
});

export const getCallBackShowSuccess = (data) => ({
  type: GET_CALLBACK_SHOW_SUCCESS,
  data,
});

export const getCallBackShowFailure = (error) => ({
  type: GET_CALLBACK_SHOW_FAILURE,
  error,
});

export const getCallBackShow = (campaignId, prospectId, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(getCallBackShowInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN); // Replace KEYS.TOKEN with your token key
      // Make API request to get campaign show data

      //   let URL = `https://run.mocky.io/v3/4b73cd1e-3315-4c14-8206-50c3ffeb46cb`;
      let URL = `${BASE_URL}${GET_CALLBACK_DETAILS}${campaignId ?? 0}/${
        prospectId ?? 0
      }/`;
      const response = await getRequest(URL, token);
       
      // Check response status
      if (response?.status === 200) {
        dispatch(getCallBackShowSuccess(response?.data));
        cb();
      } else {
        dispatch(
          getCallBackShowFailure({
            type: ERROR_TYPE.API.CALLBACKS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        getCallBackShowFailure({
          type: ERROR_TYPE.API.CALLBACKS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

// Action creators for updating assigned lead in progress
export const updateAssignedLeadInProgress = () => ({
  type: UPDATE_ASSIGNED_LEAD_IN_PROGRESS,
});

// Action creators for updating assigned lead success
export const updateAssignedLeadSuccess = (data) => ({
  type: UPDATE_ASSIGNED_LEAD_SUCCESS,
  data,
});

// Action creators for updating assigned lead failure
export const updateAssignedLeadFailure = (error) => ({
  type: UPDATE_ASSIGNED_LEAD_FAILURE,
  error,
});

// Method for updating assigned lead
export const updateAssignedLead = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(updateAssignedLeadInProgress());

      // Make API request to update the specific key in the lead
      const token = getItemFromLocalStorage(KEYS.TOKEN);
      const response = await postRequest(
        `${BASE_URL}${UPDATE_ASSIGNED_TO_URL}`,
        payload,
        "",
        token
      );

      // Check response status
      if (response?.status === 200) {
        dispatch(updateAssignedLeadSuccess(response?.data));
        cb();
        toast.success(`Assigned successfully`, {
          position: "bottom-center",
        });
      } else {
        dispatch(
          updateAssignedLeadFailure({
            type: ERROR_TYPE.API.CALLBACKS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        updateAssignedLeadFailure({
          type: ERROR_TYPE.API.CALLBACKS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};
